<script>
import BaseView from "@/views/BaseView.vue";
import OmniTable from "@/components/OmniTable.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import PageHeader from "@/components/PageHeader.vue";
import CarriersSearchForm from "@/views/companies/carriers/CarriersSearchForm.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "CarriersPage",
  components: { CarriersSearchForm, PageHeader, LinkButton, OmniTable },
  mixins: [BaseView],
  data() {
    return {
      perm: "carriers",
      headers: [
        { title: this.$t("general.name"), key: "name" },
        { title: this.$t("company.fmcsa"), key: "number" },
        { title: this.$t("company.physical_address"), key: "address" },
        { title: this.$t("company.billing_address"), key: "billingAddress" },
        {
          title: "",
          key: "actions",
          align: "end",
          width: 58,
          sortable: false,
        },
      ],
      filter: {},
      data: { items: [] },
      loading: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["mobile"]),
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
  },
  async created() {
    this.setTitle(this.$t("company.carriers"));
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      const filter = { ...this.filter, ...this.params };

      try {
        const resp = await this.$api.company.findCarriers(filter);
        this.data = resp.data;
      } catch (e) {
        console.error("Error load carriers list: ", e);
      }

      this.loading = false;
    },
    async search(params) {
      this.params.page = 1;
      this.filter = params;

      await this.refresh();
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100">
    <page-header :header="$t('company.carriers')">
      <template #suffix>
        <link-button :to="{ name: 'carriers-edit', params: { id: 'new' } }" color="success">
          {{ $t("general.create-new") }}
        </link-button>
      </template>
    </page-header>
    <carriers-search-form @search="search" />

    <omni-table
      id="carriers"
      :footer-props="{ 'items-per-page-options': pagination.itemsPerPage }"
      :headers="headers"
      :items="data.items"
      :items-length="data.total || 0"
      :items-per-page="params.pageSize"
      :loading="loading"
      :mobile-breakpoint="0"
      :page="params.page"
      :show-all-option="false"
      @update:page="updatePage"
      @update:items-per-page="updatePageSize"
    >
      <template #[`item.name`]="{ item }">
        <div>{{ item.name }}</div>
        <div class="caption">{{ item.branchName }}</div>
      </template>
      <template #[`item.number`]="{ item }"> {{ item.fmcsaType }} {{ item.fmcsaNumber }}</template>
      <template #[`item.address`]="{ item }">
        {{ item.addressLine }}, {{ item.addressCity }}, {{ item.addressZipCode }},
        {{ item.addressState }}
      </template>
      <template #[`item.billingAddress`]="{ item }">
        {{ item.billingAddressLine }}, {{ item.billingAddressCity }}, {{ item.billingAddressZipCode }},
        {{ item.billingAddressState }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          v-if="canEdit"
          color="primary"
          size="small"
          variant="flat"
          @click="$router.push({ name: 'carriers-edit', params: { id: item.id } })"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </omni-table>
  </v-container>
</template>
