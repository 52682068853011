<script>
import UserSelect from "@/views/users/components/UserSelect.vue";
import ContactPerson from "@/views/users/components/ContactPerson.vue";
import CitizenshipSelect from "@/components/inputs/CitizenshipSelect.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { MasksMixin } from "@/mixins/masks.mixin";

export default {
  name: "UserFormDriver",
  components: { DatePicker, CitizenshipSelect, ContactPerson, UserSelect },
  mixins: [UsersMixin, ValidationMixin, MasksMixin],
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      driverInTruck: null,
    };
  },
  computed: {
    user: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    isInTruck() {
      return !!this.user.firstDriverIn || !!this.user.secondDriverIn;
    },
  },
  watch: {
    async modelValue() {
      if (this.modelValue.isDriver && this.driverInTruck === null) {
        const resp = await this.$api.trucks.findTrucks({ driver: this.user.id });
        if (resp.success) {
          this.driverInTruck = resp.data.total > 0 ? resp.data.items[0] : false;
        }
      }
    },
  },
  methods: {
    ownerRequired(value) {
      if (this.user.isDriver) {
        // accept "0" as value for owner
        return (value !== null && value !== undefined) || "Owner is required for driver";
      }
      return true;
    },
  },
};
</script>

<template>
  <div class="pa-2 mb-6">
    <div class="user-header mb-3">
      <div class="subheading font-weight-medium">Driver information</div>
    </div>
    <div>
      <div class="pa-5 bg-white rounded personal-block">
        <div class="d-flex align-center justify-space-between flex-wrap">
          <div>
            <v-menu :disabled="!driverInTruck" location="top start" offset="4" open-on-hover>
              <template #activator="{ props }">
                <div v-bind="props">
                  <v-switch
                    id="is_driver"
                    v-model="user.isDriver"
                    :disabled="!!(user.id && driverInTruck)"
                    :inset="true"
                    :label="$t('drivers.driver')"
                    class="mt-0"
                    color="primary"
                    density="compact"
                    hide-details
                  />
                </div>
              </template>
              <v-card>
                <v-card-text v-if="driverInTruck">
                  Driver is in truck
                  <a :href="`/fleets/trucks/` + driverInTruck.id" target="_blank">{{ driverInTruck.number }}</a>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div class="d-flex align-center mt-md-0 mt-4 flex-wrap">
            <v-switch
              id="can_change_status"
              v-model="user.canChangeStatus"
              class="mt-0 mr-7"
              color="primary"
              hide-details
              label="Can change status"
            />
            <v-switch
              id="willing_cross_border_canada"
              v-model="user.willingCrossBorderCanada"
              class="mt-0 mr-7"
              color="primary"
              hide-details
              label="Willing cross Canada border"
            />
            <v-switch
              id="willing_cross_border_mexico"
              v-model="user.willingCrossBorderMexico"
              class="mt-0"
              color="primary"
              hide-details
              label="Willing cross Mexico border"
            />
          </div>
        </div>
      </div>

      <div v-if="user.isDriver">
        <div class="pt-6">
          <div class="mb-6">
            <v-row>
              <v-col lg="8">
                <div class="pa-5 bg-white rounded personal-block">
                  <div class="caption text-grey-darken-1 mb-3">Driver information</div>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        id="driver_status"
                        v-model="user.driverStatus"
                        :items="driverStatuses"
                        :label="$t('drivers.driver-status')"
                        :rules="[requiredValidator]"
                        class="mb-0 width-100"
                        color="primary"
                        density="compact"
                        hide-details="auto"
                        item-title="name"
                        item-value="id"
                        variant="outlined"
                      />
                    </v-col>
                    <v-col cols="6">
                      <div class="d-flex align-center">
                        <user-select
                          id="owner_id"
                          v-model="user.ownerId"
                          :disabled="isInTruck"
                          :extend-users="user.isOwner ? [user] : []"
                          :label="$t('drivers.owner')"
                          :rules="[ownerRequired]"
                          extended
                          owners
                          required
                          show-role
                        />
                        <v-tooltip v-if="isInTruck" location="top">
                          <template #activator="{ props }">
                            <v-icon class="ml-2" color="primary" icon="mdi-information-outline" v-bind="props"></v-icon>
                          </template>
                          To change the owner, you need to drop the driver off the truck first
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <contact-person
                        id="contact_person_id"
                        v-model="user.contactPersonId"
                        :user="user"
                        class="required"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col lg="4">
                <div class="pa-5 bg-white rounded personal-block">
                  <div class="caption text-grey-darken-1 mb-3">Citizenship information</div>
                  <v-row>
                    <v-col>
                      <citizenship-select id="citizenship" v-model="user.citizenship" required />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <date-picker
                        id="citizenship_document_expiration_date"
                        v-model="user.citizenshipDocumentExpirationDate"
                        label="Citizenship Doc Expire"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="mb-6">
            <v-row>
              <v-col lg="8">
                <div class="pa-5 bg-white rounded personal-block">
                  <div class="caption text-grey-darken-1 mb-3">Driver license</div>
                  <v-row>
                    <v-col cols="4">
                      <v-select
                        id="license_type"
                        v-model="user.licenseType"
                        :items="licenseTypes"
                        color="primary"
                        density="compact"
                        hide-details
                        item-title="name"
                        item-value="id"
                        label="Type"
                        variant="outlined"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        id="license_class"
                        v-model="user.licenseClass"
                        :rules="[requiredValidator]"
                        autocomplete="off1"
                        class="required"
                        color="primary"
                        density="compact"
                        hide-details="auto"
                        label="Class"
                        max-length="3"
                        variant="outlined"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        id="license_number"
                        v-model="user.licenseNumber"
                        :rules="[requiredValidator]"
                        autocomplete="off1"
                        class="required"
                        color="primary"
                        density="compact"
                        hide-details="auto"
                        label="Number"
                        variant="outlined"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        id="license_state"
                        v-model="user.licenseState"
                        :rules="[requiredValidator]"
                        autocomplete="off1"
                        class="required"
                        color="primary"
                        density="compact"
                        hide-details="auto"
                        label="State"
                        max-length="2"
                        variant="outlined"
                      />
                    </v-col>
                    <v-col cols="6">
                      <date-picker
                        id="license_expiration"
                        v-model="user.licenseExpiration"
                        :rules="[requiredValidator]"
                        label="Expire date"
                        required
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col lg="4">
                <div class="pa-5 bg-white rounded personal-block">
                  <div class="caption text-grey-darken-1 mb-3">
                    {{ $t("drivers.certificates") }}
                  </div>
                  <v-row>
                    <v-col cols="6">
                      <date-picker
                        id="certificate_hazmat_expiration_date"
                        v-model="user.certificateHazmatExpirationDate"
                        :label="$t('drivers.hazmat-expiration')"
                      />
                    </v-col>
                    <v-col cols="6">
                      <date-picker
                        id="certificate_twic_expiration_date"
                        v-model="user.certificateTwicExpirationDate"
                        :label="$t('drivers.twic-expiration')"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <date-picker
                        id="certificate_tsa_expiration_date"
                        v-model="user.certificateTsaExpirationDate"
                        :label="$t('drivers.tsa-expiration')"
                      />
                    </v-col>
                    <v-col cols="6">
                      <date-picker
                        id="certificate_tanker_endorsement_expiration_date"
                        v-model="user.certificateTankerEndorsementExpirationDate"
                        :label="$t('drivers.tanker-expiration')"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
