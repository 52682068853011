<script>
import { defineComponent } from "vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import UserStatus from "@/components/status/UserStatus.vue";

export default defineComponent({
  name: "DriverSelect",
  components: { UserStatus, PhoneNumber },
  props: {
    id: String,
    modelValue: Number,
    label: String,
    items: { type: Array, required: true },
    truck: { type: Object, required: true },
  },
  emits: ["update:modelValue"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<template>
  <v-autocomplete
    :id="id"
    v-model="model"
    :clearable="true"
    :items="items"
    :label="label"
    color="primary"
    density="compact"
    hide-details="auto"
    item-title="name"
    item-value="id"
    no-data-text="No free drivers for the owner"
    single-line
    variant="outlined"
  >
    <template #item="{ item, props }">
      <v-list-item :disabled="item.raw.disabled" :title="false" v-bind="props">
        <div class="d-flex">
          <span class="mr-2">{{ item.raw.name }}</span>

          <span v-if="item.raw.driverTruck && item.raw.driverTruck.id !== truck.id" class="font-size-14 mr-2"
            >{{ $t("drivers.in-truck") }} {{ item.raw.driverTruck.number }}</span
          >
          <phone-number
            v-else
            :is-callable="false"
            :number="item.raw.mobilePhone"
            class="font-size-14 mr-2"
            size="small"
          />
          <user-status :status="item.raw.driverStatus" class="ml-auto" />
        </div>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<style lang="scss" scoped></style>
