<script>
import BaseView from "@/views/BaseView.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";

import ChatInfoDriverPanel from "@/views/chats/chat-info/ChatInfoDriverPanel.vue";
import ChatInfoOwnerPanel from "@/views/chats/chat-info/ChatInfoOwnerPanel.vue";
import ChatInfoCoordinatorPanel from "@/views/chats/chat-info/ChatInfoCoordinatorPanel.vue";
import ChatInfoUserInfo from "@/views/chats/chat-info/ChatInfoUserInfo.vue";

export default {
  name: "ChatInfo",
  components: {
    ChatInfoUserInfo,
    ChatInfoCoordinatorPanel,
    ChatInfoOwnerPanel,
    ChatInfoDriverPanel,
    PhoneNumber,
  },
  mixins: [BaseView, TrucksMixin, UsersMixin],
  props: {
    thread: Object,
  },
  emits: ["close"],
  data() {
    return {
      user: null,
    };
  },
  watch: {
    async thread() {
      await this.loadUser();
    },
  },
  async mounted() {
    await this.loadUser();
  },
  methods: {
    async loadUser() {
      let user = { coordinators: [], owners: [] };

      const resp = await this.$api.users.findUserById(this.thread.userId);
      if (resp.success) {
        user = { ...user, ...resp.data };
      }

      if (user.isDriver) {
        const resp = await this.$api.users.findDriverById(user.id);
        if (resp.success) {
          user = { ...user, ...resp.data };
        }

        // load owner coordinators
        const coordinatorsResponse = await this.$api.users.findOwnerCoordinators(user.ownerId);
        if (coordinatorsResponse.success) {
          user.coordinators = coordinatorsResponse.data;
        }
      }

      if (user.isOwner) {
        const resp = await this.$api.users.findOwnerCoordinators(user.id);
        if (resp.success) {
          user.coordinators = resp.data;
        }
      }

      if (user.isCoordinator) {
        const resp = await this.$api.users.findCoordinatorOwners(user.id);
        if (resp.success) {
          user.owners = resp.data;
        }
      }

      this.user = user;
    },
  },
};
</script>

<template>
  <div :class="user?.emergencyContactPhone ? 'emergency-phone' : ''" class="height-100 position-relative">
    <div class="d-flex flex-column height-100">
      <div class="info-panel__inner position-relative height-100 flex-grow-1">
        <div v-if="user" class="info-panel__wrapper scroll-style">
          <chat-info-user-info :user="user" />

          <div class="panel-info__blocks">
            <chat-info-driver-panel :user="user" />
            <chat-info-owner-panel :user="user" />
            <chat-info-coordinator-panel :user="user" />
          </div>
        </div>

        <div v-if="user?.emergencyContactPhone" class="emergency-contact pa-4 rounded rounded-t-0">
          <div class="subheading mb-2 font-weight-medium text-grey-darken-3">Emergency contact</div>
          <a :href="`callto: ${user.emergencyContactPhone}`" class="d-flex align-center text-decoration-none">
            <span class="bg-grey-lighten-4 rounded-circle user-contact__icon mr-3 d-flex align-center justify-center">
              <v-icon class="text-grey-darken-1" size="14">mdi-phone-outline</v-icon>
            </span>
            <phone-number :number="user.emergencyContactPhone" class="caption text-primary" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
dt {
  font-weight: bold;
}

dd {
  margin-bottom: 7px;
}

.icon-block--medium {
  width: 14px;
  height: 14px;
  flex: 0 0 14px;
}

/*.link-icon {
  display: inline-block;
  width: 11px;
  height: 11px;
  background: url(@/assets/images/open-link.svg) no-repeat;
}*/
.user-contact__icon {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  cursor: pointer;
}

.panel-info__block-content {
  background: #f8f9fa;

  .content {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.16);
  }
}

.info-panel__wrapper {
  // height: calc(100% - 100px);
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 24px;
}

.info-panel__inner {
  padding-bottom: 32px;
}

.emergency-phone .info-panel__inner {
  padding-bottom: 90px;
}

.emergency-contact {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0px -10px 20px rgba(18, 38, 63, 0.04);
}

.trucks-dropdown__btn {
  height: auto !important;
  min-width: auto !important;
  padding: 0 !important;

  &:before {
    display: none;
  }
}

.v-list {
  padding: 6px 10px !important;
  box-shadow: none !important;
}

.v-list-item {
  padding: 0 !important;
}

.v-list-item {
  &:not(:last-child) {
    border-bottom: 1px solid #f0f1f4;
  }
}

.track-block {
  width: 100%;
  padding: 12px 0;
}

.v-menu__content {
  box-shadow: 0 10px 20px rgba(18, 38, 63, 0.04) !important;
  border-radius: 4px;
}

.short-line {
  position: relative;
  margin: 12px 0;

  &:before {
    content: "";
    display: block;
    max-width: 55%;
    height: 1px;
    border-bottom: 1px solid #dee2e6;
    margin: 0 auto;
  }
}

.day {
  position: relative;

  &:after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    border: 1px solid #dee2e6;
  }

  span {
    background: #f8f9fa;
    display: inline-block;
    position: relative;
    z-index: 1;
  }
}

.bordered {
  border: solid 1px #616161;
}

.back-btn {
  display: none;
  @media (max-width: 991px) {
    display: block !important;
  }
}

.message-textarea {
  .v-input__slot {
    border: 1px solid #bdbdbd !important;
  }
}

.currency-icon {
  width: 18px;
  flex: 0 0 18px;
  height: 18px;
  font-size: 13px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: url(@/assets/images/currency-icon.svg) no-repeat;
  background-size: 18px;
}
</style>
