export enum Role {
  admin = "ad",
  teamLead = "tl",
  booking = "bk",
  hr = "hr",
  operation = "op",
  accounting = "ac",
}

export enum MenuSection {
  dispatchers = "dispatchers",
  drivers = "drivers",
  owners = "owners",
  trucks = "trucks",
}

export enum RoleAction {
  view = "view",
  edit = "edit",
  delete = "delete",
}

export enum NavigationItem {
  account = "account",
  dashboard = "dashboard",
  chats = "chats",
  coordinators = "coordinators",
  dispatchers = "dispatchers",
  drivers = "drivers",
  carriers = "carriers",
  company = "company",
  customers = "customers",
  facilities = "facilities",
  orders = "orders",
  owners = "owners",
  trips = "trips",
  trucks = "trucks",
  logs = "logs",
  users = "users",
}

export const Permissions: {
  [key in NavigationItem]: { [key in RoleAction]?: Role[] };
} = {
  [NavigationItem.account]: {
    [RoleAction.view]: [Role.admin, Role.teamLead, Role.booking, Role.hr, Role.operation, Role.accounting],
  },
  [NavigationItem.dashboard]: {
    [RoleAction.view]: [Role.admin, Role.teamLead, Role.booking, Role.hr, Role.operation, Role.accounting],
  },
  [NavigationItem.chats]: {
    [RoleAction.view]: [Role.admin, Role.teamLead, Role.booking, Role.hr, Role.operation, Role.accounting],
  },
  [NavigationItem.coordinators]: {
    [RoleAction.view]: [Role.admin, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.hr],
  },
  [NavigationItem.dispatchers]: {
    [RoleAction.view]: [Role.admin, Role.teamLead, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.teamLead],
  },
  [NavigationItem.drivers]: {
    [RoleAction.view]: [Role.admin, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.hr],
  },
  [NavigationItem.carriers]: {
    [RoleAction.view]: [Role.admin, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.hr],
  },
  [NavigationItem.company]: {
    [RoleAction.view]: [Role.admin, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.hr],
  },
  [NavigationItem.customers]: {
    [RoleAction.view]: [Role.admin, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.hr],
  },
  [NavigationItem.facilities]: {
    [RoleAction.view]: [Role.admin, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.hr],
  },
  [NavigationItem.orders]: {
    [RoleAction.view]: [Role.admin, Role.teamLead, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.hr],
  },
  [NavigationItem.owners]: {
    [RoleAction.view]: [Role.admin, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.booking],
  },
  [NavigationItem.trips]: {
    [RoleAction.view]: [Role.admin, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.booking],
  },
  [NavigationItem.trucks]: {
    [RoleAction.view]: [Role.admin, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.hr],
  },
  [NavigationItem.logs]: {
    [RoleAction.view]: [Role.admin, Role.teamLead, Role.booking, Role.hr, Role.operation, Role.accounting],
  },
  [NavigationItem.users]: {
    [RoleAction.view]: [Role.admin, Role.booking, Role.hr, Role.operation, Role.accounting],
    [RoleAction.edit]: [Role.admin, Role.hr],
  },
};
