import type { App } from "vue";

const sidebarCollapsedField = "sidebarCollapsed";

export class Storage {
  getSidebarCollapsed(): boolean | null {
    return this.getBooleanItem(sidebarCollapsedField, null);
  }

  setSidebarCollapsed(value: boolean | null): void {
    this.setBooleanItem(sidebarCollapsedField, value);
  }

  getBooleanItem(key: string, defaultValue: boolean | null): boolean | null {
    let value: string | boolean | null = this.getItem(key, String(defaultValue));
    switch (value) {
      case "null":
        value = null;
        break;
      case "true":
        value = true;
        break;
      case "false":
        value = false;
        break;
      default:
        value = defaultValue;
        break;
    }
    return value as boolean | null;
  }

  setBooleanItem(key: string, value: boolean | null): void {
    let stringValue: string;
    switch (value) {
      case null:
        stringValue = "null";
        break;
      case true:
        stringValue = "true";
        break;
      case false:
        stringValue = "false";
        break;
    }
    this.setItem(key, stringValue);
  }

  getItem(key: string, defaultValue: string | null = null): string | null {
    return localStorage.getItem(key) || defaultValue;
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}

export default {
  install(app: App): void {
    app.config.globalProperties.$storage = new Storage();
  },
};
