<script>
import ValidationMixin from "@/mixins/validation.mixin";
import BaseView from "@/views/BaseView.vue";
import { mapActions } from "pinia";
import { useGalleryStore } from "@/store/gallery.store";

export default {
  name: "OrderFile",
  mixins: [ValidationMixin, BaseView],
  props: {
    modelValue: null,
    required: Boolean,
    disabled: Boolean,
    label: String,
    deletable: Boolean,
  },
  emits: ["update:modelValue", "clear", "delete"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    ...mapActions(useGalleryStore, ["setMedia", "setMediaId"]),
    clear() {
      this.$emit("clear");
    },
    remove() {
      this.$emit("delete");
    },
    preview() {
      this.setMedia([{ ...this.model }]);
      this.setMediaId(this.model.id);
    },
  },
};
</script>

<template>
  <div v-if="model?.id">
    <div class="text-grey-darken-1 font-size-12">{{ label }}<span v-if="required" class="text-error">*</span></div>
    <div class="d-flex justify-space-between border-b text-primary">
      <div class="d-flex align-center">
        <v-icon class="mr-2" icon="mdi-attachment"></v-icon>
        <div class="font-size-16">{{ $filters.ellipsis(model.name, 45, "middle") }}</div>
      </div>
      <div>
        <v-btn
          class="pa-0 ml-2"
          density="comfortable"
          icon="mdi-eye-outline"
          size="small"
          variant="text"
          @click="preview"
        ></v-btn>
        <v-btn
          class="pa-0"
          color="error"
          density="comfortable"
          icon="mdi-delete-outline"
          size="small"
          variant="text"
          @click="remove"
        ></v-btn>
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-center">
    <v-file-input
      v-model="model"
      :class="{ required: required }"
      :disabled="disabled"
      :label="label"
      :rules="required ? [fileRequiredValidator] : []"
      accept="image/*,.pdf"
      class="input-file"
      clear-icon="mdi-close"
      color="primary"
      density="compact"
      hide-details="auto"
      prepend-icon=""
      prepend-inner-icon="mdi-attachment"
      variant="outlined"
      @click:clear="clear"
    />
    <v-btn
      v-if="deletable"
      class="pa-0 ml-2"
      color="red-accent-2"
      density="comfortable"
      icon="mdi-delete-outline"
      size="small"
      variant="text"
      @click="remove"
    >
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.input-file {
  :deep(.v-field__input) {
    text-wrap: nowrap;
    overflow: hidden;
  }
}
</style>
