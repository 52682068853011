// Utility functions for case transformation
export function snakeToCamel(snakeStr: string): string {
  return snakeStr.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
}

export function camelToSnake(camelStr: string): string {
  return camelStr.replace(/([A-Z])/g, "_$1").toLowerCase();
}

export function transformKeysToCamel(obj: Record<string, any>): Record<string, any> {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeysToCamel(item));
  } else if (obj !== null && obj !== undefined && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (acc, key) => {
        const camelKey = snakeToCamel(key);
        acc[camelKey] = transformKeysToCamel(obj[key]);
        return acc;
      },
      {} as Record<string, any>,
    );
  }
  return obj;
}

export function transformKeysToSnake(obj: Record<string, any>): Record<string, any> {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeysToSnake(item));
  } else if (obj !== null && obj !== undefined && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (acc, key) => {
        const snakeKey = camelToSnake(key);
        acc[snakeKey] = transformKeysToSnake(obj[key]);
        return acc;
      },
      {} as Record<string, any>,
    );
  }
  return obj;
}
