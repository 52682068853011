<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import EditStopBlock from "@/views/trips/edit/dialogs/EditStopBlock.vue";
import OmniDialog from "@/components/Dialog.vue";
import RouteStopStatusLabel from "../../components/RouteStopStatus.vue";
import OrderStopStatus from "@/components/status/OrderStopStatus.vue";
import { OrderStopState } from "@/data/order.ts";
import UpdateIndicator from "@/views/trips/components/UpdateIndicator.vue";
import StopChangesPopover from "@/views/trips/components/StopChangesPopover.vue";

export default {
  name: "StopBlock",
  components: { StopChangesPopover, UpdateIndicator, OrderStopStatus, RouteStopStatusLabel, OmniDialog, EditStopBlock },
  mixins: [OrdersMixin],
  props: {
    orderFreights: {
      type: Array,
    },
    stop: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
    isOrderStop: Boolean,
  },
  emits: ["change", "delete"],
  data() {
    return {
      editStopDialog: false,
      item: {},
    };
  },
  computed: {
    freights() {
      const freights = [];
      for (const freight of this.orderFreights) {
        if (this.item.freights.includes(freight.id)) {
          freights.push({ id: freight.id, name: this.getFreightName(freight, "", "", false) });
        }
      }
      return freights;
    },
    freightsList() {
      return this.freights.map((f) => f.name).join(", ");
    },
    isUpdated() {
      return this.item.state === OrderStopState.updated;
    },
  },
  watch: {
    stop: {
      handler(value) {
        this.item = { ...value };
      },
      deep: true,
    },
  },
  created() {
    this.item = { ...this.stop };
  },
  methods: {
    showEditForm() {
      if (this.item.use && !this.item.dateTimeTo) {
        this.editStopDialog = true;
      }
    },
  },
};
</script>

<template>
  <div :class="{ 'reload-stop': item.type === STOP_RELOAD }" class="trip-stops__block">
    <div :class="disabled ? 'bg-red-lighten-5' : 'bg-grey-lighten-4'" class="trip-stops__block-inner pa-4 rounded">
      <div class="mb-6 trip-stops__block-header">
        <div class="d-flex align-center justify-space-between mb-2">
          <div class="d-flex align-center flex-wrap">
            <v-checkbox
              v-if="item.editable"
              v-model="item.use"
              class="mr-1 ma-0 pa-0"
              color="primary"
              density="compact"
              hide-details
              @change="showEditForm"
            />
            <v-icon v-if="item.type === STOP_PICK_UP" class="mr-2" color="green" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="item.type === STOP_RELOAD" class="mr-2" color="orange" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="item.type === STOP_DELIVERY" class="mr-2" color="red accent-2" size="20">
              mdi-map-marker-radius-outline
            </v-icon>
            <span class="blue-text-grey-darken-1 font-weight-600 mr-3 font-size-12" data-qa="stop-type">
              {{ item.name }}
            </span>
            <router-link
              v-if="item.order"
              :to="{ name: 'order-view', params: { id: item.order.orderId || item.order.id } }"
              class="font-size-12 font-weight-medium text-primary text-decoration-none"
              target="_blank"
            >
              Order #{{ item.order.number }}
            </router-link>
            <stop-changes-popover v-if="isUpdated" :stop="item"></stop-changes-popover>
          </div>
          <div>
            <v-btn
              v-if="item.type === STOP_RELOAD"
              class="text-red text--accent-2 bg-transparent"
              elevation="0"
              icon="mdi-delete-outline"
              size="x-small"
              @click="$emit('delete')"
            />
            <v-btn
              v-if="item.editable && item.use"
              class="text-primary bg-transparent"
              elevation="0"
              icon="mdi-pencil-outline"
              size="x-small"
              @click="editStopDialog = true"
            />
          </div>
          <div v-if="isUpdated" class="badge bg-warning pa-2 rounded-te rounded-bs font-weight-600">UPDATED</div>
        </div>
        <div v-if="item.use">
          <order-stop-status v-if="isOrderStop" :stop="item"></order-stop-status>
          <route-stop-status-label v-else :stop="item"></route-stop-status-label>
        </div>
      </div>

      <div v-if="item.use" class="mb-6 trip-stops__block-body">
        <div class="mb-5">
          <div class="d-flex align-center mb-2">
            <router-link
              :to="{ name: 'facilities-edit', params: { id: item.facility?.id } }"
              class="text-uppercase subheading font-weight-medium text-primary d-block text-decoration-none"
              data-qa="facility-name"
              target="_blank"
            >
              {{ item.facility?.name }}
            </router-link>
            <update-indicator v-if="item.oldFacilityId && !disabled" class="ml-1"></update-indicator>
          </div>
          <div class="text-grey-darken-2" data-qa="facility-address">
            {{ item.facility?.addressLine }}, {{ item.facility?.address }}
          </div>
        </div>
        <div v-if="item.timeType && !item.isReload" class="d-flex align-center text-grey-darken-1">
          <v-icon class="mr-2" size="20">mdi-clock-outline</v-icon>
          <span class="font-weight-medium" data-qa="stop-time"
            >{{ timeTypesMap[item.timeType] }}: {{ getStopTime(item) }}</span
          >
        </div>
      </div>
      <div v-if="item.use" class="trip-stops__block-footer">
        <div v-if="!item.editable" class="d-flex">
          <span class="text-grey-darken-1 mr-2">Freight:</span>
          <span
            :class="disabled ? 'text-error' : 'text-grey-darken-2'"
            class="font-weight-medium"
            data-qa="freight-size"
          >
            {{ freightsList }}
          </span>
        </div>
        <div v-else-if="freights.length > 0">
          <v-autocomplete
            v-if="item.type === STOP_PICK_UP"
            v-model="item.freights"
            :items="freights"
            :multiple="true"
            class="required"
            density="compact"
            hide-details
            item-title="name"
            item-value="id"
            label="Freight"
            variant="outlined"
          />
          <v-select
            v-if="item.type === STOP_DELIVERY"
            v-model="item.freights"
            :items="freights"
            :multiple="true"
            class="required"
            density="compact"
            hide-details
            item-title="name"
            item-value="id"
            label="Freight"
            variant="outlined"
          />
        </div>
      </div>

      <div v-else class="flex-1 trip-stops__block-empty">
        <div class="empty-content height-100 d-flex flex-column align-center justify-center">
          <div class="empty-content__img mb-3 line-height-1">
            <img alt="" src="../../../../assets/images/empty-stop.svg" />
          </div>
          <div class="empty-content__text text-grey subheading">Not used in this route</div>
        </div>
      </div>
    </div>
    <omni-dialog
      v-model="editStopDialog"
      :title="'Edit route stop - ' + item.name"
      primary-action-label="Save"
      width="830"
      @primary-action:click="editStopDialog = false"
    >
      <edit-stop-block v-model="item" />
    </omni-dialog>
  </div>
</template>

<style lang="scss" scoped>
.trip-stops__block-inner {
  min-height: 290px;
}

.reload-stop .trip-stops__block-inner {
  min-height: 610px;
}

.badge {
  margin-top: -16px;
  margin-right: -16px;
}
</style>
