const TOKEN_KEY: string = "tms_token";

export class TokenStorage {
  static getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  static saveToken(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  }

  static removeToken(): void {
    localStorage.removeItem(TOKEN_KEY);
  }
}
