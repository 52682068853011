<script>
import BaseView from "@/views/BaseView.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "TruckLocationTime",
  mixins: [BaseView, DateTimeMixin],
  props: {
    time: String,
    timezone: String,
    showIndicator: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
    dotColor() {
      if (!this.showIndicator) return "";

      const diff = this.$dayjs().diff(this.$dayjs(this.time), "minutes");

      if (diff <= 20) {
        return "indicator green";
      }
      if (diff <= 60) {
        return "indicator yellow";
      }
      if (diff <= 60 * 24) {
        return "indicator orange";
      }

      return "indicator";
    },
  },
};
</script>

<template>
  <div class="mt-2 cursor-text">
    <v-tooltip v-if="timezone" :disabled="!showTooltip" location="top start" open-on-focus>
      <template #activator="{ props }">
        <div v-bind="props">
          <div :class="dotColor" class="font-weight-600 text-grey-darken-2 mb-1 font-size-11 line-height-1">
            {{ dateTZ(time, dispatcher.timezone) }}
          </div>
          <div v-if="dispatcher.timezone !== timezone" class="text-grey-darken-2 font-size-11">
            {{ dateTZ(time, timezone) }}
          </div>
        </div>
      </template>
      Location updated {{ timeAgo(time) }}
    </v-tooltip>
    <div v-else>&mdash;</div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.indicator {
  position: relative;

  &:before {
    content: "·";
    font-size: 52px;
    position: absolute;
    top: -22px;
    left: -16px;
    color: $grey;
  }

  &.green {
    &:before {
      color: $green;
    }
  }

  &.yellow {
    &:before {
      color: $yellow;
    }
  }

  &.orange {
    &:before {
      color: $orange;
    }
  }
}
</style>
