<script>
import UserStatus from "@/components/status/UserStatus.vue";
import UserCard from "@/views/chats/UserCard.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import BaseView from "@/views/BaseView.vue";
import UserTruck from "@/views/chats/user-trucks/UserTruck.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "ChatInfoDriverPanel",
  components: { UserTruck, UserCard, UserStatus },
  mixins: [BaseView, DateTimeMixin, TrucksMixin],
  props: {
    user: { type: Object, required: true },
  },
  data() {
    return {
      contactPerson: {},
      driver: null,
      truck: null,
      trucks: [],
      otherDriver: null,
      isFirstDriver: true,
      ownerCoordinators: [],
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
  },
  watch: {
    user() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const response = await this.$api.trucks.findTrucks({ driver: this.user.id });
      if (response.success) {
        this.trucks = response.data.items;
        this.enrichTruck();
      }

      const otherDriverId = this.isFirstDriver ? this.truck?.driver2Id : this.truck?.driverId;
      if (otherDriverId) {
        const resp = await this.$api.users.findUserById(otherDriverId);
        if (resp.success) {
          this.otherDriver = resp.data;
        }
      }

      if (this.user.owner && this.user.owner.id) {
        const resp = await this.$api.users.findOwnerCoordinators(this.user.owner.id);
        if (resp.success) {
          this.ownerCoordinators = resp.data;
        }
      }
    },
    enrichTruck() {
      if (this.trucks.length === 0) return;
      const firstDriverTruck = this.trucks.find((t) => t.driverId === this.user.id);
      if (firstDriverTruck) {
        this.truck = firstDriverTruck;
        return;
      }
      const secondDriverTruck = this.trucks.find((t) => t.driver2Id === this.user.id);
      if (secondDriverTruck) {
        this.truck = secondDriverTruck;
        this.isFirstDriver = false;
      }
    },
  },
};
</script>

<template>
  <div v-if="user.isDriver" class="panel-info__block rounded" data-qa="driver-info-block">
    <div
      class="panel-info__block-header d-flex justify-space-between align-center bg-indigo-lighten-2 pt-2 pb-2 pl-5 pr-5 rounded-b-0 rounded"
    >
      <span class="font-size-12 font-weight-600 text-uppercase text-white">{{ $t("drivers.driver") }}</span>
      <user-status :status="user.driverStatus" />
    </div>
    <div class="panel-info__block-content pa-3">
      <div v-if="truck" class="pa-3 bg-white rounded content mb-3">
        <user-truck :truck="truck" :user="user"></user-truck>
      </div>

      <user-card
        v-if="user.owner && user.owner.id"
        :is-financially-responsible="user.ownerId === user.contactPersonId"
        :role="$t('drivers.owner')"
        :status="user.owner.ownerStatus"
        :user="user.owner"
        role-color="text-green-lighten-1"
      />

      <user-card
        v-for="(coordinator, index) in [...user.coordinators, ...ownerCoordinators]"
        :key="index"
        :full-access="coordinator.fullAccess"
        :is-financially-responsible="user.contactPersonId === coordinator.userId"
        :role="$t('drivers.coordinator')"
        :user="coordinator.user"
        role-color="text-blue-lighten-2"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.panel-info__block {
  margin-bottom: 16px;
  background: #f8f9fa;
  border-bottom: none;
}

.panel-info__block-content {
  .content {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.16);
  }
}

.driver-switch-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-contact__icon {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  cursor: pointer;
}

.status-item {
  font-size: 9px;
  width: 17px;
  height: 17px;
}
</style>
