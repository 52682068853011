import { BaseApi } from "@/api/base";
import type { LoginRequest } from "@/api/auth/dto/request/login.request";
import type { LoginResponse } from "@/api/auth/dto/response/login.response";
import type { Response } from "@/api/client";
import type { ResetPasswordRequest } from "@/api/auth/dto/request/reset-password.request";
import type { ChangePasswordRequest } from "@/api/auth/dto/request/change-password.request";
import type { SetPasswordRequest } from "@/api/auth/dto/request/set-password.request";

export class AuthApi extends BaseApi {
  login(request: LoginRequest): Promise<Response<LoginResponse>> {
    return this.client.post<LoginResponse>("sign-in", request);
  }

  resetPassword(request: ResetPasswordRequest): Promise<Response<string>> {
    return this.client.post<string>("/dispatchers/reset-password", request);
  }

  changePassword(request: ChangePasswordRequest): Promise<Response<string>> {
    return this.client.post<string>("/dispatchers/change-password", request);
  }

  setPassword(dispatcherId: number, request: SetPasswordRequest): Promise<Response<string>> {
    return this.client.post<string>("/dispatchers/" + dispatcherId + "/set-password", request);
  }
}
