<script lang="ts">
import { mapState } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store.js";

export default {
  name: "BlankLayout",
  computed: {
    ...mapState(useSnackbarStore, ["snackbar"]),
  },
};
</script>

<template>
  <v-app id="app" theme="light">
    <router-view />
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" multi-line top>
      {{ snackbar.text }}
    </v-snackbar>
  </v-app>
</template>
