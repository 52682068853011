<script>
import BaseView from "@/views/BaseView.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import UserEditForm from "@/views/users/UserEditForm.vue";
import NotFoundMixin from "@/mixins/not-found.mixin";
import NotFound from "@/views/misc/NotFound.vue";

export default {
  name: "UserEdit",
  components: { NotFound, UserEditForm },
  mixins: [BaseView, UsersMixin, NotFoundMixin],
  data() {
    return {
      user: {
        id: 0,
        addressCountry: "US",
        companies: [],
        driverId: null,
        driverStatus: "a",
        ownerStatus: "",
        coordinators: [],
      },
      loading: false,
    };
  },
  async beforeMount() {
    await this.loadInfo();
  },
  methods: {
    async loadInfo() {
      // check we have id as a number
      if (!this.$route.params.id) return;

      let user = this.user;
      this.loading = true;

      const resp = await this.$api.users.findUserById(this.$route.params.id);
      if (resp.success) {
        user = { ...user, ...resp.data, coordinators: [] };
        if (user.archived) {
          return this.$router.replace({ name: "user-view", params: { id: user.id } });
        }
        if (user.isDriver) {
          const driverResponse = await this.$api.users.findDriverById(this.$route.params.id);
          if (driverResponse.success) {
            user = { ...user, ...driverResponse.data };
          }
        }
        this.setTitle("User " + user.name, "Users");
      } else {
        if (!this.validateEntityResponse(resp)) return;
      }

      // load coordinators
      const ownerId = user.isOwner ? user.id : user.ownerId;
      const coordinatorsResp = await this.$api.users.findOwnerCoordinators(ownerId);
      if (coordinatorsResp.success) {
        user.coordinators = coordinatorsResp.data;
      }

      user.companies = [];
      if (user.isOwner) {
        try {
          const companiesResp = await this.$api.users.findCompanies(user.id);
          if (companiesResp.success) {
            user.companies = companiesResp.data;
          }
        } catch (e) {
          console.error("Error load owner companies: ", e);
        }
      }

      this.user = user;
      this.loading = false;
    },
  },
};
</script>

<template>
  <v-container v-if="!entityNotFound" :fluid="true" class="pt-8 pb-10 pl-6 pr-6 user-info">
    <user-edit-form :user="user" />
  </v-container>
  <not-found v-else icon="mdi-account-remove-outline" text="User not found"></not-found>
</template>

<style lang="scss" scoped></style>
