<script>
import { OrderStopType } from "@/data/order.ts";
import UpdateIndicator from "@/views/trips/components/UpdateIndicator.vue";

export default {
  name: "StopChangesBlock",
  components: { UpdateIndicator },
  props: {
    stop: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      oldFacility: null,
    };
  },
  computed: {
    isPickUp() {
      return this.stop.type === OrderStopType.pickup;
    },
    stopType() {
      switch (this.stop.type) {
        case OrderStopType.pickup:
          return "Pick up";
        case OrderStopType.delivery:
          return "Delivery";
        default:
          return "";
      }
    },
    isFacilityChanged() {
      return this.stop.oldFacilityId !== this.stop.facility?.id;
    },
  },
};
</script>

<template>
  <div class="bg-grey-lighten-4 rounded pa-4 my-2" data-qa="route-stop">
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-icon
          :class="isPickUp ? 'text-success' : 'text-danger'"
          :icon="isPickUp ? 'mdi-package-variant-closed' : 'mdi-map-marker-radius-outline'"
          class="mr-2"
          size="20"
        ></v-icon>
        <div class="text-blue-grey-darken-1 font-size-12 font-weight-600" data-qa="route-stops-stop-type">
          {{ stopType }}
        </div>
        <router-link
          v-if="stop.order"
          :to="{ name: 'order-view', params: { id: stop.order.id } }"
          class="d-block text-primary font-weight-500 font-size-12 text-decoration-none ml-3"
          data-qa="route-stops-order-number"
          target="_blank"
        >
          Order #{{ stop.order.number }}
        </router-link>
      </div>
    </div>
    <div class="d-flex align-center mt-6">
      <router-link
        :to="{ name: 'facilities-edit', params: { id: stop.facility?.id } }"
        class="d-block font-size-16 font-weight-500 text-primary text-uppercase text-decoration-none"
        data-qa="route-stops-facility-name"
        target="_blank"
      >
        {{ stop.facility?.name }}
      </router-link>
      <update-indicator v-if="isFacilityChanged" class="ml-1"></update-indicator>
    </div>
    <div class="mt-2 text-grey-darken-2" data-qa="route-stops-facility-address">
      {{ stop.facility?.addressLine }}, {{ stop.facility?.address }}
    </div>
    <div v-if="stop.$oldFacility" class="mt-3">
      <div class="font-size-16 font-weight-500 text-grey text-uppercase">{{ stop.$oldFacility.name }}</div>
      <div class="mt-2 text-grey">{{ stop.$oldFacility.addressLine }}, {{ stop.$oldFacility.address }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
