import { BaseApi } from "@/api/base";
import type { Response } from "@/api/client";
import type { TruckDto } from "@/api/trucks/dto/truck.dto";
import type { ListResponseDto } from "@/api/dto/list-response.dto";
import type { LocationsResponse } from "@/api/trucks/dto/response/locations.response";

export class TrucksApi extends BaseApi {
  private trucksListController: AbortController | undefined;

  async findTrucks(options = {}, abortable = true): Promise<Response<ListResponseDto<TruckDto>>> {
    if (abortable && this.trucksListController) {
      this.trucksListController.abort();
    }
    this.trucksListController = new AbortController();

    const response = await this.client.get<ListResponseDto<TruckDto>>("trucks", options, {
      signal: abortable ? this.trucksListController.signal : undefined,
    });
    this.trucksListController = undefined;
    return response;
  }

  findTruckById(truckId: number): Promise<Response<TruckDto>> {
    return this.client.get<TruckDto>("trucks/" + truckId);
  }

  reserveTruck(truckId: number, dispatcherId: number, minutes: number): Promise<Response<TruckDto>> {
    return this.client.post<TruckDto>("trucks/" + truckId + "/reserve", { dispatcherId: dispatcherId, minutes });
  }

  async upsertTruck(truck: any): Promise<Response<TruckDto>> {
    return this.client.save<TruckDto>("/trucks", truck);
  }

  updateTruck(truckId: number, data: any): Promise<Response<TruckDto>> {
    return this.client.patch<TruckDto>("trucks/" + truckId, data);
  }

  updateTruckStatus(truckId: number, data: any): Promise<Response<TruckDto>> {
    return this.client.patch<TruckDto>("trucks/" + truckId + "/status", data);
  }

  getTruckLocations(
    truckId: number,
    dateFrom: string | null = null,
    dateTo: string | null = null,
  ): Promise<Response<LocationsResponse>> {
    return this.client.get<LocationsResponse>("trucks/" + truckId + "/locations", {
      timeFrom: dateFrom,
      timeTo: dateTo,
    });
  }

  archiveTruck(truckId: number): Promise<Response<void>> {
    return this.client.post("/trucks/" + truckId + "/archive", {});
  }

  unarchiveTruck(truckId: number): Promise<Response<void>> {
    return this.client.post("/trucks/" + truckId + "/unarchive", {});
  }
}
