<script>
import UserStatus from "@/components/status/UserStatus.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import CheckMark from "@/components/CheckMark.vue";

export default {
  name: "UserViewDriverInfo",
  components: { CheckMark, UserStatus },
  mixins: [UsersMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    citizenship() {
      return this.citizenships.find((c) => c.id === this.user.citizenship)?.name;
    },
  },
};
</script>

<template>
  <div v-if="user.isDriver" class="user-block pt-5 pr-5 pb-7 pl-5 bg-white rounded mt-4">
    <v-row>
      <v-col lg="6">
        <div class="text-grey-darken-3 mb-6 font-weight-medium">Driver info</div>
        <v-row>
          <div class="label">
            <span class="text-grey-darken-1">Status</span>
          </div>
          <div class="description">
            <user-status :muted="user.archived !== null" :status="user.driverStatus"></user-status>
          </div>
        </v-row>
        <v-row>
          <div class="label">
            <span class="text-grey-darken-1">License</span>
          </div>
          <div class="description">{{ user.licenseType }} {{ user.licenseNumber }} ({{ user.licenseClass }})</div>
        </v-row>
        <v-row>
          <div class="label">
            <span class="text-grey-darken-1">Citizenship</span>
          </div>
          <div class="description">
            {{ citizenship }}
            <template v-if="user.citizenshipDocumentExpirationDate">
              until
              {{ $filters.date(user.citizenshipDocumentExpirationDate) }}
            </template>
          </div>
        </v-row>
        <v-row v-if="user.owner">
          <div class="label">
            <span class="text-grey-darken-1">Owner</span>
          </div>
          <div class="description">
            <router-link
              :to="{ name: 'user-view', params: { id: user.owner.id } }"
              class="text-primary text-decoration-none"
            >
              {{ user.owner.name }}
            </router-link>
          </div>
        </v-row>
        <v-row align="center">
          <div class="label">
            <span class="text-grey-darken-1">Can change status</span>
          </div>
          <div class="description">
            <check-mark :muted="user.archived !== null" :value="user.canChangeStatus"></check-mark>
          </div>
        </v-row>
      </v-col>
      <v-col lg="6">
        <div class="text-grey-darken-3 mb-6 font-weight-medium">Certificates</div>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">Hazmat Expiration Date</span>
          </div>
          <div class="description">
            {{ $filters.date(user.certificateHazmatExpirationDate) }}
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">TWIC Expiration Date</span>
          </div>
          <div class="description">
            {{ $filters.date(user.certificateTwicExpirationDate) }}
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">Tanker Expiration Date</span>
          </div>
          <div class="description">
            {{ $filters.date(user.certificateTankerEndorsementExpirationDate) }}
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">TSA Expiration Date</span>
          </div>
          <div class="description">
            {{ $filters.date(user.certificateTsaExpirationDate) }}
          </div>
        </v-row>
        <v-row align="center">
          <div class="label long">
            <span class="text-grey-darken-1">Willing to go to Canada</span>
          </div>
          <div class="description">
            <check-mark :muted="user.archived !== null" :value="user.willingCrossBorderCanada"></check-mark>
          </div>
        </v-row>
        <v-row align="center">
          <div class="label long">
            <span class="text-grey-darken-1">Willing to go to Mexico</span>
          </div>
          <div class="description">
            <check-mark :muted="user.archived !== null" :value="user.willingCrossBorderMexico"></check-mark>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>
