<script>
import DispatcherSelect from "@/components/inputs/DispatcherSelect.vue";
import AddressField from "@/components/AddressField.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { MasksMixin } from "@/mixins/masks.mixin";
import UsersMixin from "@/views/users/UsersMixin.vue";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import BaseView from "@/views/BaseView.vue";
import OmniDialog from "@/components/Dialog.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "UserFormInfo",
  components: { OmniDialog, OmniTextarea, DatePicker, AddressField, DispatcherSelect },
  mixins: [BaseView, ValidationMixin, MasksMixin, UsersMixin],
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      inviteSending: false,
      inviteSent: false,
      showInviteResendConfirmation: false,
      emailErrors: [],
    };
  },
  computed: {
    user: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError", "showSnackbarSuccess"]),
    sendInvite() {
      this.inviteSending = true;
      return this.$api.users
        .sendInvite(this.user.id)
        .then(
          () => {
            this.showSnackbarSuccess("Invitation has been sent successfully");
            this.inviteSent = true;
          },
          () => this.showSnackbarError("Error sending invite"),
        )
        .finally(() => (this.inviteSending = false));
    },
    resendInvite() {
      this.sendInvite()
        .then(() => (this.inviteSent = true))
        .finally(() => (this.showInviteResendConfirmation = false));
    },
    onEmailBlur(e) {
      this.transformLowerCase(e);
      this.emailExistsValidator(e);
    },
    async emailExistsValidator(e) {
      const email = e.target.value;
      this.emailErrors = [];
      if (!email) return;

      const usersResponse = await this.$api.users.findUsers({ email });
      if (usersResponse.success && usersResponse.data.items.filter((user) => user.id !== this.item?.id).length > 0) {
        this.emailErrors = ["User with this email already exists"];
        return;
      }
      const archivedUsersResponse = await this.$api.users.findUsers({ email, archived: true });
      if (
        archivedUsersResponse.success &&
        archivedUsersResponse.data.items.filter((user) => user.id !== this.item?.id).length > 0
      ) {
        this.emailErrors = ["User with this email already exists (in Archive)"];
      }
    },
  },
};
</script>

<template>
  <div class="pa-2 mb-6">
    <div class="user-header d-flex align-center justify-space-between mb-3 flex-wrap">
      <div class="user-header__left d-flex align-center">
        <div class="subheading font-weight-medium">User information</div>
        <v-btn
          v-if="user.id && !user.mobileUserHasPassword && !inviteSent"
          :loading="inviteSending"
          class="font-weight-600 text-uppercase pr-3 pl-3 font-size-12 ml-6"
          color="success"
          height="28"
          variant="flat"
          @click="sendInvite"
        >
          Send invite
        </v-btn>
        <div v-if="user.id && user.mobileUserHasPassword && !user.mobileUserLastLogin" class="d-inline-block">
          <span class="ml-6">
            <v-icon class="text-grey mr-2" size="small">mdi-check</v-icon>
            <span class="text-uppercase text-grey font-weight-600">Invite sent</span>
          </span>
          <v-btn
            v-if="!inviteSent"
            class="font-weight-600 text-uppercase pr-3 pl-3 font-size-12 ml-6"
            color="primary"
            height="28"
            variant="outlined"
            @click.stop="showInviteResendConfirmation = true"
          >
            Resend
          </v-btn>
        </div>
        <span v-if="user.id && user.mobileUserHasPassword && user.mobileUserLastLogin" class="ml-6">
          <v-icon class="text-green mr-2" size="small">mdi-check</v-icon>
          <span class="text-uppercase text-green font-weight-600">Invite accepted</span>
        </span>
      </div>
      <div class="user-header__right d-flex align-center flex-wrap">
        <div class="d-flex align-center mr-6">
          <span class="text-grey-darken-1 mr-2">Created date:</span>
          <span class="text-grey-darken-3">
            {{ $filters.date(user.createdTime || $dayjs()) }}
          </span>
        </div>
        <dispatcher-select
          id="hired_by_id"
          v-model="user.hiredById"
          class="mb-0 created-by__select subheading text-grey-darken-4 mr-6"
          label="Hired by"
        />
        <v-text-field
          id="score"
          v-model="user.score"
          class="score-points__select"
          color="primary"
          density="compact"
          hide-details
          label="Score points"
          type="number"
          variant="outlined"
        />
      </div>
    </div>
    <div class="pa-5 mb-6 bg-white rounded personal-block personal-information">
      <div class="caption text-grey-darken-1 mb-3">Personal information</div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            id="name"
            v-model="user.name"
            :rules="[requiredValidator]"
            autocomplete="off1"
            class="required"
            color="primary"
            density="compact"
            hide-details="auto"
            label="Name"
            rel="name"
            variant="outlined"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            id="language"
            v-model="user.language"
            :items="languages"
            :rules="[requiredValidator]"
            class="mb-0 width-100 required"
            color="primary"
            density="compact"
            hide-details="auto"
            item-title="name"
            item-value="id"
            label="Language"
            variant="outlined"
          />
        </v-col>
        <v-col cols="3">
          <date-picker
            id="birthday"
            v-model="user.birthday"
            :max="
              $dayjs()
                .subtract(user.isDriver ? 21 : 18, 'year')
                .format('YYYY-MM-DD')
            "
            :rules="[requiredValidator]"
            label="Birthday"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            id="email"
            v-model="user.email"
            :error-messages="emailErrors"
            :rules="[requiredValidator, emailValidator]"
            autocomplete="off1"
            class="required"
            color="primary"
            density="compact"
            hide-details="auto"
            label="Email"
            type="email"
            variant="outlined"
            @blur="onEmailBlur"
          />
        </v-col>
        <v-col>
          <v-text-field
            id="mobile_phone_1"
            v-model="user.mobilePhone"
            v-maska:[phoneMask]
            :rules="[requiredValidator, phoneValidator]"
            autocomplete="off1"
            class="mb-1 required"
            color="primary"
            density="compact"
            hide-details="auto"
            label="Mobile phone 1"
            variant="outlined"
          />
        </v-col>
        <v-col>
          <v-text-field
            id="mobile_phone_2"
            v-model="user.mobilePhone2"
            v-maska:[phoneMask]
            :rules="[phoneValidator]"
            autocomplete="off1"
            class="mb-1"
            color="primary"
            density="compact"
            hide-details="auto"
            label="Mobile phone 2"
            variant="outlined"
          />
        </v-col>
        <v-col>
          <v-text-field
            id="home_phone"
            v-model="user.homePhone"
            v-maska:[phoneMask]
            :rules="[phoneValidator]"
            autocomplete="off1"
            color="primary"
            density="compact"
            hide-details="auto"
            label="Home phone"
            variant="outlined"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <omni-textarea id="note" v-model="user.note" label="Dispatcher note" rows="4"></omni-textarea>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col lg="8">
        <div class="pa-5 bg-white rounded personal-block personal-address">
          <address-field v-model="user" label="Address" />
        </div>
      </v-col>
      <v-col lg="4">
        <div class="pa-5 bg-white rounded personal-block personal-address height-100">
          <div class="caption text-grey-darken-1 mb-3">Emergency contact</div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                id="emergency_contact_full_name"
                v-model="user.emergencyContactFullName"
                autocomplete="off1"
                color="primary"
                density="compact"
                hide-details="auto"
                label="Name"
                variant="outlined"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                id="emergency_contact_relation"
                v-model="user.emergencyContactRelation"
                autocomplete="off1"
                color="primary"
                density="compact"
                hide-details="auto"
                label="Relation"
                variant="outlined"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                id="emergency_contact_phone"
                v-model="user.emergencyContactPhone"
                v-maska:[phoneMask]
                :rules="[phoneValidator]"
                autocomplete="off1"
                color="primary"
                density="compact"
                hide-details="auto"
                label="Phone"
                variant="outlined"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
  <omni-dialog
    v-model="showInviteResendConfirmation"
    primary-action-label="Send"
    size="small"
    title="Resend invite"
    @primary-action:click="resendInvite"
  >
    <div class="text-body-2 text-left text-grey text-grey-darken-1 my-4">
      Are you sure want to resend invite to this user?
    </div>
  </omni-dialog>
</template>

<style lang="scss" scoped>
.score-points__select {
  width: 110px;
  flex: 0 0 110px;
}

.created-by__select {
  width: 240px;
  flex: 0 0 240px;

  .v-input__control .v-text-field__details {
    display: none !important;
  }
}
</style>
