<script setup lang="ts">
import { defineProps, withDefaults } from "vue";

const props = withDefaults(
  defineProps<{
    expanded: boolean;
  }>(),
  {
    expanded: false,
  }
);
</script>

<template>
  <v-btn :class="{ expanded: props.expanded }" class="expandable-btn" icon="mdi-chevron-down" size="small" variant="plain" />
</template>

<style lang="scss" scoped>
.expandable-btn {
  transition: 0.25s ease-in-out;
  z-index: 2;

  &.expanded {
    transform: rotate(180deg);
  }
}
</style>
