<script>
import BaseView from "@/views/BaseView.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import PopupMixin from "@/mixins/popup-mixin";
import OmniTable from "@/components/OmniTable.vue";
import ExpandButton from "@/components/controls/ExpandButton.vue";
import OrdersMixin from "./OrdersMixin.vue";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import OrderStopsList from "@/views/orders/components/OrderStopsList.vue";
import AttachTruckDialog from "@/views/trips/edit/dialogs/AttachTruckDialog.vue";
import OrderCertificates from "@/views/orders/components/OrderCertificates.vue";
import ShowMore from "@/components/ShowMore.vue";
import OrderStatus from "@/components/status/OrderStatus.vue";
import OmniDialog from "@/components/Dialog.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "OrdersList",
  components: {
    OmniDialog,
    OrderStatus,
    ShowMore,
    OrderCertificates,
    AttachTruckDialog,
    OrderStopsList,
    OmniTextarea,
    ExpandButton,
    OmniTable,
  },
  mixins: [BaseView, OrdersMixin, UsersMixin, PopupMixin],
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    searchParams: Object,
  },
  emits: ["params"],
  data() {
    return {
      data: { items: [], total: 0 },
      sortDesc: false,
      expanded: [],
      loading: true,
      attachTruckDialog: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher", "dispatchers", "mobile"]),
    headers() {
      const allHeaders = {
        number: {
          key: "number",
          title: "Order",
          sortable: false,
          minWidth: 175,
        },
        refNumbers: {
          key: "refNumbers",
          title: "Ref number / Bill to company",
          sortable: false,
        },
        status: {
          key: "status",
          title: "Status",
          sortable: false,
          class: "col-status",
        },
        firstPickup: {
          key: "firstPickup",
          title: "First pick up, EST",
          sortable: false,
          class: "col-pickup",
        },
        miles: {
          key: "miles",
          title: "Loaded miles",
          sortable: false,
        },
        lastDelivery: {
          key: "lastDelivery",
          title: "Last delivery, EST",
          sortable: false,
          class: "col-delivery",
        },
        bookedBy: {
          key: "bookedBy",
          title: "Booked by",
          sortable: false,
        },
      };

      const columns = ["number", "refNumbers", "status", "firstPickup", "miles", "lastDelivery", "bookedBy"];
      const headers = columns.map((name) => allHeaders[name]);
      if (this.canEdit) {
        headers.push({
          title: "Actions",
          key: "actions",
          align: "left",
          class: "col-actions",
          sortable: false,
        });
      }

      return headers;
    },
  },
  watch: {
    async searchParams(newParams, oldParams) {
      if (JSON.stringify(newParams) !== JSON.stringify(oldParams)) {
        this.params.page = 1;
        await this.refresh();
      }
    },
  },
  async created() {
    if (this.searchParams) {
      this.params.page = 1;
      await this.refresh();
    }
  },
  methods: {
    attachTruck(order) {
      this.attachTruckOrder = order;
      this.attachTruckDialog = true;
    },
    attachToOrder(payload) {
      this.$router.push({
        name: "trip-edit",
        params: { id: "new" },
        query: { order: this.attachTruckOrder.id, truck: payload.truck?.id },
      });
    },
    getDispatcher(dispatcherId) {
      const dispatcher = this.dispatchers.find((d) => d.id === dispatcherId);
      return dispatcher || {};
    },
    expand(itemId) {
      if (this.expanded.length === 1 && this.expanded[0] === itemId) {
        this.expanded = [];
      } else {
        this.expanded = [itemId];
      }
    },
    getItemClass(item) {
      if (item.status === "h") return "status-hold";
      return "";
    },
    async refresh() {
      const params = { ...this.searchParams, ...this.params };

      const response = await this.$api.orders.findOrders(params);
      if (response.success) {
        this.data = response.data;
        this.data.items = this.data.items.map((order) => {
          let pickupNum = 1,
            deliveryNum = 1;
          for (const stop of order.orderStops) {
            if (stop.type === "d") {
              order.lastDelivery = stop;
              stop.name = "Delivery #" + deliveryNum;
              deliveryNum++;
            }
            if (stop.type === "p") {
              if (pickupNum === 1) {
                order.firstPickup = stop;
              }
              stop.name = "Pickup #" + pickupNum;
              pickupNum++;
            }
          }
          return order;
        });
        this.$emit("params", this.data.params);
      }

      this.loading = false;
    },
    viewTrip(order) {
      return this.$router.push({ name: "trip-view", params: { id: order.tripId } });
    },
  },
};
</script>

<template>
  <omni-table
    v-model:expanded="expanded"
    :footer-props="{ 'items-per-page-options': pagination.itemsPerPage }"
    :headers="headers"
    :item-class="getItemClass"
    :items="data.items"
    :items-length="data.total"
    :items-per-page="params.pageSize"
    :loading="loading"
    :mobile-breakpoint="0"
    :page="params.page"
    :show-all-option="false"
    @update:page="updatePage"
    @update:items-per-page="updatePageSize"
  >
    <template #[`item.number`]="{ item }">
      <div class="d-flex align-center">
        <expand-button
          :expanded="expanded.length === 1 && expanded[0] === item.id"
          class="mr-3"
          color="primary"
          size="xsmall"
          @click="expand(item.id)"
        />
        <div class="mr-3">
          <router-link
            :to="{ name: 'order-view', params: { id: item.id } }"
            class="text-decoration-none text-primary"
            data-qa="order-number"
          >
            #{{ item.number }}
          </router-link>
        </div>
        <order-certificates :order="item" class="pr-4"></order-certificates>
        <div class="status-items text-right">
          <v-tooltip location="top start">
            <template #activator="{ props }">
              <v-icon
                v-if="item.temperatures?.length > 0 || item.protectFromFreezing"
                color="blue"
                size="16"
                v-bind="props"
              >
                mdi-snowflake
              </v-icon>
            </template>
            <template v-if="item.temperatures?.length > 0">
              Temperature control from {{ item.temperatures[0] }} to {{ item.temperatures[1] }} ℉
            </template>
            <template v-else-if="item.protectFromFreezing">Protect from freezing</template>
          </v-tooltip>
        </div>
      </div>
    </template>
    <template #[`item.refNumbers`]="{ item }">
      <div class="d-flex flex-column row-gap-2">
        <show-more :items="item.refNumbers" data-qa="ref-number"></show-more>
        <router-link
          :to="{ name: 'customer-edit', params: { id: item.customer.id } }"
          class="text-primary font-size-12 text-decoration-none"
          data-qa="bill_to_customer"
          target="_blank"
        >
          {{ item.customer.name }}
        </router-link>
      </div>
    </template>
    <template #[`item.status`]="{ item }">
      <order-status :order="item"></order-status>
    </template>
    <template #[`item.firstPickup`]="{ item }">
      <div v-if="item.firstPickup" class="d-flex flex-column row-gap-2 text-grey-darken-3">
        <div data-qa="first-stop-address">{{ item.firstPickup.facility.address }}</div>
        <div class="font-size-12 text-grey-darken-2" data-qa="first-stop-time">
          {{ getTimeType(item.firstPickup.timeType) }} {{ getStopTime(item.firstPickup) }}
        </div>
      </div>
    </template>
    <template #[`item.lastDelivery`]="{ item }">
      <div v-if="item.lastDelivery" class="d-flex flex-column row-gap-2 text-grey-darken-3">
        <div data-qa="last-stop-address">{{ item.lastDelivery.facility.address }}</div>
        <div class="font-size-12 text-grey-darken-2" data-qa="last-stop-time">
          {{ getTimeType(item.lastDelivery.timeType) }} {{ getStopTime(item.lastDelivery) }}
        </div>
      </div>
    </template>
    <template #[`item.bookedBy`]="{ item }">
      <span class="text-primary" data-qa="dispatcher">{{ getDispatcher(item.bookedBy).name }}</span>
    </template>
    <template #[`item.miles`]="{ item }"> {{ $filters.miles(item.totalDistance) }} miles</template>
    <template #[`item.actions`]="{ item }">
      <div class="d-flex align-center">
        <v-btn
          :to="{ name: 'order-view', params: { id: item.id } }"
          class="pa-1 table-action"
          size="small"
          variant="flat"
        >
          <v-icon class="text-primary" small>mdi-eye-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="!item.tripId"
          class="text-uppercase font-weight-600"
          color="primary"
          size="small"
          variant="flat"
          @click="attachTruck(item)"
        >
          Assign
        </v-btn>
        <v-btn
          v-else
          class="text-uppercase font-weight-600"
          color="success"
          size="small"
          variant="flat"
          @click="viewTrip(item)"
        >
          View trip
        </v-btn>
      </div>
    </template>
    <template #expanded-row="{ columns, item }">
      <tr>
        <td :colspan="columns.length" class="dop-row">
          <div class="grey lighten-4">
            <v-row>
              <v-col lg="3" md="4" sm="12">
                <div class="bg-white rounded pa-4 height-100">
                  <div class="d-flex mb-3">
                    <span class="text-grey-darken-2 mr-2" style="min-width: 75px">Assign to:</span>
                    <span class="text-primary">Courtney Henry</span>
                  </div>
                  <div class="d-flex mb-3">
                    <span class="text-grey-darken-2 mr-2" style="min-width: 75px">Booked by:</span>
                    <span class="text-primary" data-qa="extended-booked-by">
                      {{ getDispatcher(item.bookedBy).name }}
                    </span>
                  </div>
                  <div class="d-flex mb-3">
                    <span class="text-grey-darken-2 mr-2" style="min-width: 75px">Rate:</span>
                    <span class="text-grey-darken-3 font-weight-medium" data-qa="extended-rate">{{ item.rate }}$</span>
                  </div>
                </div>
              </v-col>
              <v-col lg="9" md="8" sm="12">
                <div class="bg-white rounded pa-4 height-100 position-relative">
                  <div v-if="item.notStackable" class="block-icon">
                    <img alt="package-variant" src="@/assets/images/package-variant-closed.svg" width="20" />
                  </div>
                  <div class="mb-8">
                    <order-stops-list :distances="item.distances" :stops="item.orderStops"></order-stops-list>
                  </div>
                  <div class="d-flex">
                    <div class="d-flex align-center mr-2 bg-blue-grey-lighten-5 rounded total-blocks">
                      <span class="text-grey-darken-2 caption mr-1">Total distance:</span>
                      <span class="font-size-12 text-grey-darken-2 font-weight-600">
                        {{ $filters.miles(item.totalDistance) }} miles
                      </span>
                    </div>
                    <div class="d-flex align-center mr-2 bg-blue-grey-lighten-5 rounded total-blocks">
                      <span class="text-grey-darken-2 caption mr-1">Total quantity:</span>
                      <span class="font-size-12 text-grey-darken-2 font-weight-600">
                        {{ item.totalQuantity }} pallets
                      </span>
                    </div>
                    <div class="d-flex align-center bg-blue-grey-lighten-5 rounded total-blocks">
                      <span class="text-grey-darken-2 caption mr-1">Total weight:</span>
                      <span class="font-size-12 text-grey-darken-2 font-weight-600"> {{ item.totalWeight }} lbs </span>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <omni-textarea
            v-model="item.generalNote"
            :readonly="true"
            class="mt-4 mb-3 bg-white"
            label="General note"
            rows="4"
          />
        </td>
      </tr>
    </template>
  </omni-table>

  <omni-dialog v-model="attachTruckDialog" size="large" title="Attach truck/carrier" @close="attachTruckDialog = false">
    <attach-truck-dialog @attach="attachToOrder" />
  </omni-dialog>
</template>

<style lang="scss" scoped>
.block__footer__info + .block__footer__info,
.block-address + .block-address {
  margin-top: 24px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  height: auto !important;
  min-width: auto !important;
}

.dop-row {
  border-right: 0 !important;
  padding-bottom: 12px;
  background: #f8f8fb;

  &__inner {
    box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.16);
  }
}

.total-blocks {
  max-width: 190px;
  flex: 0 0 190px;
  padding: 8px;
}

.delivery-info__line {
  position: relative;

  &:not(:last-child):before {
    position: absolute;
    bottom: -9px;
    content: "";
    display: block;
    border: 1px dashed #bdbdbd;
    width: 2px;
    height: calc(100% - 11px);
    left: 7px;
  }
}

.block-inner {
  position: relative;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.block-label {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 2px 8px;
}

.blocks-items {
  margin: 0 -8px;
}

.table-action {
  width: auto !important;
  min-width: auto !important;
  padding: 0 2px !important;
  background: transparent !important;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.location {
  line-height: 17px;
}

.table-status {
  white-space: nowrap;
  display: inline-block;
  line-height: 16px !important;
}

.type-language {
  line-height: 12px;
  font-size: 11px;
  padding: 2px 0;
}

.line-height-1 {
  line-height: 1 !important;
}

.status-item {
  font-size: 9px;
  line-height: 16px;
  height: 16px !important;
  border-radius: 100px;
  padding: 0 2px !important;
  min-width: auto !important;
}

.currency-icon {
  width: 18px;
  flex: 0 0 18px;
  height: 18px;
  font-size: 13px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: url(@/assets/images/currency-icon.svg) no-repeat;
  background-size: 18px;

  &.small {
    width: 14px;
    flex: 0 0 14px;
    height: 14px;
    background-size: 14px;
  }
}

.block-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}

.b-small {
  // max-width: 8%;
  // flex: 0 0 8%;
  padding: 0 16px;
}

.b-medium {
  max-width: 13%;
  flex: 0 0 13%;
  padding: 0 16px;
}

.b-big {
  max-width: 24%;
  flex: 0 0 24%;
  padding: 0 16px;
}

@media (max-width: 1400px) {
  .b-medium {
    max-width: 20%;
    flex: 0 0 20%;
  }
  .b-big {
    max-width: 30%;
    flex: 0 0 30%;
  }
}

@media (max-width: 1250px) {
  .b-small {
    max-width: 30%;
    flex: 0 0 30%;
  }
  .b-medium,
  .b-big {
    max-width: 47%;
    flex: 0 0 47%;
  }
  .delivery-info__line .b-big:nth-child(3) {
    padding-left: 35px;
  }
}
</style>

<style lang="scss">
.general-notes.v-text-field > .v-input__control > .v-input__slot:before {
  display: none;
}

.col-number {
  min-width: 160px;
  width: 10%;
}

thead .col-status {
  width: 6%;
  min-width: 145px;
}

.col-info {
  width: 15%;
  min-width: 250px;
}

.col-dims,
.col-payload {
  width: 1%;
  min-width: 150px;
}

.col-location {
  width: 15%;
  min-width: 165px;
}

.col-actions {
  width: 1%;
  min-width: 170px;
}

.col-distance {
  width: 11.8%;
  min-width: 180px;
}

.v-application .location-block .caption {
  font-size: 11px !important;
  line-height: 12px;
}

.font-size-11 {
  font-size: 11px;
}

.subheading {
  font-size: 16px;
}
</style>
