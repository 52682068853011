<script>
import * as pdfjs from "pdfjs-dist";
import * as Sentry from "@sentry/vue";
import { ConfigMixin } from "@/mixins/config.mixin.ts";

export default {
  name: "PdfViewer",
  mixins: [ConfigMixin],
  props: {
    data: String,
  },
  data() {
    return {
      containerId: "pdf-viewport",
      pageWrapperClass: "page-wrapper",
      maxZoomFactor: 300,
      minZoomFactor: 25,
      zoomStep: 25,
      wheelZoomFactor: 0.2,
      zoomFactor: 100,
      initialWidth: null,
      rerenderTimeoutId: null,
      rerenderTimeout: 500,
    };
  },
  async mounted() {
    pdfjs.GlobalWorkerOptions.workerSrc = this.getPdfWorkerSrc(pdfjs.version);
    this.loadDocument();
  },
  methods: {
    loadDocument() {
      const loadingTask = pdfjs.getDocument(this.data);
      loadingTask.promise.then(
        (pdf) => {
          for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            this.renderPage(pdf, pageNumber);
          }
        },
        function (reason) {
          // PDF loading error
          console.error(reason);
        },
      );
    },
    renderPage(pdf, pageNumber) {
      pdf.getPage(pageNumber).then((page) => {
        const viewport = page.getViewport({ scale: this.zoomFactor / 100 });

        // Prepare canvas using PDF page dimensions
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        if (!this.initialWidth) {
          this.initialWidth = viewport.width;
        }

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        let pageWrapper = document
          .getElementById(this.containerId)
          .querySelector("div." + this.pageWrapperClass + ":nth-child(" + pageNumber + ")");
        if (!pageWrapper) {
          pageWrapper = document.createElement("div");
          pageWrapper.classList.add(this.pageWrapperClass);
          pageWrapper.appendChild(canvas);
          document.getElementById(this.containerId).appendChild(pageWrapper);
        } else {
          pageWrapper.replaceChild(canvas, pageWrapper.querySelector("canvas"));
        }
        page.render(renderContext);
        const sentryClient = Sentry.getClient();
        if (sentryClient) {
          sentryClient.getIntegrationByName("ReplayCanvas").snapshot(canvas);
        }
      });
    },
    zoomIn() {
      if (this.zoomFactor < this.maxZoomFactor) {
        this.zoomFactor =
          this.zoomFactor + this.zoomStep < this.maxZoomFactor ? this.zoomFactor + this.zoomStep : this.maxZoomFactor;
        this.scale();
      }
    },
    zoomOut() {
      if (this.zoomFactor > this.minZoomFactor) {
        this.zoomFactor =
          this.zoomFactor - this.zoomStep > this.minZoomFactor ? this.zoomFactor - this.zoomStep : this.minZoomFactor;
        this.scale();
      }
    },
    onWheel(event) {
      if (event.metaKey || event.ctrlKey) {
        event.preventDefault();
        let zoomFactor = Math.round(this.zoomFactor - event.deltaY * this.wheelZoomFactor);
        if (zoomFactor > this.maxZoomFactor) {
          zoomFactor = this.maxZoomFactor;
        }
        if (zoomFactor < this.minZoomFactor) {
          zoomFactor = this.minZoomFactor;
        }
        if (this.zoomFactor !== zoomFactor) {
          this.zoomFactor = zoomFactor;
        }
        this.scale();
      }
    },
    scale() {
      const pages = document.querySelectorAll("." + this.pageWrapperClass + " canvas");
      pages.forEach((page) => this._scalePage(page));
      if (this.rerenderTimeoutId) {
        clearTimeout(this.rerenderTimeoutId);
      }
      this.rerenderTimeoutId = setTimeout(() => {
        this.loadDocument();
      }, this.rerenderTimeout);
    },
    _scalePage(page) {
      if (!page.style.width) {
        page.style.width = page.width + "px";
      }
      page.style.width = (this.initialWidth * this.zoomFactor) / 100 + "px";
    },
  },
};
</script>

<template>
  <div class="pdf-container" @wheel="onWheel">
    <div class="zoom-controls">
      <div :class="{ disabled: zoomFactor <= minZoomFactor }" class="zoom-controls__control" @click="zoomOut">
        <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.3198574,14.9056439 L21.7071068,20.2928932 L20.2928932,21.7071068 L14.9056439,16.3198574 C13.5509601,17.3729184 11.8487115,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 C14.418278,2 18,5.581722 18,10 C18,11.8487115 17.3729184,13.5509601 16.3198574,14.9056439 Z M10,16 C13.3137085,16 16,13.3137085 16,10 C16,6.6862915 13.3137085,4 10,4 C6.6862915,4 4,6.6862915 4,10 C4,13.3137085 6.6862915,16 10,16 Z M6,11 L6,9 L14,9 L14,11 L6,11 Z"
            fill-rule="evenodd"
          />
        </svg>
      </div>
      <div class="zoom-controls__value">{{ zoomFactor }}%</div>
      <div :class="{ disabled: zoomFactor >= maxZoomFactor }" class="zoom-controls__control" @click="zoomIn">
        <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.3198574,14.9056439 L21.7071068,20.2928932 L20.2928932,21.7071068 L14.9056439,16.3198574 C13.5509601,17.3729184 11.8487115,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 C14.418278,2 18,5.581722 18,10 C18,11.8487115 17.3729184,13.5509601 16.3198574,14.9056439 Z M10,16 C13.3137085,16 16,13.3137085 16,10 C16,6.6862915 13.3137085,4 10,4 C6.6862915,4 4,6.6862915 4,10 C4,13.3137085 6.6862915,16 10,16 Z M9,9 L9,6 L11,6 L11,9 L14,9 L14,11 L11,11 L11,14 L9,14 L9,11 L6,11 L6,9 L9,9 Z"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div :id="containerId"></div>
  </div>
</template>

<style lang="scss">
.pdf-container {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;

  .page-wrapper {
    canvas {
      transition: all 100ms ease-in-out;
    }
  }

  .zoom-controls {
    position: fixed;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: 1;
    color: #ccc;

    &__control {
      width: 40px;
      height: 40px;
      padding: 8px;
      cursor: pointer;
      transition: all 150ms ease-in-out;
      background-color: rgba(50, 50, 50, 0.9);

      &:not(.disabled):hover {
        color: #fff;
        background-color: rgba(10, 10, 10, 0.9);
      }
    }

    &__value {
      height: 40px;
      width: 60px;
      text-align: center;
      padding: 8px;
      line-height: 24px;
      font-weight: bold;
      user-select: none;
      background-color: rgba(50, 50, 50, 0.9);
    }
  }
}

.vue-pdf-embed {
  display: inline-block;

  & > div {
    margin-bottom: 16px;
    margin-top: 16px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  }
}
</style>
