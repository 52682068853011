import WebSocket from "isomorphic-ws";
import {
  addConnectionEventListeners,
  chatCodes,
  randomId,
  removeConnectionEventListeners,
  retryInterval,
  sleep,
} from "./ws.utils";
import type {
  ConnectAPIResponse,
  ConnectionOpen,
  EventHandler,
  LogLevel,
  OnlineStatusHandler,
  RecoveryHandler,
  UR,
} from "./ws.types";
import { transformKeysToCamel, transformKeysToSnake } from "@/api/utils";

// Type guards to check WebSocket error type
const isCloseEvent = (res: WebSocket.CloseEvent | WebSocket.Data | WebSocket.ErrorEvent): res is WebSocket.CloseEvent =>
  (res as WebSocket.CloseEvent).code !== undefined;

const isErrorEvent = (res: WebSocket.CloseEvent | WebSocket.Data | WebSocket.ErrorEvent): res is WebSocket.ErrorEvent =>
  (res as WebSocket.ErrorEvent).error !== undefined;

/**
 * WSConnection - A WS connection that reconnects upon failure.
 * - the browser will sometimes report that you're online or offline
 * - the WS connection can break and fail (there is a 30s health check)
 * - sometimes your WS connection will seem to work while the user is in fact offline
 * - to speed up online/offline detection you can use the window.addEventListener('offline');
 *
 * There are 4 ways in which a connection can become unhealthy:
 * - websocket.onerror is called
 * - websocket.onclose is called
 * - the health check fails and no event is received for ~40 seconds
 * - the browser indicates the connection is now offline
 *
 * There are 2 assumptions we make about the server:
 * - state can be recovered by querying the channel again
 * - if the servers fails to publish a message to the client, the WS connection is destroyed
 */
export class WSConnection {
  url?: string;
  tokenProvider?: () => string | undefined;
  eventHandler?: EventHandler;
  recoveryHandler?: RecoveryHandler;
  onlineStatusHandler?: OnlineStatusHandler | null;
  connectionID?: string;
  connectionOpen?: ConnectAPIResponse;
  consecutiveFailures: number;
  pingInterval: number;
  healthCheckTimeoutRef?: number;
  isConnecting: boolean;
  isDisconnected: boolean;
  isHealthy: boolean;
  isResolved?: boolean;
  lastEvent: Date | null;
  connectionCheckTimeout: number;
  connectionCheckTimeoutRef?: number;
  rejectPromise?: (
    reason?: Error & { code?: string | number; isWSFailure?: boolean; StatusCode?: string | number },
  ) => void;
  requestID: string | undefined;
  resolvePromise?: (value: ConnectionOpen) => void;
  totalFailures: number;
  ws?: WebSocket;
  wsID: number;

  constructor() {
    /** consecutive failures influence the duration of the timeout */
    this.consecutiveFailures = 0;
    /** keep track of the total number of failures */
    this.totalFailures = 0;
    /** We only make 1 attempt to reconnect at the same time */
    this.isConnecting = false;
    /** To avoid reconnect if client is disconnected */
    this.isDisconnected = false;
    /** Boolean that indicates if the connection promise is resolved */
    this.isResolved = false;
    /** Boolean that indicates if we have a working connection to the server */
    this.isHealthy = false;
    /** Incremented when a new WS connection is made */
    this.wsID = 1;
    /** Store the last event time for health checks */
    this.lastEvent = null;
    /** Send a health check message every 25 seconds */
    this.pingInterval = 25 * 1000;
    this.connectionCheckTimeout = this.pingInterval + 10 * 1000;

    addConnectionEventListeners(this.onlineStatusChanged);
  }

  _log(msg: string, extra: UR = {}, level: LogLevel = "info") {
    console.log(level, "connection:" + msg, { tags: ["connection"], ...extra });
  }

  async connect(
    url: string,
    tokenProvider: () => string | undefined,
    eventHandler: EventHandler,
    recoveryHandler: RecoveryHandler,
    onlineStatusHandler?: OnlineStatusHandler,
    timeout: number = 15000,
  ): Promise<ConnectionOpen | void> {
    if (this.isConnecting) {
      throw Error(`You've called connect twice, can only attempt 1 connection at the time`);
    }
    this.url = url;
    this.tokenProvider = tokenProvider;
    this.eventHandler = eventHandler;
    this.recoveryHandler = recoveryHandler;
    this.onlineStatusHandler = onlineStatusHandler;

    this.isDisconnected = false;

    try {
      const healthCheck = await this._connect();
      this.consecutiveFailures = 0;

      this._log(`connect() - Established ws connection with healthcheck: ${healthCheck}`);
    } catch (error: any) {
      this.isHealthy = false;
      this.consecutiveFailures += 1;

      if (!error.isWSFailure) {
        // API rejected the connection and we should not retry
        throw new Error(
          JSON.stringify({
            code: error.code,
            StatusCode: error.StatusCode,
            message: error.message,
            isWSFailure: error.isWSFailure,
          }),
        );
      }
    }

    return await this._waitForHealthy(timeout);
  }

  /**
   * _waitForHealthy polls the promise connection to see if its resolved until it times out
   * the default 15s timeout allows between 2~3 tries
   * @param timeout duration(ms)
   */
  async _waitForHealthy(timeout = 15000) {
    return Promise.race([
      (async () => {
        const interval = 50; // ms
        for (let i = 0; i <= timeout; i += interval) {
          try {
            return await this.connectionOpen;
          } catch (error: any) {
            if (i === timeout) {
              throw new Error(
                JSON.stringify({
                  code: error.code,
                  StatusCode: error.StatusCode,
                  message: error.message,
                  isWSFailure: error.isWSFailure,
                }),
              );
            }
            await sleep(interval);
          }
        }
      })(),
      (async () => {
        await sleep(timeout);
        this.isConnecting = false;
        throw new Error(
          JSON.stringify({
            code: "",
            StatusCode: "",
            message: "initial WS connection could not be established",
            isWSFailure: true,
          }),
        );
      })(),
    ]);
  }

  disconnect(timeout?: number): Promise<void> {
    this._log(`disconnect() - Closing the websocket connection for wsID ${this.wsID}`);

    this.wsID += 1;
    this.isConnecting = false;
    this.isDisconnected = true;

    // start by removing all the listeners
    if (this.healthCheckTimeoutRef) {
      clearInterval(this.healthCheckTimeoutRef);
    }
    if (this.connectionCheckTimeoutRef) {
      clearInterval(this.connectionCheckTimeoutRef);
    }

    removeConnectionEventListeners(this.onlineStatusChanged);

    this.isHealthy = false;

    // remove ws handlers...
    if (this.ws && this.ws.removeAllListeners) {
      this.ws.removeAllListeners();
    }

    let isClosedPromise: Promise<void>;
    const { ws } = this;
    if (ws && ws.close && ws.readyState === ws.OPEN) {
      isClosedPromise = new Promise((resolve) => {
        const onclose = (event: WebSocket.CloseEvent) => {
          this._log(`disconnect() - resolving isClosedPromise ${event ? "with" : "without"} close frame`, { event });
          resolve();
        };

        ws.onclose = onclose;
        setTimeout(onclose, timeout != null ? timeout : 1000);
      });

      this._log(`disconnect() - Manually closed connection by calling client.disconnect()`);

      ws.close(chatCodes.WS_CLOSED_SUCCESS, "Manually closed connection by calling client.disconnect()");
    } else {
      this._log(`disconnect() - ws connection doesn't exist or it is already closed.`);
      isClosedPromise = Promise.resolve();
    }

    delete this.ws;

    return isClosedPromise;
  }

  /**
   * _connect - Connect to the WS endpoint
   */
  async _connect() {
    if (this.isConnecting || this.isDisconnected) return;
    this.isConnecting = true;
    this.requestID = randomId();

    try {
      this._setupConnectionPromise();
      const wsURL = this.url;
      this._log(`_connect() - Connecting to ${wsURL}`, { wsURL, requestID: this.requestID });
      this.ws = new WebSocket(wsURL);
      this.ws.onopen = this.onopen.bind(this, this.wsID);
      this.ws.onclose = this.onclose.bind(this, this.wsID);
      this.ws.onerror = this.onerror.bind(this, this.wsID);
      this.ws.onmessage = this.onmessage.bind(this, this.wsID);
      const response = await this.connectionOpen;
      this.isConnecting = false;

      if (response) {
        this.connectionID = response.connection_id;
        return response;
      }
    } catch (err: any) {
      this.isConnecting = false;
      this._log(`_connect() - Error - `, err);
      throw err;
    }
  }

  /**
   * _reconnect - Retry the connection to WS endpoint
   *
   * @param {{ interval?: number; refreshToken?: boolean }} options Following options are available
   *
   * - `interval`	{int}			number of ms that function should wait before reconnecting
   * - `refreshToken` {boolean}	reload/refresh user token be refreshed before attempting reconnection.
   */
  async _reconnect(options: { interval?: number; refreshToken?: boolean } = {}): Promise<void> {
    this._log("_reconnect() - Initiating the reconnect");

    // only allow 1 connection at the time
    if (this.isConnecting || this.isHealthy) {
      this._log("_reconnect() - Abort (1) since already connecting or healthy");
      return;
    }

    // reconnect in case of on error or on close
    // also reconnect if the health check cycle fails
    let interval = options.interval;
    if (!interval) {
      interval = retryInterval(this.consecutiveFailures);
    }
    // reconnect, or try again after a little while...
    await sleep(interval);

    // Check once again if by some other call to _reconnect is active or connection is
    // already restored, then no need to proceed.
    if (this.isConnecting || this.isHealthy) {
      this._log("_reconnect() - Abort (2) since already connecting or healthy");
      return;
    }

    if (this.isDisconnected) {
      this._log("_reconnect() - Abort (3) since disconnect() is called");
      return;
    }

    this._log("_reconnect() - Destroying current WS connection");

    // cleanup the old connection
    this._destroyCurrentWSConnection();

    if (options.refreshToken) {
      await this._authenticate();
    }

    try {
      const lastEvent = this.lastEvent;
      await this._connect();
      this._log("_reconnect() - Waiting for recoverCallBack");
      await this.recoveryHandler?.call(this, lastEvent);
      this._log("_reconnect() - Finished recoverCallBack");

      this.consecutiveFailures = 0;
    } catch (error: any) {
      this.isHealthy = false;
      this.consecutiveFailures += 1;
      if (error.code === chatCodes.TOKEN_EXPIRED) {
        this._log("_reconnect() - WS failure due to expired token, so going to try to reload token and reconnect");

        return this._reconnect({ refreshToken: true });
      }

      // reconnect on WS failures, don't reconnect if there is a code bug
      if (error.isWSFailure) {
        this._log("_reconnect() - WS failure, so going to try to reconnect");

        this._reconnect();
      }
    }
    this._log("_reconnect() - == END ==");
  }

  onlineStatusChanged = (event: Event): void => {
    if (event.type === "offline") {
      this._log("onlineStatusChanged() - Status changing to offline");
      this.onlineStatusHandler?.call(this, false);
      this._setHealth(false);
    } else if (event.type === "online") {
      this._log(`onlineStatusChanged() - Status changing to online. isHealthy: ${this.isHealthy}`);
      this.onlineStatusHandler?.call(this, true);
      if (!this.isHealthy) {
        this._reconnect({ interval: 10 });
      }
    }
  };

  sendCommand(command: string, data: Record<string, any> | null = null): void {
    data = data ? transformKeysToSnake(data) : {};
    data.command = command;
    this._log("sendCommand() - command", { command, data });
    this.ws?.send(JSON.stringify(data));
  }

  onopen = (wsID: number): void => {
    if (this.wsID !== wsID) return;
    this._log("onopen() - onopen callback", { wsID });
    this._authenticate();
  };

  _authenticate(): void {
    this._log("_authenticate() - authenticating websocket...");
    const token = this.tokenProvider?.call(this);
    if (token !== undefined) {
      return this.sendCommand("token", { token });
    }
    this._log("_authenticate() - error, token not provided");
  }

  onmessage = (wsID: number, event: MessageEvent): void => {
    if (this.wsID !== wsID) return;

    this._log("onmessage() - onmessage callback", { event, wsID });
    const data = typeof event.data === "string" ? transformKeysToCamel(JSON.parse(event.data)) : null;

    if (!this.isResolved && data) {
      this.isResolved = true;
      if (data.error) {
        this.rejectPromise?.(this._errorFromWSEvent(data, false));
        return;
      }

      this.resolvePromise?.(data as any);
      this._setHealth(true);
    }

    this.lastEvent = new Date();

    if (data && (data.command === "token" || data.command === "pong")) {
      this.scheduleNextPing();
    }

    this.eventHandler?.call(this, data);
    this.scheduleConnectionCheck();
  };

  onclose = (wsID: number, event: WebSocket.CloseEvent): void => {
    if (this.wsID !== wsID) return;

    this._log("onclose() - onclose callback - " + event.code, { event, wsID });

    if (event.code === chatCodes.WS_CLOSED_SUCCESS) {
      const error = new Error(`WS connection reject with error ${event.reason}`) as Error & WebSocket.CloseEvent;

      error.reason = event.reason;
      error.code = event.code;
      error.wasClean = event.wasClean;
      error.target = event.target;

      this.rejectPromise?.(error);
      this._log(`onclose() - WS connection reject with error ${event.reason}`, { event });
    } else {
      this.consecutiveFailures += 1;
      this.totalFailures += 1;
      this._setHealth(false);
      this.isConnecting = false;

      this.rejectPromise?.(this._errorFromWSEvent(event));

      this._log(`onclose() - WS connection closed. Calling reconnect ...`, { event });

      this._reconnect();
    }
  };

  onerror = (wsID: number, event: WebSocket.ErrorEvent): void => {
    if (this.wsID !== wsID) return;

    this.consecutiveFailures += 1;
    this.totalFailures += 1;
    this._setHealth(false);
    this.isConnecting = false;

    this.rejectPromise?.(this._errorFromWSEvent(event));
    this._log(`onerror() - WS connection resulted into error`, { event });

    this._reconnect();
  };

  _setHealth = (healthy: boolean): void => {
    if (healthy === this.isHealthy) return;

    this.isHealthy = healthy;
  };

  _errorFromWSEvent = (event: WebSocket.CloseEvent | WebSocket.Data | WebSocket.ErrorEvent, isWSFailure = true) => {
    let code;
    let statusCode;
    let message;
    if (isCloseEvent(event)) {
      code = event.code;
      statusCode = "unknown";
      message = event.reason;
    }

    if (isErrorEvent(event)) {
      code = event.error.code;
      statusCode = event.error.StatusCode;
      message = event.error.message;
    }

    this._log(`_errorFromWSEvent() - WS failed with code ${code}`, { event }, "warn");

    const error = new Error(`WS failed with code ${code} and reason - ${message}`) as Error & {
      code?: string | number;
      isWSFailure?: boolean;
      StatusCode?: string | number;
    };
    error.code = code;
    error.StatusCode = statusCode;
    error.isWSFailure = isWSFailure;
    return error;
  };

  _destroyCurrentWSConnection(): void {
    this.wsID += 1;

    try {
      this?.ws?.removeAllListeners();
      this?.ws?.close();
    } catch (e) {
      // we don't care
    }
  }

  _setupConnectionPromise = (): void => {
    this.isResolved = false;
    this.connectionOpen = new Promise<ConnectionOpen>((resolve, reject) => {
      this.resolvePromise = resolve;
      this.rejectPromise = reject;
    });
  };

  scheduleNextPing = (): void => {
    if (this.healthCheckTimeoutRef) {
      clearTimeout(this.healthCheckTimeoutRef);
    }

    this.healthCheckTimeoutRef = setTimeout(() => {
      try {
        this.sendCommand("ping");
      } catch (e) {
        // error will already be detected elsewhere
      }
    }, this.pingInterval);
  };

  scheduleConnectionCheck = (): void => {
    if (this.connectionCheckTimeoutRef) {
      clearTimeout(this.connectionCheckTimeoutRef);
    }

    this.connectionCheckTimeoutRef = setTimeout(() => {
      const now = new Date();
      if (this.lastEvent && now.getTime() - this.lastEvent.getTime() > this.connectionCheckTimeout) {
        this._log("scheduleConnectionCheck - going to reconnect");
        this._setHealth(false);
        this._reconnect();
      }
    }, this.connectionCheckTimeout);
  };
}

export default {
  install(app: any): void {
    app.config.globalProperties.$ws = new WSConnection();
  },
};
