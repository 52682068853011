import { useSnackbarStore } from "@/store/snackbar.store";
import { defineComponent } from "vue";

type Response = {
  success: boolean;
  status: number;
  error: {
    message: string;
  };
};

const NotFoundMixin = defineComponent({
  data(): { entityNotFound: boolean } {
    return {
      entityNotFound: false,
    };
  },
  methods: {
    notFound(): void {
      this.entityNotFound = true;
    },
    validateEntityResponse(response: Response): boolean {
      if (!response.success) {
        if (response.status === 404) {
          this.notFound();
        } else {
          const snackbarStore = useSnackbarStore();
          snackbarStore.showSnackbarError(response.error.message);
        }
        return false;
      }
      return true;
    },
  },
});

export default NotFoundMixin;
