<script>
import BaseView from "@/views/BaseView.vue";
import SearchField from "@/components/inputs/SearchField.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CarriersSearchForm",
  components: { TableFilterRow, TableFilter, SearchField },
  mixins: [BaseView],
  emits: ["search"],
  data() {
    return {
      params: {},
    };
  },
  created() {
    this.params = this.getSearchParams("carriers");
    this.search();
  },
  methods: {
    clear() {
      this.params = {};
      this.search();
    },
    search() {
      this.setSearchParams("carriers", this.params);
      this.$emit("search", this.params);
    },
  },
});
</script>

<template>
  <table-filter :expandable="false" @apply="search" @clear="clear">
    <template #header>
      <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
        <v-col cols="4">
          <search-field v-model="params.name" :label="$t('general.full-name')" name="name" @search="search" />
        </v-col>
        <v-col cols="4">
          <search-field
            v-model="params.fmcsaNumber"
            :label="$t('company.fmcsa_number')"
            maxlength="6"
            name="fmcsaNumber"
            @search="search"
          />
        </v-col>
      </table-filter-row>
    </template>
  </table-filter>
</template>
