<script lang="ts" setup>
import { defineProps, onMounted, provide, ref, withDefaults } from "vue";
import type { VForm } from "vuetify/components";

const props = withDefaults(
  defineProps<{
    flat?: boolean;
  }>(),
  {
    flat: false,
  },
);

const formSymbol = Symbol.for("OmniForm");

provide("formSymbol", formSymbol);

const form = ref<VForm | null>(null);

const validate = () => {
  return form.value?.validate();
};

defineExpose({
  validate,
});

onMounted(() => {
  if (!formSymbol) {
    throw new Error("OmniForm must provide formSymbol");
  }
});
</script>

<template>
  <v-container :class="{ 'px-6': !props.flat, 'px-0': props.flat }" :fluid="true" class="pt-8 pb-14">
    <v-form ref="form" aria-autocomplete="none" role="presentation" validate-on="lazy">
      <slot></slot>
    </v-form>
  </v-container>
</template>

<style lang="scss" scoped></style>
