<script>
import PhoneNumber from "@/components/PhoneNumber.vue";
import UserStatus from "@/components/status/UserStatus.vue";

export default {
  name: "UserSelect",
  components: { UserStatus, PhoneNumber },
  props: {
    id: String,
    coordinators: {
      type: Boolean,
      default: false,
    },
    drivers: {
      type: Boolean,
      default: false,
    },
    owners: {
      type: Boolean,
      default: false,
    },
    label: String,
    modelValue: Number,
    rules: Array,
    required: {
      type: Boolean,
      default: false,
    },
    excludeIds: Array,
    extendUsers: Array,
    extended: { type: Boolean, default: false },
    disabled: Boolean,
    showRole: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      isLoading: false,
      search: null,
      items: [],
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
    users() {
      return this.extendUsers
        ? Array.from([...this.items, ...this.extendUsers].reduce((m, o) => m.set(o.id, o), new Map()).values())
        : this.items;
    },
  },
  watch: {
    search(val, oldVal) {
      if (this.isLoading || !val || !oldVal) return;
      this.fetchUsers(val);
    },
    async model() {
      if (!this.items.some((u) => u.id === this.model)) {
        this.preloadUser(this.model);
      }
    },
  },
  mounted() {
    this.preloadUser(this.modelValue);
  },
  methods: {
    getStatus(item) {
      if (this.drivers) {
        return item.driverStatus;
      }
      if (this.owners) {
        return item.ownerStatus;
      }
      return null;
    },
    getRole(user) {
      const roles = [];
      if (user.isDriver) roles.push("Driver");
      if (user.isOwner) roles.push("Owner");
      if (user.isCoordinator) roles.push("Coordinator");
      return roles.join(" - ");
    },
    fetchUsers(term) {
      if (!term || term.length < 3) return;
      const params = { name: term, pageSize: 10 };
      if (this.coordinators) {
        params.coordinators = true;
      }
      if (this.drivers) {
        params.drivers = true;
      }
      if (this.owners) {
        params.owners = true;
      }

      this.isLoading = true;
      this.$api.users
        .findUsers(params)
        .then((resp) => {
          let response = resp.data.items;
          if (this.excludeIds) {
            this.items = response.filter((elem) => !this.excludeIds.includes(elem.id));
          } else {
            this.items = response;
          }
        })
        .catch((err) => {
          console.error("Error loading users", err);
        })
        .finally(() => (this.isLoading = false));
    },
    preloadUser(userId) {
      if (!userId) return;

      this.isLoading = true;
      this.$api.users
        .findUserById(userId)
        .then((resp) => {
          this.items.push(resp.data);
        })
        .catch((err) => {
          console.error("Error loading user", err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <v-autocomplete
    :id="id"
    v-model="model"
    v-model:search="search"
    :class="{ required: required }"
    :clearable="true"
    :disabled="disabled"
    :items="users"
    :label="label"
    :loading="isLoading"
    :rules="rules"
    class="mb-0 width-100"
    color="primary"
    density="compact"
    hide-details="auto"
    hide-no-data
    hide-selected
    item-title="name"
    item-value="id"
    single-line
    variant="outlined"
  >
    <template #item="{ item, props }">
      <div v-bind="props">
        <div class="d-flex select-option align-center">
          <div class="mr-4">
            <div>{{ item.raw.name }}</div>
            <div v-if="showRole" class="text-grey-darken-1 font-size-12">{{ getRole(item.raw) }}</div>
          </div>

          <phone-number
            v-if="extended"
            :is-callable="false"
            :number="item.raw.phone"
            class="font-size-14 mr-2"
            size="small"
          />
          <user-status
            v-if="extended && (drivers || owners) && getStatus(item.raw)"
            :status="getStatus(item.raw)"
            class="ml-auto"
          />
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>
