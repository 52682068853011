import { ApiClient } from "@/api/client.js";
import { UsersApi } from "@/api/users/users";
import { GeoApi } from "@/api/geo/geo";
import { AuthApi } from "@/api/auth/auth";
import { TripsApi } from "@/api/trips/trips";
import { TrucksApi } from "@/api/trucks/trucks";
import { ChatApi } from "@/api/chat/chat";
import { DispatchersApi } from "@/api/dispatchers/dispatchers";
import { ServiceApi } from "@/api/service/service";
import { CompanyApi } from "@/api/company/company";
import { OrdersApi } from "@/api/orders/orders";
import { FilesApi } from "@/api/files/files";
import type { Router } from "vue-router";
import type { App } from "vue";

export class API {
  public readonly client: ApiClient;
  public readonly auth: AuthApi;
  public readonly users: UsersApi;
  public readonly dispatchers: DispatchersApi;
  public readonly geo: GeoApi;
  public readonly trips: TripsApi;
  public readonly trucks: TrucksApi;
  public readonly chat: ChatApi;
  public readonly service: ServiceApi;
  public readonly company: CompanyApi;
  public readonly orders: OrdersApi;
  public readonly files: FilesApi;

  constructor() {
    this.client = new ApiClient();
    this.auth = new AuthApi(this.client);
    this.users = new UsersApi(this.client);
    this.dispatchers = new DispatchersApi(this.client);
    this.geo = new GeoApi(this.client);
    this.trips = new TripsApi(this.client);
    this.trucks = new TrucksApi(this.client);
    this.chat = new ChatApi(this.client);
    this.service = new ServiceApi(this.client);
    this.company = new CompanyApi(this.client);
    this.orders = new OrdersApi(this.client);
    this.files = new FilesApi(this.client);
  }

  setRouter(router: Router): void {
    this.client.setRouter(router);
  }
}

export default {
  install(app: App) {
    app.config.globalProperties.$api = new API();
  },
};
