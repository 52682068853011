<script>
import BaseView from "@/views/BaseView.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import TruckHelper from "@/helpers/truck.helper";
import TruckViewSidebar from "@/views/trucks/components/TruckViewSidebar.vue";
import SidebarLayout from "@/components/layout/SidebarLayout.vue";
import SidebarToggle from "@/components/layout/SidebarToggle.vue";
import NotFoundMixin from "@/mixins/not-found.mixin";
import NotFound from "@/views/misc/NotFound.vue";
import TruckAdditionalActions from "@/views/trucks/components/TruckAdditionalActions.vue";
import OmniTabs from "@/components/controls/OmniTabs.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "TruckView",
  components: {
    OmniTabs,
    TruckAdditionalActions,
    NotFound,
    SidebarToggle,
    SidebarLayout,
    TruckViewSidebar,
  },
  mixins: [TrucksMixin, BaseView, DateTimeMixin, NotFoundMixin],
  data() {
    return {
      ...mapState(useAppStore, ["dispatcher"]),
      data: { items: [], total: 0 },
      truck: {},
      loading: false,
      tab: 0,
      showReserveDialog: false,

      userSidebarCollapsed: false,
      sidebarSmall: false,
    };
  },
  computed: {
    TruckHelper() {
      return TruckHelper;
    },
    editLink() {
      return {
        name: this.$route.name.replace("view", "edit"),
        params: { id: this.truck.id },
      };
    },
  },
  async created() {
    await this.loadTruck();
  },
  methods: {
    isSidebarOpenDefault() {
      let windowWidth = window.innerWidth;
      return windowWidth > 1250;
    },
    async loadTruck() {
      this.loading = true;
      const resp = await this.$api.trucks.findTruckById(this.$route.params.id);
      if (!this.validateEntityResponse(resp)) return;
      const truck = { coordinators: [], ...resp.data };

      // load truck owner coordinators
      const coordinatorsResp = await this.$api.users.findOwnerCoordinators(truck.ownerId);
      if (coordinatorsResp.success) {
        truck.coordinators = coordinatorsResp.data.filter((c) => c.fullAccess);
      }

      this.truck = truck;
      this.loading = false;
    },
  },
};
</script>

<template>
  <v-container v-if="!entityNotFound" :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100">
    <sidebar-layout :sidebar-opened="isSidebarOpenDefault()">
      <div class="w-100">
        <div class="d-flex justify-space-between align-center flex-wrap mb-4">
          <div class="d-flex">
            <div class="text-grey-darken-4 font-weight-medium text-h6" data-qa="truck-number">
              Truck {{ truck.number }}
            </div>
            <v-chip v-if="truck.archived !== null" :label="true" class="ml-4" color="grey-darken-2" variant="flat">
              Archived
            </v-chip>
          </div>
          <div class="d-flex">
            <v-btn
              v-if="truck.id"
              :class="{ 'btn--disabled': truck.archived }"
              :disabled="truck.archived"
              :to="editLink"
              class="text-uppercase font-weight-600 mr-2"
              color="primary"
              variant="flat"
            >
              <v-icon class="mr-2" size="20"> mdi-pencil-outline</v-icon>
              {{ $t("general.edit") }} Truck
            </v-btn>
            <sidebar-toggle class="mr-2"></sidebar-toggle>
            <truck-additional-actions :truck="truck" @change="loadTruck"></truck-additional-actions>
          </div>
        </div>

        <v-card class="bg-transparent" variant="flat">
          <omni-tabs v-model="tab" class="rounded">
            <v-tab key="general">General</v-tab>
            <v-tab key="plannedRoutes">Planned routes</v-tab>
          </omni-tabs>

          <v-window v-model="tab" :touch="false" class="mt-4">
            <v-window-item value="general">
              <div class="h-100">
                <div class="box-shadow pa-6 bg-white rounded mb-6">
                  <div class="text-grey-darken-3 mb-6 font-weight-medium text-subtitle-1">Characteristics</div>
                  <div class="characteristics-items">
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Make</span>
                      <span class="text-grey-darken-3" data-qa="truck-make">{{ truck.make }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Model</span>
                      <span class="text-grey-darken-3" data-qa="truck-model">{{ truck.model }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Gross weight</span>
                      <span class="text-grey-darken-3" data-qa="truck-gross-weight">{{ truck.grossWeight }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">VIN code</span>
                      <span class="text-grey-darken-3" data-qa="truck-vin-code">{{ truck.vin }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Year</span>
                      <span class="text-grey-darken-3" data-qa="truck-year">{{ truck.year }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Licence plate</span>
                      <span class="text-grey-darken-3" data-qa="truck-licence-plate">{{ truck.licencePlate }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Licence state</span>
                      <span class="text-grey-darken-3" data-qa="truck-licence-state">{{ truck.licenceState }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Licence expire</span>
                      <span class="text-grey-darken-3" data-qa="truck-licence-expire">{{
                        $filters.date(truck.licenceExpire)
                      }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Color</span>
                      <span class="text-grey-darken-3" data-qa="truck-color">{{ truck.color }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Signs</span>
                      <span class="text-grey-darken-3" data-qa="truck-signs">
                        {{ $filters.booleanValue(truck.signs, "Yes", "No") || "-" }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="box-shadow pa-6 bg-white rounded mb-6">
                  <div class="text-grey-darken-3 mb-6 font-weight-medium text-subtitle-1">Trailer</div>
                  <div class="characteristics-items">
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Length</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-length">
                        {{ truck.trailer?.length ?? "-" }}
                      </span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Width</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-width">
                        {{ truck.trailer?.width ?? "-" }}
                      </span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Door width</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-door-width">
                        {{ truck.trailer?.doorWidth ?? "-" }}
                      </span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Min width</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-min-width">
                        {{ truck.trailer?.minWidth ?? "-" }}
                      </span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Height</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-height">
                        {{ truck.trailer?.height ?? "-" }}
                      </span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Door height</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-door-height">{{
                        truck.trailer?.doorHeight ?? "-"
                      }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Min height</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-min-height">
                        {{ truck.trailer?.minHeight ?? "-" }}
                      </span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Year</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-year">
                        {{ truck.trailer?.year ?? "-" }}
                      </span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Payload</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-payload">{{
                        truck.trailer?.payload
                      }}</span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Door type</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-door-type">
                        {{ TruckHelper.doorTypeToString(truck) }}
                      </span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Equipment</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-equipment">
                        {{ TruckHelper.equipmentToString(truck) }}
                      </span>
                    </div>
                    <div class="d-flex characteristics-item">
                      <span class="text-grey-darken-1 characteristics-label">Additional equipment</span>
                      <span class="text-grey-darken-3" data-qa="truck-trailer-additional-equipment">
                        {{ TruckHelper.additionalEquipmentToString(truck) }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="box-shadow pa-6 bg-white rounded mb-2">
                  <div class="text-grey-darken-3 mb-6 font-weight-medium text-subtitle-1">Truck notes</div>
                  <div class="driver-note__text bg-grey-lighten-4 pt-4 pb-4 pr-3 pl-3" style="min-height: 110px">
                    {{ $filters.mdash(truck.note) }}
                  </div>
                </div>
              </div>
            </v-window-item>
            <v-window-item value="plannedRoutes">
              <div class="h-100">
                <div class="box-shadow pa-6 bg-white rounded mb-6">123</div>
              </div>
            </v-window-item>
          </v-window>
        </v-card>
      </div>
      <template #sidebar>
        <truck-view-sidebar :truck="truck" @update:truck="loadTruck"></truck-view-sidebar>
      </template>
    </sidebar-layout>
  </v-container>
  <not-found v-else icon="mdi-truck-remove-outline" text="Truck not found"></not-found>
</template>

<style lang="scss" scoped>
.box-shadow {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.16);
}

.characteristics-item {
  align-items: center;
  padding: 10px 0;
}

.characteristics-label {
  width: 110px;
  margin-right: 30px;
}

.characteristics-items {
  columns: 2;
  margin: -10px 0;
}
</style>
