import { defineStore } from "pinia";

interface SnackbarStore {
  snackbar: Snackbar;
}

interface Snackbar {
  color: string;
  text: string;
  timeout: number;
  show?: boolean;
}

export const useSnackbarStore = defineStore("snackbarStore", {
  state: (): SnackbarStore => ({
    snackbar: {
      color: "",
      text: "",
      timeout: 0,
    },
  }),
  actions: {
    showSnackbar(snackbar: Snackbar) {
      snackbar.show = true;
      this.snackbar = snackbar;
    },
    closeSnackBar() {
      this.snackbar = { ...this.snackbar, show: false };
    },
    showSnackbarError(message: string) {
      const snackbar = {
        color: "#FF5252",
        text: message,
        timeout: -1,
      };
      this.showSnackbar(snackbar);
    },
    showSnackbarSuccess(message: string) {
      const snackbar = {
        color: "success",
        text: message,
        timeout: 4000,
      };
      this.showSnackbar(snackbar);
    },
  },
});
