import { BaseApi } from "@/api/base";
import type { UpdateThreadRequest } from "@/api/chat/dto/request/update-thread.request";
import type { ThreadDto } from "@/api/chat/dto/thread.dto";
import type { Response } from "@/api/client";
import type { ThreadsListResponse } from "@/api/chat/dto/response/threads-list.response";
import type { MessageDto } from "@/api/chat/dto/message.dto";

export class ChatApi extends BaseApi {
  updateThread(threadId: number, data: UpdateThreadRequest): Promise<Response<ThreadDto>> {
    return this.client.patch<ThreadDto>("chat/threads/" + threadId, data);
  }

  noResponseThread(threadId: number): Promise<Response<ThreadDto>> {
    return this.client.post<ThreadDto>("chat/threads/" + threadId + "/no-response", {});
  }

  getThreads(
    offset: number,
    limit: number = 100,
    timeFrom: string | null = null,
  ): Promise<Response<ThreadsListResponse>> {
    return this.client.get<ThreadsListResponse>("chat/threads", {
      offset: offset.toString(),
      limit: limit.toString(),
      timeFrom: timeFrom,
    });
  }

  findThreadByUserId(userId: number): Promise<Response<ThreadDto>> {
    return this.client.get<ThreadDto>("chat/threads/user/" + userId);
  }

  getThreadMessages(threadId: number, limit: number = 100, offset: number = 0): Promise<Response<MessageDto[]>> {
    return this.client.get<MessageDto[]>("chat/threads/" + threadId + "/messages", {
      limit: limit.toString(),
      offset: offset.toString(),
    });
  }

  sendMessage(threadId: number, text: string): Promise<Response<string>> {
    return this.client.post<string>("chat/threads/" + threadId + "/messages", { text });
  }

  sendFile(threadId: number, data: FormData): Promise<Response<string>> {
    return this.client.post<string>("chat/threads/" + threadId + "/file", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
