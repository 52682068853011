<script>
export default {
  name: "AddOrderDialog",
  emits: ["attach", "close"],
  data() {
    return {
      order: null,
      orders: [],
      search: "",
      isLoading: false,
    };
  },
  watch: {
    search(value) {
      // if (this.isLoading) return;
      this.fetchOrders(value);
    },
  },
  methods: {
    async attach() {
      // load full order info with freights
      try {
        const resp = await this.$api.orders.findOrderById(this.order.id);
        this.order = resp.data;
      } catch (err) {
        console.log("Error loading order info", err);
      }

      this.$emit("attach", this.order);
      this.$emit("close");
    },
    fetchOrders(term) {
      const params = { number: term, pageSize: 10 };

      this.isLoading = true;
      this.$api.orders
        .findOrders(params)
        .then((resp) => {
          this.orders = resp.data.items;
        })
        .catch((err) => {
          console.log("Error loading orders", err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<template>
  <div class="py-4">
    <v-autocomplete
      v-model="order"
      v-model:search="search"
      :items="orders"
      :loading="isLoading"
      density="compact"
      hide-details
      item-title="number"
      item-value="id"
      label="Order number"
      return-object
      variant="outlined"
    />
  </div>
</template>
