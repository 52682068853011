<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import DatePicker from "@/components/pickers/DatePicker.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import ValidationMixin from "@/mixins/validation.mixin";

export default {
  name: "EditStopBlock",
  components: { TimePicker, DatePicker },
  mixins: [ValidationMixin, DateTimeMixin, OrdersMixin],
  props: {
    modelValue: { type: Object, required: true },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    timezone: {
      get() {
        return this.modelValue.timezone;
      },
      set(value) {
        this.update("timezone", value);
      },
    },
    timeType: {
      get() {
        return this.modelValue.timeType;
      },
      set(value) {
        this.update("timeType", value);
      },
    },
    dateFrom: {
      get() {
        return this.modelValue.timeFrom ? this.$dayjs(this.modelValue.timeFrom).format("YYYY-MM-DD") : "";
      },
      set(value) {
        this.update("timeFrom", value + " " + this.timeFrom);
      },
    },
    timeFrom: {
      get() {
        return this.modelValue.timeFrom ? this.$dayjs(this.modelValue.timeFrom).format("HH:MM") : "";
      },
      set(value) {
        this.update("timeFrom", this.dateFrom + " " + value);
      },
    },
    dateTo: {
      get() {
        return this.modelValue.timeTo ? this.$dayjs(this.modelValue.timeTo).format("YYYY-MM-DD") : "";
      },
      set(value) {
        this.update("timeTo", value + " " + this.timeTo);
      },
    },
    timeTo: {
      get() {
        return this.modelValue.timeTo ? this.$dayjs(this.modelValue.timeTo).format("HH:MM") : "";
      },
      set(value) {
        this.update("timeTo", this.dateTo + " " + value);
      },
    },
  },
  methods: {
    update(key, value) {
      const stop = { ...this.modelValue, [key]: value };
      this.$emit("update:modelValue", stop);
      this.$emit("change", stop);
    },
  },
};
</script>

<template>
  <div class="py-4">
    <div class="pb-2">
      <span>Facility: </span>
      <span class="font-weight-500">{{ modelValue.facility.name }}</span>
    </div>

    <div class="mb-6 mt-4">
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="timezone"
            :items="timezonesShortList"
            :rules="[requiredValidator]"
            class="required"
            density="compact"
            hide-details="auto"
            item-title="name"
            item-value="id"
            label="Time zone"
            required
            variant="outlined"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="timeType"
            :items="getTimeTypes(modelValue.type)"
            :rules="[requiredValidator]"
            class="required"
            density="compact"
            hide-details="auto"
            item-title="name"
            item-value="id"
            label="Time type"
            required
            variant="outlined"
          />
        </v-col>
        <v-col v-if="timeType === 'f'" cols="3">
          <date-picker v-model="dateFrom" label="Date from" />
        </v-col>
        <v-col v-if="timeType === 'f'" cols="3">
          <time-picker v-model="timeFrom" placeholder="Time from" />
        </v-col>
        <v-col v-if="timeType === 'f'" cols="6" />
        <v-col cols="3">
          <date-picker v-model="dateTo" :rules="[requiredValidator]" label="Date to" required />
        </v-col>
        <v-col cols="3">
          <time-picker
            v-model="timeTo"
            :rules="[requiredValidator]"
            class="required"
            label="Time to"
            placeholder="Time to"
            required
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
