type PopupOptions = {
  url: string;
  title: string;
  x: number;
  y: number;
  w: number;
  h: number;
};

const PopupMixin = {
  methods: {
    openPopup({ url, title, x, y, w, h }: PopupOptions): void {
      const newWindow = window.open(
        url,
        title,
        `
      scrollbars=yes,
      width=${w},
      height=${h},
      top=${y},
      left=${x}
      `,
      );

      if (newWindow?.focus) newWindow?.focus();
    },
    popupHeight(): number {
      return Number(localStorage.getItem(PopupDimensionsKey.height)) || 400;
    },
    popupWidth(): number {
      return Number(localStorage.getItem(PopupDimensionsKey.width)) || 600;
    },
    popupX(): number {
      return Number(localStorage.getItem(PopupDimensionsKey.x)) || 100;
    },
    popupY(): number {
      return Number(localStorage.getItem(PopupDimensionsKey.y)) || 100;
    },
  },
};

export const PopupDimensionsKey: Record<string, string> = {
  x: "popup-position-x",
  y: "popup-position-y",
  height: "popup-height",
  width: "popup-width",
};

export default PopupMixin;
