<script>
import BaseView from "@/views/BaseView.vue";
import { MasksMixin } from "@/mixins/masks.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import countries from "@/data/countries";
import FmcsaNumberInput from "@/views/companies/components/FmcsaNumberInput.vue";
import AddressField from "@/components/AddressField.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "CarrierEditForm",
  components: { AddressField, FmcsaNumberInput },
  mixins: [BaseView, MasksMixin, ValidationMixin],
  props: {
    carrier: Object,
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      item: {
        status: "a",
        addressCity: "",
        addressState: "",
        addressZipCode: "",
        addressCountry: "US",
        billingAddressCity: "",
        billingAddressState: "",
        billingAddressZipCode: "",
        billingAddressCountry: "US",
      },
      sameAddress: false,
      fmcsaTypes: ["MC", "FF", "MX"],
      statuses: [
        { id: "a", name: this.$t("company.status-active") },
        { id: "h", name: this.$t("company.status-hold") },
        { id: "n", name: this.$t("company.status-hidden") },
      ],
    };
  },
  watch: {
    carrier() {
      this.item = { ...this.carrier };
      if (
        this.item.billingAddressLine === this.item.addressLine &&
        this.item.billingAddressCity === this.item.addressCity &&
        this.item.billingAddressState === this.item.addressState &&
        this.item.billingAddressZipCode === this.item.addressZipCode &&
        this.item.billingAddressCountry === this.item.addressCountry
      ) {
        this.sameAddress = true;
      }
    },
    item: {
      deep: true,
      handler() {
        if (this.sameAddress) {
          this.syncAddress();
        }
      },
    },
    sameAddress() {
      if (this.sameAddress) {
        this.syncAddress();
      }
    },
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    countries() {
      return countries;
    },
    async save() {
      const result = await this.$refs.form.validate();
      if (!result.valid) {
        this.scrollToError();
        return;
      }

      const resp = await this.$api.company.upsertCarrier(this.item);
      if (resp.success) {
        this.$emit("close");
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
    syncAddress() {
      this.item.billingAddressLine = this.item.addressLine;
      this.item.billingAddressCity = this.item.addressCity;
      this.item.billingAddressState = this.item.addressState;
      this.item.billingAddressZipCode = this.item.addressZipCode;
      this.item.billingAddressCountry = this.item.addressCountry;
    },
  },
};
</script>

<template>
  <v-form id="edit-carrier" ref="form" lazy-validation>
    <div class="about-user">
      <div class="mb-6">
        <div class="user-header d-flex align-center justify-space-between mb-3 flex-wrap">
          <div class="user-header__left d-flex align-center">
            <div class="subheading font-weight-medium">Carrier information</div>
          </div>
        </div>
        <div class="pa-5 mb-6 bg-white rounded personal-block">
          <div class="caption text-grey-darken-1 mb-3">Company information</div>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="item.name"
                :label="$t('general.name')"
                :rules="[requiredValidator]"
                autocomplete="off1"
                class="required"
                color="primary"
                density="compact"
                hide-details="auto"
                name="name"
                variant="outlined"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="item.branchName"
                :label="$t('company.branch_name')"
                autocomplete="off1"
                color="primary"
                density="compact"
                hide-details="auto"
                name="branchName"
                variant="outlined"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="item.fmcsaType"
                :items="fmcsaTypes"
                :label="$t('company.fmcsa_type')"
                :rules="[requiredValidator]"
                autocomplete="off1"
                class="required"
                color="primary"
                density="compact"
                hide-details="auto"
                name="fmcsaType"
                variant="outlined"
              />
            </v-col>
            <v-col cols="2">
              <fmcsa-number-input
                v-model="item.fmcsaNumber"
                :rules="[requiredValidator]"
                class="required"
                name="fmcsaNumber"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="item.status"
                :items="statuses"
                :label="$t('general.status')"
                :rules="[requiredValidator]"
                autocomplete="off1"
                color="primary"
                density="compact"
                hide-details="auto"
                item-title="name"
                item-value="id"
                name="status"
                variant="outlined"
              />
            </v-col>
          </v-row>
        </div>
        <div class="pa-5 mb-6 bg-white rounded personal-block">
          <address-field v-model="item" hide-location label="Physical Address"></address-field>
        </div>

        <div class="pa-5 mb-6 bg-white rounded personal-block">
          <address-field
            v-model="item"
            :disabled="sameAddress"
            hide-location
            label="Billing Address"
            model-prefix="billing"
          >
            <template #label:suffix>
              <v-checkbox
                v-model="sameAddress"
                class="text-grey-darken-2 mt-0 pt-0 billing-checkbox"
                color="primary"
                hide-details=""
                label="Billing address same as physical"
              />
            </template>
          </address-field>
        </div>
      </div>
    </div>
    <div class="d-flex justify-end pa-5 bg-white user-info__buttons">
      <v-btn
        id="cancel-btn"
        :to="{ name: 'carriers' }"
        class="mr-3 text-primary text-uppercase font-weight-600"
        height="36"
        variant="flat"
      >
        {{ $t("general.cancel") }}
      </v-btn>
      <v-btn
        id="save-btn"
        class="pr-6 pl-6 font-weight-600 text-uppercase"
        color="primary"
        height="36"
        variant="flat"
        @click="save"
      >
        {{ $t("general.save") }}
      </v-btn>
    </div>
  </v-form>
</template>
