import type { CoordinatesDto } from "@/api/dto/coordinates.dto";

const GoogleMapsMixin = {
  methods: {
    getPlaceUrl(location: CoordinatesDto, zoom: number = -1): string {
      let url = `https://www.google.com/maps/place/${location.lat},${location.lon}`;
      if (zoom > 0) {
        url += `/@${location.lat},${location.lon},${zoom}z`;
      }
      return url;
    },
    getRouteUrl(location1: CoordinatesDto, location2: CoordinatesDto): string {
      if (!location1 || !location2) return "#";

      const baseUrl = "https://www.google.com/maps/dir/?api=1&travelmode=driving";
      return `${baseUrl}&origin=${location1.lat},${location1.lon}&destination=${location2.lat},${location2.lon}`;
    },
    getPlaceUrlByAddress(address: string): string {
      return encodeURI(`https://www.google.com/maps/search/?api=1&query=${address}`);
    },
  },
};

export default GoogleMapsMixin;
