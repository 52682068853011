<script>
import { mapActions, mapState } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "OmniSnackbar",
  computed: {
    ...mapState(useSnackbarStore, ["snackbar"]),
  },
  methods: {
    ...mapActions(useSnackbarStore, ["closeSnackBar"]),
  },
};
</script>

<template>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    location="bottom right"
    multi-line
    variant="flat"
  >
    {{ snackbar.text }}
    <template #actions>
      <v-icon class="cursor-pointer" @click="closeSnackBar()">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>

<style lang="scss" scoped>
:deep(.v-snackbar__actions) {
  align-self: start;
  margin-top: 8px;
}
</style>
