<script>
import DriversMixin from "@/views/drivers/DriversMixin.vue";

export default {
  name: "DriverCertificates",
  mixins: [DriversMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isExpired(document) {
      if (!document.date) return false;

      const now = this.$dayjs(),
        expDate = this.$dayjs(document.date);
      return now.isAfter(expDate);
    },
    getName(document) {
      let name = document?.name;

      if (this.isExpired(document)) {
        const expDate = this.$dayjs(document.date);
        name += " " + this.$t("drivers.certificate-expired") + " " + expDate.format("MM/DD/YYYY");
      }

      return name;
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column flex-wrap certificates-holder">
    <div v-for="document in getUserDocuments(user)" :key="document.code">
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            :class="{ 'text-red': isExpired(document), 'text-grey': !isExpired(document) }"
            :color="isExpired(document) ? 'bg-red-lighten-4' : 'bg-grey-lighten-2'"
            class="caption pr-1 pl-1 city-tooltip text-uppercase text-grey-darken-1 mr-1"
            height="16"
            size="x-small"
            v-bind="props"
            variant="tonal"
          >
            {{ document.code }}
          </v-btn>
        </template>
        <span class="text-white">{{ getName(document) }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.certificates-holder {
  max-height: 42px;

  .city-tooltip {
    padding: 2px 4px !important;
    min-width: auto !important;
  }
}
</style>
