import { BaseApi } from "@/api/base";
import type { QueryParams } from "@/api/types";
import type { Response } from "@/api/client";
import type { LogRecordDto } from "@/api/service/dto/log-record.dto";

export class ServiceApi extends BaseApi {
  fetchLogs(options: QueryParams = {}): Promise<Response<LogRecordDto[]>> {
    return this.client.get("logs", options);
  }
}
