import { BaseApi } from "@/api/base";
import type { QueryParams } from "@/api/types";
import type { Response } from "@/api/client";
import type { AddressDto } from "@/api/geo/dto/address.dto";

export class GeoApi extends BaseApi {
  async searchGeneral(query = "", limit = 5): Promise<Response<AddressDto[]>> {
    const url: string = "/geo/coder/search_general";
    return await this._search(url, { q: query, limit: limit });
  }

  async searchPostalCode(code = "", limit = 15): Promise<Response<AddressDto[]>> {
    const url: string = "/geo/coder/search_general";
    return await this._search(url, { q: code, limit: limit });
  }

  async _search(url: string, params: QueryParams = {}): Promise<Response<AddressDto[]>> {
    params.addressdetails = 1;
    return this.client.get<AddressDto[]>(url, params);
  }

  async reverse(lat: number, lon: number, limit: number = 5): Promise<Response<AddressDto>> {
    return this.client.get<AddressDto>("/geo/coder/reverse", {
      lat: lat,
      lon: lon,
      limit: limit,
    });
  }
}
