<script setup lang="ts">
import { defineProps, withDefaults, onMounted, inject } from "vue";

const props = withDefaults(
  defineProps<{
    title: string;
  }>(),
  {}
);

const formSymbol = inject("formSymbol");

onMounted(() => {
  if (!formSymbol) {
    throw new Error("FormHeader must be a child of OmniForm");
  }
});
</script>

<template>
  <div class="d-flex align-center justify-space-between mb-3 flex-wrap">
    <div class="d-flex align-center">
      <div class="subheading font-weight-medium">{{ props.title }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
