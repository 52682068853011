import { BaseApi } from "@/api/base";
import type { ListResponseDto } from "@/api/dto/list-response.dto";
import type { DispatcherDto } from "@/api/dispatchers/dto/dispatcher.dto";
import type { Response } from "@/api/client";
import type { DispatcherProfileDto } from "@/api/dispatchers/dto/dispatcher-profile.dto";

export class DispatchersApi extends BaseApi {
  findDispatchers(options = {}): Promise<Response<ListResponseDto<DispatcherDto>>> {
    return this.client.get<ListResponseDto<DispatcherDto>>("dispatchers", options);
  }

  upsertDispatcher(dispatcher: any): Promise<Response<DispatcherDto>> {
    return this.client.save<DispatcherDto>("dispatchers", dispatcher);
  }

  removeDispatcher(dispatcherId: number): Promise<Response<void>> {
    return this.client.delete("dispatchers/" + dispatcherId);
  }

  getCurrentDispatcher(): Promise<Response<DispatcherProfileDto>> {
    return this.client.get<DispatcherProfileDto>("dispatchers/me");
  }

  archiveDispatcher(dispatcherId: number): Promise<Response<void>> {
    return this.client.post("/dispatchers/" + dispatcherId + "/archive", {});
  }

  unarchiveDispatcher(dispatcherId: number): Promise<Response<void>> {
    return this.client.post("/dispatchers/" + dispatcherId + "/unarchive", {});
  }
}
