import { BaseApi } from "@/api/base";
import type { Response } from "@/api/client";
import type { FileDto } from "@/api/files/dto/file.dto";

export class FilesApi extends BaseApi {
  uploadFile(folder: string, file: File, tags: string[] = []): Promise<Response<FileDto>> {
    const data: FormData = new FormData();
    data.append("file", file);
    data.append("folder", folder);
    for (const tag of tags) {
      data.append("tags", tag);
    }

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.client.post("files", data, options);
  }

  deleteFile(fileId: string): Promise<Response<void>> {
    return this.client.delete("files/" + fileId);
  }
}
