<script>
export default {
  name: "DriverNotes",
  props: {
    user: Object,
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<template>
  <v-menu
    v-if="user.note || user.driverNote"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    class="quote-menu"
    offset="10"
  >
    <template #activator="{ props }">
      <v-btn class="pa-0 quote-btn" size="small" v-bind="props" variant="flat">
        <v-icon class="text-primary" size="small">mdi-format-quote-close</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list class="pt-5 pb-5 pr-6 pl-6">
        <v-list-item v-if="user.note" class="pa-0">
          <v-list-item-title class="pa-0 text-grey-darken-3 text-body-2 mb-2">Dispatcher note</v-list-item-title>
          <v-list-item-subtitle class="text-grey-darken-2 font-size-12">
            {{ user.note }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item v-if="user.driverNote" class="pa-0">
          <v-list-item-title class="pa-0 text-grey-darken-3 text-body-2 mb-2">Driver note</v-list-item-title>
          <v-list-item-subtitle class="text-grey-darken-2 font-size-12">
            {{ user.driverNote }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: 0 8px 20px -7px rgba(0, 0, 0, 0.1) !important;

  .v-list-item + .v-list-item {
    margin-top: 20px;
  }
}

.quote-btn {
  position: absolute;
  top: 2px;
  right: 4px;
  min-width: auto !important;
  height: auto !important;
  background-color: transparent !important;
}
</style>
