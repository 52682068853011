<script lang="ts">
import { useI18n } from "vue-i18n";
import { useChatStore } from "@/store/chat.store";

export default {
  name: "App",
  setup() {
    const { t } = useI18n(); // use as global scope
    return { t };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "cabinet") + "-layout";
    },
  },
  created() {
    this.$api.setRouter(this.$router);
  },
  beforeCreate() {
    const chatStore = useChatStore();
    chatStore.initChat();
  },
};
</script>

<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
