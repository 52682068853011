<script>
import BaseView from "@/views/BaseView.vue";
import ChatMixin from "@/views/chats/ChatMixin.vue";
import MessageDraftMixin from "@/mixins/message-draft.mixin";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "ThreadRow",
  mixins: [BaseView, ChatMixin, MessageDraftMixin],
  props: {
    highlightBorder: Boolean,
    thread: Object,
  },
  emits: ["click"],
  computed: {
    ...mapState(useAppStore, ["mobile"]),
    listItemClass() {
      // mins < 5 - green-border,
      // 15 > mins > 5 - yellow-border,
      // mins > 15 - red-border.
      // no need to highlight border
      if (!this.highlightBorder) return "";

      // no new messages from driver
      if (this.thread.newMessagesCount === 0 || !this.thread.updatedDt) return "";

      const md = this.$dayjs.utc(this.thread.updatedDt);
      const mins = this.$dayjs.duration(this.$dayjs().diff(md)).asMinutes();
      if (mins > 15) return "red-border";
      if (mins > 5 && mins <= 15) return "yellow-border";
      return "green-border";
    },
    time() {
      if (!this.thread.lastMessageDt) return "";
      return this.showMessageTime(this.thread.lastMessageDt);
    },
    truckNumber() {
      return this.thread.user.driverTruck?.number;
    },
  },
  methods: {
    clearTabs(string) {
      return (string || "").replaceAll("\n", " ");
    },
  },
};
</script>
<template>
  <v-list-item :class="listItemClass" class="px-1 pt-2 pb-1" @click="$emit('click')">
    <v-row no-gutters>
      <v-col class="flex-grow-0 pr-1">
        <v-avatar :color="avatarColor(thread)" class="mr-3" size="44">
          <span class="text-white">{{ $filters.initials(thread.name) }}</span>
        </v-avatar>
      </v-col>
      <v-col class="pa-0" style="min-width: 0">
        <v-row class="flex-nowrap" no-gutters>
          <v-col class="">
            <v-row class="flex-nowrap justify-space-between" no-gutters>
              <v-col class="ellipsis flex-shrink-1">
                <span class="text-no-wrap text-black font-weight-450">{{ thread.name }}</span>
              </v-col>
              <v-col class="justify-end flex-grow-0 ml-4">
                <span class="text-no-wrap font-size-10 text-grey-darken-1">{{ time }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="flex-nowrap" no-gutters>
          <v-col>
            <v-row class="flex-nowrap justify-space-between mt-2" no-gutters>
              <v-col class="ellipsis flex-shrink-1 pr-4">
                <template v-if="draft(thread).message || draft(thread).file">
                  <span class="text-red">Draft: </span>
                  <span v-if="!draft(thread).file && draft(thread).message">{{ clearTabs(draft(thread).message) }}</span>
                  <span v-if="draft(thread).file"> 📎 {{ draft(thread).file.name }} </span>
                </template>
                <span v-else class="text-no-wrap ellipsis">{{ clearTabs(thread.lastMessage) }}</span>
              </v-col>
              <v-col class="justify-end flex-grow-0">
                <div
                  v-if="thread.newMessagesCount > 0 && !thread.resolved"
                  class="counter-messages font-size-12 rounded-lg d-inline-block bg-grey text-white"
                >
                  {{ thread.newMessagesCount }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="flex-nowrap mt-2" no-gutters>
      <v-col>
        <v-row class="flex-nowrap justify-space-between" no-gutters>
          <v-col class="ellipsis flex-shrink-1">
            <span v-if="!thread.resolved && !thread.dispatcher" class="caption text-grey-darken-1">
              {{ $t("chats.not-resolved") }}
            </span>
            <div v-if="!thread.resolved && thread.dispatcher" class="claimed-block d-flex align-center">
              <span class="text-grey-darken-1 caption mr-2">
                {{ $t("chats.claimed") }}
              </span>
              <span class="rounded claim-user bg-indigo-lighten-5 text-indigo-lighten-1 caption">
                {{ thread.dispatcher.name }}
              </span>
            </div>
            <div v-if="thread.resolved && thread.dispatcher" class="claimed-block d-flex align-center">
              <span class="text-grey-darken-1 caption mr-2">
                {{ $t("chats.resolved") }}
              </span>
              <span class="rounded claim-user bg-indigo-lighten-5 text-indigo-lighten-1 caption">
                {{ thread.dispatcher.name }}
              </span>
            </div>
          </v-col>
          <v-col v-if="truckNumber" class="text-no-wrap justify-end flex-grow-0">
            <v-icon class="mr-1 text-grey-darken-3" size="16">mdi-truck-outline</v-icon>
            <span class="text-grey-darken-3 font-weight-600 text-uppercase font-size-14">
              {{ truckNumber }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style lang="scss" scoped>
.v-virtual-scroll__item:first-child {
  .v-list-item {
    border-top: 1px solid #e0e0e0;
  }
}

.v-list-item {
  margin: 0 !important;
  border-radius: 0;
  border-bottom: 1px solid #e0e0e0;

  &:before,
  &:after {
    display: none;
  }

  &__title {
    font-size: 16px !important;
    line-height: 1.3rem !important;
  }

  /*&__action {
    align-self: center !important;
  }*/
  &--active {
    background: #fff;
  }

  .counter-messages {
    line-height: 16px;
    padding: 0 6px;
    border-radius: 10px;
    letter-spacing: 0.4px;
  }

  &.green-border {
    border-left: 4px solid #4caf50;
  }

  &.yellow-border {
    border-left: 4px solid #fb8c00;
  }

  &.red-border {
    border-left: 4px solid #ff5252;
  }

  .claim-user {
    padding: 3px 5px;
  }
}

.text-no-wrap {
  white-space: nowrap;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
