<script>
import BaseView from "@/views/BaseView.vue";
import { MasksMixin } from "@/mixins/masks.mixin";
import countries from "@/data/countries";
import FmcsaNumberInput from "@/views/companies/components/FmcsaNumberInput.vue";
import AddressField from "@/components/AddressField.vue";

export default {
  name: "CompanyProfileForm",
  components: { AddressField, FmcsaNumberInput },
  mixins: [BaseView, MasksMixin],
  data() {
    return {
      item: {},
      fmcsaTypes: ["MC", "FF", "MX"],
      statuses: [
        { id: "a", name: this.$t("company.status-active") },
        { id: "h", name: this.$t("company.status-hold") },
        { id: "n", name: this.$t("company.status-hidden") },
      ],
    };
  },
  async created() {
    const resp = await this.$api.company.getProfile();
    this.item = resp.data;
  },
  methods: {
    countries() {
      return countries;
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-8 pb-10 pl-6 pr-6">
    <v-form id="company-profile" ref="form">
      <div class="about-user">
        <div class="mb-6">
          <div class="user-header d-flex align-center justify-space-between mb-3 flex-wrap">
            <div class="user-header__left d-flex align-center">
              <div class="subheading font-weight-medium">Company information</div>
            </div>
          </div>
          <div class="pa-5 mb-6 bg-white rounded personal-block">
            <div class="caption text-grey-darken-1 mb-3">Company information</div>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="item.name"
                  :label="$t('general.name')"
                  :readonly="true"
                  autocomplete="off1"
                  color="primary"
                  density="compact"
                  hide-details="auto"
                  name="name"
                  variant="outlined"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="item.branchName"
                  :label="$t('company.branch_name')"
                  :readonly="true"
                  autocomplete="off1"
                  color="primary"
                  density="compact"
                  hide-details="auto"
                  name="branchName"
                  variant="outlined"
                />
              </v-col>
              <v-col cols="2">
                <v-select
                  v-model="item.fmcsaType"
                  :items="fmcsaTypes"
                  :label="$t('company.fmcsa_type')"
                  :readonly="true"
                  autocomplete="off1"
                  color="primary"
                  density="compact"
                  hide-details="auto"
                  name="fmcsaType"
                  variant="outlined"
                />
              </v-col>
              <v-col cols="2">
                <fmcsa-number-input v-model="item.fmcsaNumber" :readonly="true"></fmcsa-number-input>
              </v-col>
              <v-col cols="2">
                <v-select
                  v-model="item.status"
                  :items="statuses"
                  :label="$t('general.status')"
                  :readonly="true"
                  autocomplete="off1"
                  color="primary"
                  density="compact"
                  hide-details="auto"
                  item-title="name"
                  item-value="id"
                  name="status"
                  variant="outlined"
                />
              </v-col>
            </v-row>
          </div>
          <div class="pa-5 mb-6 bg-white rounded personal-block">
            <address-field v-model="item" hide-location label="Physical Address"></address-field>
          </div>

          <div class="pa-5 mb-6 bg-white rounded personal-block">
            <address-field v-model="item" hide-location label="Billing Address" model-prefix="billing_">
              <template #label:suffix>
                <v-checkbox
                  class="text-grey-darken-2 mt-0 pt-0 billing-checkbox"
                  color="primary"
                  hide-details=""
                  label="Billing address same as physical"
                />
              </template>
            </address-field>
          </div>
        </div>
      </div>
    </v-form>
  </v-container>
</template>
