import Axios, { AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from "axios";
import { TokenStorage } from "@/services/tokenStorage.js";
import type { Router } from "vue-router";
import type { BodyParams, QueryParams } from "@/api/types";
import { transformKeysToCamel, transformKeysToSnake } from "@/api/utils";

const BASE_URL: string = location.protocol + "//" + location.host + "/api/v1";

export class Response<T> {
  data: T | undefined;
  status: number | null = null;
  error: AxiosError | null = null;
  success: boolean = false;

  constructor(axiosResponse: AxiosResponse | null = null, error: AxiosError | null = null, success: boolean = false) {
    this.data = axiosResponse?.data;
    this.status = axiosResponse?.status || null;
    this.error = error;
    this.success = success;
  }

  static unknownError() {
    return new Response();
  }
}

export class ApiClient {
  private api: AxiosInstance;

  constructor() {
    this.api = Axios.create({
      baseURL: BASE_URL,
      timeout: 30000,
      headers: { "Content-Type": "application/json" },
    });

    this.api.interceptors.request.use((request) => {
      if (request.url) {
        request.url = this.transformQueryParams(request.url);
      }
      if (request.data) {
        this.removeVirtualProps(request.data);
        request.data = transformKeysToSnake(request.data);
      }
      return request;
    });

    this.api.interceptors.response.use((response) => {
      if (response.data) {
        response.data = transformKeysToCamel(response.data);
      }
      return response;
    });

    // load saved token
    const token = this.getToken();
    if (token) {
      this.setToken(token);
    }
  }

  buildParams(params: QueryParams): string {
    const searchParams: URLSearchParams = new URLSearchParams();

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (value === undefined || value === null) return;

        if (Array.isArray(value)) {
          value.forEach((value: string | number) => searchParams.append(key, value.toString()));
        } else {
          searchParams.append(key, value.toString());
        }
      });
    }

    return searchParams ? "?" + searchParams.toString() : "";
  }

  async delete(url: string): Promise<Response<void>> {
    try {
      const response = await this.api.delete(url);
      return new Response(response, null, true);
    } catch (error) {
      return this.handleRequestError(error, "DELETE");
    }
  }

  async get<T>(
    url: string,
    params: QueryParams = {},
    config: AxiosRequestConfig | undefined = undefined,
  ): Promise<Response<T>> {
    try {
      const response = await this.api.get(url + this.buildParams(params), config);
      return new Response<T>(response, null, true);
    } catch (error) {
      return this.handleRequestError(error, "GET");
    }
  }

  async patch<T>(url: string, data: BodyParams): Promise<Response<T>> {
    try {
      const response = await this.api.patch(url, data);
      return new Response<T>(response, null, true);
    } catch (error) {
      return this.handleRequestError(error, "PATCH");
    }
  }

  async post<T>(
    url: string,
    data: BodyParams,
    config: AxiosRequestConfig | undefined = undefined,
  ): Promise<Response<T>> {
    try {
      const response = await this.api.post(url, data, config);
      return new Response<T>(response, null, true);
    } catch (error) {
      console.log(error);
      return this.handleRequestError(error, "POST");
    }
  }

  async put<T>(url: string, data: BodyParams): Promise<Response<T>> {
    try {
      const response = await this.api.put(url, data);
      return new Response<T>(response, null, true);
    } catch (error) {
      return this.handleRequestError(error, "PUT");
    }
  }

  async save<T>(url: string, data: BodyParams): Promise<Response<T>> {
    if (data.id) {
      return await this.put<T>(url + "/" + data.id, data);
    } else {
      return await this.post<T>(url, data);
    }
  }

  saveToken(token: string): void {
    if (token) {
      TokenStorage.saveToken(token);
    } else {
      TokenStorage.removeToken();
    }
    this.setToken(token);
  }

  setRouter(router: Router): void {
    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          return router.replace({ name: "login" });
        }
        return Promise.reject(error);
      },
    );
  }

  setToken(token: string): void {
    if (token) {
      this.api.defaults.headers.common["Authorization"] = token;
    } else {
      delete this.api.defaults.headers.common["Authorization"];
    }
  }

  getToken(): string | null {
    return TokenStorage.getToken();
  }

  getWSChatUrl(): string {
    return BASE_URL.replace("http", "ws") + "/chat/ws";
  }

  removeVirtualProps(obj: BodyParams): void {
    for (const k in obj) {
      if (typeof obj[k] == "object" && obj[k] !== null) {
        this.removeVirtualProps(obj[k]);
      } else {
        if (k[0] === "$") {
          delete obj[k];
        }
      }
    }
  }

  handleRequestError(error: any, requestType: string): Response<any> {
    if (error.request && error.response) {
      console.error(
        `Error processing ${requestType} ${error.request.responseURL} with status ${error.response.status}`,
      );
      return new Response(error.response, error, false);
    }
    return Response.unknownError();
  }

  private transformQueryParams(url: string): string {
    const splitUrl = url.split("?");
    url = splitUrl[0];
    const paramsString = splitUrl[1];
    if (paramsString) {
      const params = transformKeysToSnake(Object.fromEntries(new URLSearchParams(paramsString).entries()));
      url += "?" + new URLSearchParams(params).toString();
    }
    return url;
  }
}
