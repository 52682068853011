<script>
import BaseView from "@/views/BaseView.vue";
import { MasksMixin } from "@/mixins/masks.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import countries from "@/data/countries";
import FmcsaNumberInput from "@/views/companies/components/FmcsaNumberInput.vue";
import AddressField from "@/components/AddressField.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import OmniTextField from "@/components/controls/OmniTextField.vue";
import OmniForm from "@/components/form/OmniForm.vue";
import FormHeader from "@/components/form/FormHeader.vue";
import FormBlock from "@/components/form/FormBlock.vue";
import FormActions from "@/components/form/FormActions.vue";

export default {
  name: "CustomerEditForm",
  components: {
    FormActions,
    FormBlock,
    FormHeader,
    OmniForm,
    OmniTextField,
    OmniSelect,
    AddressField,
    FmcsaNumberInput,
  },
  mixins: [BaseView, MasksMixin, ValidationMixin],
  props: {
    customer: Object,
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      item: {
        status: "a",
        addressCity: "",
        addressState: "",
        addressZipCode: "",
        addressCountry: "US",
        billingAddressCity: "",
        billingAddressState: "",
        billingAddressZipCode: "",
        billingAddressCountry: "US",
      },
      sameAddress: false,
      fmcsaTypes: ["MC", "FF", "MX"],
      statuses: [
        { id: "a", name: this.$t("company.status-active") },
        { id: "h", name: this.$t("company.status-hold") },
        { id: "n", name: this.$t("company.status-hidden") },
      ],
    };
  },
  watch: {
    customer() {
      this.item = { ...this.customer };
      if (
        this.item.billingAddressLine === this.item.addressLine &&
        this.item.billingAddressCity === this.item.addressCity &&
        this.item.billingAddressState === this.item.addressState &&
        this.item.billingAddressZipCode === this.item.addressZipCode &&
        this.item.billingAddressCountry === this.item.addressCountry
      ) {
        this.sameAddress = true;
      }
    },
    item: {
      deep: true,
      handler() {
        if (this.sameAddress) {
          this.syncAddress();
        }
      },
    },
    sameAddress() {
      if (this.sameAddress) {
        this.syncAddress();
      }
    },
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    countries() {
      return countries;
    },
    async save() {
      const result = await this.$refs.form.validate();
      if (!result.valid) {
        this.scrollToError();
        return;
      }

      const resp = await this.$api.company.upsertCustomer(this.item);
      if (resp.success) {
        this.$emit("close");
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
    syncAddress() {
      this.item.billingAddressLine = this.item.addressLine;
      this.item.billingAddressCity = this.item.addressCity;
      this.item.billingAddressState = this.item.addressState;
      this.item.billingAddressZipCode = this.item.addressZipCode;
      this.item.billingAddressCountry = this.item.addressCountry;
    },
    onCancel() {
      this.$router.push({ name: "customers" });
    },
  },
};
</script>

<template>
  <omni-form id="edit-customer" ref="form" lazy-validation>
    <form-header title="Customer information"></form-header>
    <form-block title="Company information">
      <v-row>
        <v-col cols="3">
          <omni-text-field
            id="name"
            v-model="item.name"
            :label="$t('general.name')"
            :rules="[requiredValidator]"
            required
          />
        </v-col>
        <v-col>
          <omni-text-field id="branch_name" v-model="item.branchName" :label="$t('company.branch_name')" />
        </v-col>
        <v-col>
          <omni-select
            id="fmcsa_type"
            v-model="item.fmcsaType"
            :items="fmcsaTypes"
            :label="$t('company.fmcsa_type')"
            :rules="[requiredValidator]"
            required
          />
        </v-col>
        <v-col>
          <fmcsa-number-input
            id="fmcsa_number"
            v-model="item.fmcsaNumber"
            :rules="[requiredValidator]"
            class="required"
            name="fmcsaNumber"
          />
        </v-col>
        <v-col>
          <omni-select
            id="status"
            v-model="item.status"
            :items="statuses"
            :label="$t('general.status')"
            :rules="[requiredValidator]"
            required
          />
        </v-col>
        <v-col>
          <omni-text-field
            id="unpaid_loads_limit"
            v-model="item.unpaidLoadsLimit"
            :label="$t('company.unpaid_loads_limit')"
            :rules="[requiredValidator]"
            required
          />
        </v-col>
      </v-row>
    </form-block>
    <form-block>
      <address-field v-model="item" hide-location label="Physical Address"></address-field>
    </form-block>
    <form-block>
      <address-field
        v-model="item"
        :disabled="sameAddress"
        hide-location
        label="Billing Address"
        model-prefix="billing"
      >
        <template #label:suffix>
          <v-checkbox
            v-model="sameAddress"
            class="text-grey-darken-2 mt-0 pt-0 billing-checkbox"
            color="primary"
            hide-details=""
            label="Billing address same as physical"
          />
        </template>
      </address-field>
    </form-block>

    <form-actions @cancel="onCancel" @submit="save"></form-actions>
  </omni-form>
</template>
