<script lang="ts">
import { customAlphabet } from "nanoid";
import { defineComponent } from "vue";
import { NavigationItem, Permissions, Role, RoleAction } from "@/enums/navigation";
import { useAppStore } from "@/store/app.store";
import { useChatStore } from "@/store/chat.store";

export default defineComponent({
  data() {
    return {
      avatarColor(thread: any) {
        // in a chat modal window, while loading thread name is null.
        if (thread.resolved || !thread.name || thread.archived) {
          return "#BDBDBD";
        }

        const colors = ["#9575CD", "#EF5350", "#7986CB", "#4FC3F7", "#81C784", "#FFD54F", "#FF8A65"];
        return colors[
          thread.name
            .split("")
            .reduce((previousValue: number, currentLetter: string) => previousValue + currentLetter.charCodeAt(0), 0) %
            7
        ];
      },
      roles: [
        { id: "ad", name: this.$t("dispatchers.role-admin") },
        { id: "tl", name: this.$t("dispatchers.role-team-lead") },
        { id: "bk", name: this.$t("dispatchers.role-booking") },
        { id: "hr", name: this.$t("dispatchers.role-hr") },
        { id: "op", name: this.$t("dispatchers.role-operation") },
        { id: "ac", name: this.$t("dispatchers.role-accounting") },
      ],
      params: { page: 1, pageSize: 10 },
      pagination: { itemsPerPage: [10, 25, 50, 100] },
    };
  },
  methods: {
    getPreviewPath(file: any) {
      return "/api/v1/files/" + file.previewPath;
    },
    generateId() {
      const nanoid = customAlphabet("1234567890abcdef", 16);
      return nanoid();
    },
    hasStandardTimezone(value: string) {
      const dateChunks = value.split(" ");
      return ["EDT", "EST"].indexOf(dateChunks[1]) > -1;
    },
    getAssetUrl(path: string) {
      return "https://hugohunter.fra1.digitaloceanspaces.com/" + path;
    },
    getSearchParams(key: string) {
      let params = localStorage.getItem(key + "-params");
      if (!params) return {};
      return JSON.parse(params);
    },
    setSearchParams(key: string, params: { [key: string]: string }) {
      localStorage.setItem(key + "-params", JSON.stringify(params));
    },
    async loadDispatchers() {
      try {
        // page_size = 0 means we load all dispatchers
        const resp = await this.$api.dispatchers.findDispatchers({ pageSize: 0 });
        const appStore = useAppStore();
        appStore.setDispatchers((resp.data as any).items);
      } catch (err) {
        console.error("Error load account info", err);
      }
    },
    arrayToMap(arr: (Object & { id: string | number; name: string })[]): { [key: string | number]: string } {
      const map: { [key: string | number]: string } = {};
      for (const item of arr) {
        map[item.id] = item.name;
      }
      return map;
    },
    hasAccess(section: NavigationItem, action: RoleAction = RoleAction.view) {
      const appStore = useAppStore();
      if (!appStore.dispatcher?.roles) {
        return false;
      }

      const sectionRoles = (Permissions[section] || {})[action] || [];
      if ((Permissions[section] || {})[action] === undefined) {
        console.error("No action " + action + "in permissions section " + section);
      }

      for (const role of appStore.dispatcher.roles) {
        if (sectionRoles.includes(role)) return true;
      }
      return false;
    },
    hasRole(role: Role | Role[]) {
      const appStore = useAppStore();
      if (typeof role == "string") role = [role];
      return role.some((r) => appStore.dispatcher?.roles.includes(r));
    },
    clearDigits(value: string) {
      return value.replace(/[^.\d]/g, "");
    },
    normalizePhone(value: string) {
      return this.clearDigits(value);
    },
    setTitle(title: string, title2: string | null = null) {
      const appStore = useAppStore();
      appStore.setTitle(title);

      if (title2) {
        title = title + " – " + title2;
      }
      let account = "";
      if (appStore.dispatcher?.accountName) {
        account = " – " + appStore.dispatcher?.accountName;
      }
      document.title = title + account + " – TMS";
    },
    showChat(user: any) {
      const chatStore = useChatStore();
      chatStore.setChatUser(user);
    },
    transformUpperCase(e: any) {
      e.target.value = e.target.value.toUpperCase();
    },
    transformLowerCase(e: any) {
      e.target.value = e.target.value.toLowerCase();
    },
    async refresh() {
      // Placeholder for the refresh method
    },
    async updatePage(page: number) {
      this.params.page = page;
      await this.refresh();
    },
    async updatePageSize(size: number) {
      this.params.pageSize = size;
      await this.refresh();
    },
    debounce(func: Function, timeout = 300) {
      let timer: number;
      return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    scrollToError() {
      const element = document.querySelector(".v-input--error:first-of-type");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    uniqueArray(objects: any[], uniqueBy: string[], keepFirst = true) {
      return Array.from(
        objects
          .reduce((map, e) => {
            let key = uniqueBy
              .map((key) => [e[key], typeof e[key]])
              .flat()
              .join("-");
            if (keepFirst && map.has(key)) return map;
            return map.set(key, e);
          }, new Map())
          .values(),
      );
    },
  },
});
</script>
