<script>
import CheckInAction from "@/views/trips/view/actions/CheckInAction.vue";
import GoodToGoAction from "@/views/trips/view/actions/GoodToGoAction.vue";
import LoadVerificationAction from "@/views/trips/view/actions/LoadVerificationAction.vue";
import UnloadVerificationAction from "@/views/trips/view/actions/UnloadVerificationAction.vue";
import { RouteStopStatus, TravelOrderCancellationStatusMap } from "@/data/trip";
import { OrderStopState, OrderStopType } from "@/data/order";
import RouteStopStatusLabel from "@/views/trips/components/RouteStopStatus.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import StepperItem from "@/views/trips/components/StepperItem.vue";
import DistanceLine from "@/views/trips/components/DistanceLine.vue";
import StopTimer from "@/views/trips/components/StopTimer.vue";
import RouteStopFreightDetails from "@/views/trips/components/RouteStopFreightDetails.vue";
import RouteStopActions from "@/views/trips/components/RouteStopActions.vue";
import LoadAction from "@/views/trips/view/actions/LoadAction.vue";
import UnloadAction from "@/views/trips/view/actions/UnloadAction.vue";
import ShowInfoAction from "@/views/trips/view/actions/ShowInfoAction.vue";
import FinishAction from "@/views/trips/view/actions/FinishAction.vue";
import StopChangesPopover from "@/views/trips/components/StopChangesPopover.vue";

export default {
  name: "RouteStop",
  components: {
    StopChangesPopover,
    FinishAction,
    ShowInfoAction,
    UnloadAction,
    LoadAction,
    RouteStopActions,
    RouteStopFreightDetails,
    StopTimer,
    DistanceLine,
    StepperItem,
    RouteStopStatusLabel,
    UnloadVerificationAction,
    LoadVerificationAction,
    GoodToGoAction,
    CheckInAction,
  },
  mixins: [DateTimeMixin],
  props: {
    stop: Object,
    route: Object,
    trip: Object,
    index: Number,
    lastStop: Boolean,
    undoable: Boolean,
  },
  emits: ["change"],
  computed: {
    isNew() {
      return this.stop.status === RouteStopStatus.notStarted;
    },
    isReadyForCheckIn() {
      return this.route.dispatchConfirmed && this.stop.status === RouteStopStatus.inRoute;
    },
    isReadyForLoading() {
      return this.stop.status === RouteStopStatus.waitingForLoading && this.isPickUp;
    },
    isReadyForLoadingVerification() {
      return this.stop.status === RouteStopStatus.waitingForVerification && this.isPickUp;
    },
    isReadyForUnloading() {
      return this.stop.status === RouteStopStatus.waitingForLoading && this.isDelivery;
    },
    isReadyForUnloadingVerification() {
      return this.stop.status === RouteStopStatus.waitingForVerification && this.isDelivery;
    },
    isReadyForGoodToGo() {
      return this.stop.status === RouteStopStatus.waitingForGoodToGo;
    },
    isComplete() {
      return this.stop.status === RouteStopStatus.completed;
    },
    isActive() {
      return !this.isNew && !this.isComplete;
    },
    isCheckedIn() {
      return this.stop.checkInTime;
    },
    isPickUp() {
      return this.stop.type === OrderStopType.pickup;
    },
    isDelivery() {
      return this.stop.type === OrderStopType.delivery;
    },
    stopType() {
      switch (this.stop.type) {
        case OrderStopType.pickup:
          return "Pick up";
        case OrderStopType.delivery:
          return "Delivery";
        default:
          return "";
      }
    },
    facility() {
      return this.route.travelOrders
        .flatMap((to) => to.$order?.orderStops || [])
        .map((s) => s.facility)
        .find((o) => o.id === this.stop.facilityId);
    },
    freights() {
      return this.route.freights;
    },
    stopFreights() {
      return this.stop.freights.map((sf) => this.freights.find((f) => f.freightId === sf)).filter((f) => f !== undefined);
    },
    quantity() {
      return this.stopFreights.reduce((sum, f) => sum + f.plannedQuantity, 0);
    },
    weight() {
      return this.stopFreights.reduce((sum, f) => sum + f.plannedWeight, 0);
    },
    distance() {
      const nextStop = this.route.routeStops[this.index];
      return nextStop ? this.route.distances[this.stop.id + "-" + nextStop.id] : null;
    },
    disabled() {
      const travelOrder = this.route.travelOrders.find((to) => to.orderId === this.stop.order?.id);
      return TravelOrderCancellationStatusMap.some((s) => s.id === travelOrder?.status);
    },
    isUpdated() {
      return this.stop.state === OrderStopState.updated;
    },
    isRedispatchNotConfirmed() {
      return this.route.redispatch === "not-confirmed";
    },
  },
  methods: {
    onChange() {
      this.$emit("change");
    },
  },
};
</script>

<template>
  <stepper-item :active="isActive" :complete="isComplete" :last="lastStop" :number="index">
    <div :class="disabled ? 'bg-red-lighten-5' : 'bg-grey-lighten-4'" class="rounded pa-4 my-2" data-qa="route-stop">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-icon
            :class="isPickUp ? 'text-success' : 'text-danger'"
            :icon="isPickUp ? 'mdi-package-variant-closed' : 'mdi-map-marker-radius-outline'"
            class="mr-2"
            size="20"
          ></v-icon>
          <div class="text-blue-grey-darken-1 font-size-12 font-weight-600" data-qa="route-stops-stop-type">
            {{ stopType }}
          </div>
          <router-link
            v-if="stop.order"
            :to="{ name: 'order-view', params: { id: stop.order.id } }"
            class="d-block text-primary font-weight-500 font-size-12 text-decoration-none ml-3"
            data-qa="route-stops-order-number"
            target="_blank"
          >
            Order #{{ stop.order.number }}
          </router-link>
          <stop-changes-popover v-if="isUpdated" :stop="stop"></stop-changes-popover>
        </div>
        <div class="d-flex align-center">
          <stop-timer v-if="isCheckedIn" :end-time="stop.checkOutTime" :start-time="stop.checkInTime"></stop-timer>
          <route-stop-actions
            v-if="(undoable || isComplete) && !disabled"
            :route="route"
            :stop="stop"
            :trip="trip"
            class="ml-3"
            @change="onChange"
          ></route-stop-actions>
        </div>
        <div v-if="isUpdated" class="badge bg-warning pa-2 rounded-te rounded-bs font-weight-600">UPDATED</div>
      </div>
      <route-stop-status-label :stop="stop" class="mt-2"></route-stop-status-label>
      <router-link
        :to="{ name: 'facilities-edit', params: { id: facility?.id } }"
        class="d-block mt-6 font-size-16 font-weight-500 text-primary text-uppercase text-decoration-none"
        data-qa="route-stops-facility-name"
        target="_blank"
      >
        {{ facility?.name }}
      </router-link>
      <div class="mt-2 text-grey-darken-2" data-qa="route-stops-facility-address">
        {{ facility?.addressLine }}, {{ facility?.address }}
      </div>
      <div class="d-flex text-grey-darken-1 font-weight-500 mt-5">
        <div class="mr-2">
          <v-icon icon="mdi-clock-outline" size="20"></v-icon>
        </div>
        <div class="d-flex" style="margin-top: 0.1rem">
          <div class="mr-1">
            <div v-if="stop.dispatchedTimeTo">Appointment:</div>
            <div v-if="stop.checkInTime">Checked in:</div>
            <div v-if="stop.loadUnloadTime && isPickUp">Loaded:</div>
            <div v-if="stop.loadUnloadTime && isDelivery">Unloaded:</div>
            <div v-if="stop.checkOutTime">Completed:</div>
          </div>
          <div>
            <div v-if="stop.dispatchedTimeTo" data-qa="route-stops-appointment">
              {{ dateTZ(stop.dispatchedTimeTo, stop.timezone) }}
            </div>
            <div v-if="stop.checkInTime" data-qa="route-stops-checked-in">
              {{ dateTZ(stop.checkInTime, stop.timezone) }}
            </div>
            <div v-if="stop.loadUnloadTime" data-qa="route-stops-loaded">
              {{ dateTZ(stop.loadUnloadTime, stop.timezone) }}
            </div>
            <div v-if="stop.checkOutTime" data-qa="route-stops-completed">
              {{ dateTZ(stop.checkOutTime, stop.timezone) }}
            </div>
          </div>
        </div>
      </div>
      <route-stop-freight-details :freights="stopFreights" :stop="stop"></route-stop-freight-details>
      <template v-if="!disabled && !isRedispatchNotConfirmed">
        <check-in-action
          v-if="isReadyForCheckIn"
          :route="route"
          :stop="stop"
          :trip="trip"
          class="mt-6"
          @checked-in="onChange"
        ></check-in-action>
        <load-action
          v-if="isReadyForLoading"
          :route="route"
          :stop="stop"
          :trip="trip"
          class="mt-6"
          @load="onChange"
        ></load-action>
        <load-verification-action
          v-if="isReadyForLoadingVerification"
          :route="route"
          :stop="stop"
          :trip="trip"
          class="mt-6"
          @verify="onChange"
        ></load-verification-action>
        <unload-action
          v-if="isReadyForUnloading"
          :route="route"
          :stop="stop"
          :trip="trip"
          class="mt-6"
          @unload="onChange"
        ></unload-action>
        <unload-verification-action
          v-if="isReadyForUnloadingVerification"
          :route="route"
          :stop="stop"
          :trip="trip"
          class="mt-6"
          @verify="onChange"
        ></unload-verification-action>
        <v-row v-if="isReadyForGoodToGo" class="mt-6">
          <v-col>
            <show-info-action :route="route" :stop="stop" :trip="trip"></show-info-action>
          </v-col>
          <v-col>
            <good-to-go-action
              v-if="!lastStop"
              :route="route"
              :stop="stop"
              :trip="trip"
              @finish="onChange"
            ></good-to-go-action>
            <finish-action v-else :route="route" :stop="stop" :trip="trip" @finish="onChange"></finish-action>
          </v-col>
        </v-row>
      </template>
    </div>
    <distance-line v-if="distance" :value="distance"></distance-line>
  </stepper-item>
</template>

<style lang="scss" scoped>
.badge {
  margin-top: -16px;
  margin-right: -16px;
}
</style>
