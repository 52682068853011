<script lang="ts" setup>
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    number: string;
    isCallable?: boolean;
  }>(),
  { isCallable: false },
);

const formattedNumber = computed(() => {
  const cleaned = ("" + props.number).replace(/\D/g, "");
  const match = cleaned.match(/(\d)(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4];
  }
  return cleaned;
});
</script>

<template>
  <span class="phone-number cursor-text">
    <a v-if="props.isCallable" :href="`callto:${props.number}`" class="text-decoration-none text-primary">{{
      formattedNumber
    }}</a>
    <span v-if="!props.isCallable">{{ formattedNumber }}</span>
  </span>
</template>

<style>
.phone-number {
  color: #616161;
}
</style>
