<script lang="ts" setup>
import { defineProps } from "vue";

const props = defineProps<{
  value: boolean;
  muted?: boolean;
}>();
</script>

<template>
  <v-icon v-if="props.value" :color="props.muted ? 'grey-darken-2' : 'success'">mdi-check</v-icon>
  <span v-else>&mdash;</span>
</template>

<style lang="scss" scoped></style>
