import { BaseApi } from "@/api/base";
import type { QueryParams } from "@/api/types";
import type { Response } from "@/api/client";
import type { FileDto } from "@/api/files/dto/file.dto";
import type { ListResponseDto } from "@/api/dto/list-response.dto";
import type { OrderDto, OrderListItemDto } from "@/api/orders/dto/order.dto";

export class OrdersApi extends BaseApi {
  private ordersListController: AbortController | undefined;

  findOrderFiles(orderId: number): Promise<Response<FileDto[]>> {
    return this.client.get<FileDto[]>("orders/" + orderId + "/files");
  }

  async findOrders(
    options: QueryParams = {},
    abortable: boolean = true,
  ): Promise<Response<ListResponseDto<OrderListItemDto>>> {
    if (abortable && this.ordersListController) {
      this.ordersListController.abort();
    }
    this.ordersListController = new AbortController();

    const response: Response<ListResponseDto<OrderListItemDto>> = await this.client.get("orders", options, {
      signal: abortable ? this.ordersListController.signal : undefined,
    });
    this.ordersListController = undefined;
    return response;
  }

  findOrderById(orderId: number): Promise<Response<OrderDto>> {
    return this.client.get("orders/" + orderId);
  }

  upsertOrder(order: any): Promise<Response<OrderDto>> {
    return this.client.save("orders", order);
  }
}
