<script>
import * as pdfjs from "pdfjs-dist";

import ValidationMixin from "@/mixins/validation.mixin";
import { ConfigMixin } from "@/mixins/config.mixin";
import { mapActions } from "pinia";
import { useGalleryStore } from "@/store/gallery.store";

export default {
  name: "FileUploadingGrid",
  mixins: [ValidationMixin, ConfigMixin],
  props: {
    columns: {
      type: Number,
      default: 4,
    },
    modelValue: Array,
    accept: Array,
    required: Boolean,
    disabled: Boolean,
  },
  emits: ["update:modelValue", "remove"],
  data() {
    return {
      dragover: false,
      defaultAccept: ["application/pdf", "image/jpeg", "image/png"],
    };
  },
  computed: {
    files: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    colsSize() {
      return Math.floor(12 / this.columns);
    },
    acceptTypes() {
      return (this.accept || this.defaultAccept).join(",");
    },
  },
  methods: {
    ...mapActions(useGalleryStore, ["setMedia", "setMediaId"]),
    chooseFile() {
      this.$refs.fileInput.click();
    },
    changed(event) {
      const files = Array.from(event.target.files);
      this.emitFiles(files);
    },
    startDrag() {
      this.dragover = true;
    },
    stopDrag() {
      this.dragover = false;
    },
    drop(event) {
      this.dragover = false;
      if (event.dataTransfer.files.length === 0) return;
      const files = Array.from(event.dataTransfer.files).filter((f) => this.acceptTypes.includes(f.type));
      this.emitFiles(files);
    },
    removeFile(index) {
      const removedFileId = this.files[index].id;
      this.files.splice(index, 1);
      if (removedFileId) {
        this.$emit("remove", removedFileId);
      }
    },
    preview(file) {
      this.setMedia(this.files);
      this.setMediaId(file.id);
    },
    async loadPdf(file, onLoad) {
      pdfjs.GlobalWorkerOptions.workerSrc = this.getPdfWorkerSrc(pdfjs.version);
      const loadingTask = pdfjs.getDocument(file);
      loadingTask.promise.then(
        (pdf) => {
          pdf.getPage(1).then((page) => {
            const viewport = page.getViewport({ scale: 1 });

            // Prepare canvas using PDF page dimensions
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            page.render(renderContext).promise.then(() => {
              onLoad(canvas.toDataURL());
            });
          });
        },
        function (reason) {
          console.error(reason);
        },
      );
    },
    emitFiles(files) {
      const readyFiles = [];
      for (const file of files) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          async () => {
            if (file.type.includes("image")) {
              readyFiles.push({
                name: file.name,
                contentType: file.type,
                size: file.size,
                previewPath: reader.result,
                file,
              });
            } else if (file.type === "application/pdf") {
              await this.loadPdf(reader.result, (pdf) => {
                readyFiles.push({
                  name: file.name,
                  contentType: file.type,
                  size: file.size,
                  previewPath: pdf,
                  file,
                });
                if (readyFiles.length === files.length) {
                  this.files.push(...readyFiles);
                }
              });
            } else {
              readyFiles.push({
                name: file.name,
                contentType: file.type,
                size: file.size,
                previewPath: null,
                file,
              });
            }
            if (readyFiles.length === files.length) {
              this.files.push(...readyFiles);
            }
          },
          false,
        );
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<template>
  <v-row>
    <v-col v-for="(file, i) in files" :key="i" :cols="colsSize">
      <slot :file="file" name="prefix"></slot>
      <div :class="{ error: file.error }" class="file-container">
        <div class="file-preview flex-1">
          <img v-if="file.previewPath" :src="file.previewPath" alt="" />
        </div>
        <v-progress-linear v-show="file.loading" color="primary" indeterminate></v-progress-linear>
        <div class="file-footer pa-4">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <v-icon class="mr-2" color="primary" icon="mdi-file-document-outline" size="16"></v-icon>
              <v-tooltip :disabled="file.name.length <= 24" location="top">
                <template #activator="{ props }">
                  <div class="text-grey-darken-4 file-name" v-bind="props">
                    {{ $filters.ellipsis(file.name, 24, "middle") }}
                  </div>
                </template>
                {{ file.name }}
              </v-tooltip>
            </div>
            <v-icon
              v-if="!disabled"
              class="cursor-pointer"
              color="error"
              icon="mdi-delete-outline"
              size="16"
              @click="removeFile(i)"
            ></v-icon>
          </div>
          <slot :file="file" name="footer"></slot>
          <v-btn
            block
            class="text-uppercase font-weight-500 mt-4"
            color="primary"
            size="small"
            variant="outlined"
            @click="preview(file)"
          >
            View
          </v-btn>
        </div>
      </div>
    </v-col>
    <v-col v-if="!disabled" :cols="colsSize">
      <div
        :class="{ dragover }"
        class="file-upload-zone d-flex align-center justify-center"
        @click="chooseFile"
        @dragenter.prevent="startDrag"
        @dragleave.prevent="stopDrag"
        @dragover.prevent
        @drop.prevent="drop"
      >
        <div class="d-flex flex-column align-center file-control">
          <v-icon class="d-block mb-4" icon="mdi-plus" size="48"></v-icon>
          <div class="font-size-12">Upload or drag file here</div>
        </div>
        <v-file-input
          ref="fileInput"
          :accept="acceptTypes"
          :rules="required && !files?.length ? [fileRequiredValidator] : []"
          class="file-input"
          multiple
          type="file"
          @change="changed"
        />
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

$block-height: 282px;
$border-color: #e0e0e0;

.file-container {
  height: $block-height;
  border: 1px solid $border-color;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  &.error {
    border-color: $danger;
  }

  .file-preview {
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .file-footer {
    border-top: 1px solid $border-color;
  }

  .file-name {
    overflow-wrap: anywhere;
  }
}

.file-upload-zone {
  position: relative;
  border: 1px solid $primary;
  border-radius: 4px;
  width: 100%;
  height: $block-height;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
  background-color: #ffffff;

  &:hover {
    background-color: rgba($primary, 0.05);
  }

  &.dragover {
    border-style: dashed;
  }

  .file-input {
    position: absolute;
    width: 0;
    height: 0;
    overflow: clip;
    opacity: 0;
  }

  .file-control {
    color: $primary;
  }

  &:has(.v-input--error) {
    border-color: $danger;

    &:hover {
      background-color: rgba($danger, 0.05);
    }

    .file-control {
      color: $danger;
    }
  }
}
</style>
