export const sleep = (m: number): Promise<void> => new Promise((r) => setTimeout(r, m));

export const chatCodes = {
  TOKEN_EXPIRED: 40,
  WS_CLOSED_SUCCESS: 1000,
};

export const DEFAULT_LOG_LEVEL = "none";

export function retryInterval(numberOfFailures: number) {
  // try to reconnect in 0.25-25 seconds (random to spread out the load from failures)
  const max = Math.min(500 + numberOfFailures * 2000, 25000);
  const min = Math.min(Math.max(250, (numberOfFailures - 1) * 2000), 25000);
  return Math.floor(Math.random() * (max - min) + min);
}

export function randomId() {
  return generateUUIDv4();
}

function hex(bytes: Uint8Array): string {
  let s = "";
  for (let i = 0; i < bytes.length; i++) {
    s += bytes[i].toString(16).padStart(2, "0");
  }
  return s;
}

// https://tools.ietf.org/html/rfc4122
export function generateUUIDv4() {
  const bytes = getRandomBytes(16);
  bytes[6] = (bytes[6] & 0x0f) | 0x40; // version
  bytes[8] = (bytes[8] & 0xbf) | 0x80; // variant

  return (
    hex(bytes.subarray(0, 4)) +
    "-" +
    hex(bytes.subarray(4, 6)) +
    "-" +
    hex(bytes.subarray(6, 8)) +
    "-" +
    hex(bytes.subarray(8, 10)) +
    "-" +
    hex(bytes.subarray(10, 16))
  );
}

function getRandomValuesWithMathRandom(bytes: Uint8Array): void {
  const max = Math.pow(2, (8 * bytes.byteLength) / bytes.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = Math.random() * max;
  }
}

declare const msCrypto: Crypto;

const getRandomValues = (() => {
  if (typeof crypto !== "undefined" && typeof crypto?.getRandomValues !== "undefined") {
    return crypto.getRandomValues.bind(crypto);
  } else if (typeof msCrypto !== "undefined") {
    return msCrypto.getRandomValues.bind(msCrypto);
  } else {
    return getRandomValuesWithMathRandom;
  }
})();

function getRandomBytes(length: number): Uint8Array {
  const bytes = new Uint8Array(length);
  getRandomValues(bytes);
  return bytes;
}

/**
 * listenForConnectionChanges - Adds an event listener fired on browser going online or offline
 */
export function addConnectionEventListeners(cb: (e: Event) => void) {
  if (typeof window !== "undefined" && window.addEventListener) {
    window.addEventListener("offline", cb);
    window.addEventListener("online", cb);
  }
}

export function removeConnectionEventListeners(cb: (e: Event) => void) {
  if (typeof window !== "undefined" && window.removeEventListener) {
    window.removeEventListener("offline", cb);
    window.removeEventListener("online", cb);
  }
}
