import { defineStore } from "pinia";
import type { DispatcherDto } from "@/api/dto/dispatcher.dto";

interface AppState {
  dispatcher?: AppStateDispatcher;
  dispatchers: DispatcherDto[];
  mobile: boolean;
  title: string;
}

interface AppStateDispatcher extends DispatcherDto {
  perms?: Record<string, string>;
  companyName?: string;
  accountName?: string;
}

export const useAppStore = defineStore("appStore", {
  state: (): AppState => ({
    dispatchers: [],
    mobile: false,
    title: "",
  }),
  getters: {
    getDispatcher: (state) => (id: number) => {
      return state.dispatchers.find((d) => d.id === id) || null;
    },
  },
  actions: {
    setMobile(mobile: boolean) {
      this.mobile = mobile;
    },
    setDispatchers(dispatchers: DispatcherDto[]) {
      this.dispatchers = dispatchers;
    },
    setTitle(title: string) {
      this.title = title;
    },
    setDispatcher(dispatcher: AppStateDispatcher) {
      if (dispatcher.company) {
        dispatcher.companyName = dispatcher.company.name;
      }
      this.dispatcher = dispatcher;
    },
  },
});
