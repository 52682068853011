<script>
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";
import { useChatStore } from "@/store/chat.store";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ChatMixin",
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
    ...mapState(useChatStore, ["threads"]),
  },
  methods: {
    async connectChat() {
      await this.$ws.connect(
        this.$api.client.getWSChatUrl(),
        () => this.$api.client.getToken(),
        (data) => {
          const chatStore = useChatStore();
          chatStore.handleWSMessage(data);
        },
        (lastEvent) => {
          this.loadNewThreads(Math.round(lastEvent.getTime() / 1000));
        },
        (isOnline) => {
          const chatStore = useChatStore();
          chatStore.setOffline(!isOnline);
        },
      );
      await this.loadAllThreads();
    },
    disconnectChat() {
      this.$ws.disconnect();
    },
    showMessageTime(dt) {
      const d = this.$dayjs.utc(dt).tz(this.dispatcher.timezone);
      const isToday = d.isSame(new Date(), "day");
      return d.format(isToday ? "HH:mm z" : "MM/DD/YY HH:mm z");
    },
    async noResponseThread() {
      try {
        const resp = await this.$api.chat.noResponseThread(this.thread.id);
        const chatStore = useChatStore();
        chatStore.updateThread(resp.data);
        this.updateMessages();
      } catch (err) {
        console.error("Can not set no response thread", err);
      }
    },
    async resolveThread() {
      await this.updateThread({ dispatcherId: this.dispatcher.id, resolved: true });
    },
    updateMessages() {
      if (!this.thread || !this.thread.id) return;
      this.$ws.sendCommand("messages", {
        threadId: this.thread.id,
        timeFrom: this.updateMessagesTime,
      });
      this.updateMessagesTime = Math.floor(Date.now() / 1000);
    },
    async updateThread(data) {
      try {
        const resp = await this.$api.chat.updateThread(this.thread.id, data);
        const chatStore = useChatStore();
        chatStore.updateThread(resp.data);
        this.updateMessages();
      } catch (err) {
        console.error("Can not update thread", err);
      }
    },
    async loadAllThreads() {
      let offset = 0;
      const chatStore = useChatStore();
      for (;;) {
        try {
          const resp = await this.$api.chat.getThreads(offset, 300);
          if (resp.success) {
            if (resp.data.threads.length === 0) break;

            chatStore.addThreads(resp.data.threads);
            offset += resp.data.threads.length;
          }
        } catch (e) {
          console.error("Can t update threads list", e);
          break;
        }
      }

      chatStore.sortThreads();
      chatStore.recalcThreadsCount();
    },
    async loadNewThreads(timeFrom) {
      const chatStore = useChatStore();
      const resp = await this.$api.chat.getThreads(0, 1000, timeFrom);
      if (resp.success) {
        for (const thread of resp.data.threads) {
          chatStore.updateThread(thread);
        }

        chatStore.sortThreads();
        chatStore.recalcThreadsCount();
      }
    },
  },
});
</script>
