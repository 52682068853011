import { UsStates } from "@/data/states";
import { defineComponent } from "vue";

const ValidationMixin = defineComponent({
  methods: {
    requiredValidator: (v: any): boolean | string =>
      (v !== "" && v !== null && v !== undefined && v.toString().trim() !== "") || "Field is required",
    emailValidator: (email: string): boolean | string => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email) || "Email is not valid";
    },
    digitsValidator: (v: any): boolean | string =>
      (v.toString() && !isNaN(v) && !isNaN(parseFloat(v))) || "Only digits allowed",
    selectValidator: (v: any[]): boolean | string => (v && v.length > 0) || "Required at least one value",
    phoneValidator: (v: string): boolean | string => {
      const len = (v || "").replace(/ /g, "").length;
      if (len === 0) return true;
      return len === 11 || "Phone number is incorrect";
    },
    validateVIN: (v: string): boolean | string => (v && v.length === 17) || "VIN code is incorrect",
    stateValidator: (v: string): boolean | string => {
      if (v && v.length !== 2) return "Two letters state name";
      return (v && UsStates.includes(v.toUpperCase())) || "State not found";
    },
    dateInputValidator: (v: string): boolean | string => {
      const re = /^[0-1]\d\/[0-3]\d\/\d{4}$/;
      return re.test(v) || "Date is not valid";
    },
    yearLengthValidator: (v: string): boolean | string => !v || (v && v.length === 4) || "Incorrect year",
    positiveNumber: (v: number): boolean | string => (v && !!Number(v) && v > 0) || "Only positive numbers allowed",
    fileRequiredValidator: (v: File[]): boolean | string => {
      return (v[0] && !!v[0].name) || "File is required";
    },
  },
});

export default ValidationMixin;
