import { BaseApi } from "@/api/base";
import type { QueryParams } from "@/api/types";
import type { Response } from "@/api/client";
import type { ListResponseDto } from "@/api/dto/list-response.dto";
import type { DriverDto } from "@/api/users/dto/driver.dto";
import type { OwnerDto } from "@/api/users/dto/owner.dto";
import type { CoordinatorDto } from "@/api/users/dto/coordinator.dto";
import type { CoordinatorOwnerResponse } from "@/api/users/dto/response/coordinator-owner.response";

export class UsersApi extends BaseApi {
  private driversListController: AbortController | undefined;
  private ownersListController: AbortController | undefined;
  private coordinatorsListController: AbortController | undefined;

  async findDrivers(options = {}): Promise<Response<ListResponseDto<DriverDto>>> {
    if (this.driversListController) {
      this.driversListController.abort();
    }
    this.driversListController = new AbortController();
    const response: Response<ListResponseDto<DriverDto>> = await this.client.get("drivers", options, {
      signal: this.driversListController.signal,
    });
    this.driversListController = undefined;
    return response;
  }

  async findOwners(options = {}): Promise<Response<ListResponseDto<OwnerDto>>> {
    if (this.ownersListController) {
      this.ownersListController.abort();
    }
    this.ownersListController = new AbortController();
    const response: Response<ListResponseDto<OwnerDto>> = await this.client.get("owners", options, {
      signal: this.ownersListController.signal,
    });
    this.ownersListController = undefined;
    return response;
  }

  async findCoordinators(options = {}): Promise<Response<ListResponseDto<CoordinatorDto>>> {
    if (this.coordinatorsListController) {
      this.coordinatorsListController.abort();
    }
    this.coordinatorsListController = new AbortController();
    const response: Response<ListResponseDto<CoordinatorDto>> = await this.client.get("coordinators", options, {
      signal: this.coordinatorsListController.signal,
    });
    this.coordinatorsListController = undefined;
    return response;
  }

  findCoordinatorOwners(coordinatorId: number, options: QueryParams = {}): Promise<Response<CoordinatorOwnerResponse[]>> {
    return this.client.get("/coordinators/" + coordinatorId + "/owners", options);
  }

  findOwnerCoordinators(ownerId: number, options: QueryParams = {}) {
    return this.client.get("/owners/" + ownerId + "/coordinators", options);
  }

  createOwnerCoordinator(ownerId: number, userId: number, fullAccess: boolean) {
    return this.client.post("/owners/" + ownerId + "/coordinators", { fullAccess: fullAccess, userId: userId });
  }

  removeOwnerCoordinator(ownerId: number, userId: number) {
    return this.client.delete("/owners/" + ownerId + "/coordinators/" + userId);
  }

  updateOwnerCoordinator(ownerId: number, userId: number, fullAccess: boolean) {
    return this.client.patch("/owners/" + ownerId + "/coordinators/" + userId, { fullAccess: fullAccess });
  }

  findUsers(options: QueryParams = {}) {
    return this.client.get("users", options);
  }

  findUserById(userId: number) {
    return this.client.get("users/" + userId);
  }

  findDriverById(driverId: number) {
    return this.client.get("drivers/" + driverId);
  }

  findOwnerById(ownerId: number) {
    return this.client.get("owners/" + ownerId);
  }

  upsertUser(user: any) {
    return this.client.save("/users", user);
  }

  async upsertDriver(driver: any) {
    return this.client.save("/drivers", driver);
  }

  updateDriver(driverId: number, data: any) {
    return this.client.patch("drivers/" + driverId, data);
  }

  findCompanies(userId: number) {
    return this.client.get("owners/" + userId + "/companies");
  }

  upsertCompany(userId: number, company: any) {
    return this.client.save("/owners/" + userId + "/companies", company);
  }

  sendInvite(userId: number) {
    return this.client.post("/users/" + userId + "/send-invite", {});
  }

  archiveUser(userId: number) {
    return this.client.post("/users/" + userId + "/archive", {});
  }

  unarchiveUser(userId: number) {
    return this.client.post("/users/" + userId + "/unarchive", {});
  }
}
