<script>
import BaseView from "@/views/BaseView.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import OmniForm from "@/components/form/OmniForm.vue";
import FormActions from "@/components/form/FormActions.vue";
import OrderValidator from "@/views/orders/helpers/order.validator";
import OrderEditInfo from "@/views/orders/components/edit/OrderEditInfo.vue";
import OrderEditFiles from "@/views/orders/components/edit/OrderEditFiles.vue";
import OrderEditStops from "@/views/orders/components/edit/OrderEditStops.vue";
import DispatcherSelect from "@/components/inputs/DispatcherSelect.vue";
import OrderStatus from "@/components/status/OrderStatus.vue";
import InfoMessage from "@/components/InfoMessage.vue";
import { mapActions, mapState } from "pinia";
import { useAppStore } from "@/store/app.store";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "OrderEdit",
  components: {
    InfoMessage,
    OrderStatus,
    DispatcherSelect,
    OrderEditStops,
    OrderEditFiles,
    OrderEditInfo,
    FormActions,
    OmniForm,
  },
  mixins: [BaseView, DateTimeMixin, OrdersMixin, ValidationMixin],
  data() {
    return {
      originalOrder: null,
      order: {
        freights: [],
        orderStops: [],
        refNumbers: [],
        sendUpdatesTo: [],
        rate: 0,
        underTracking: false,
        teamForBroker: false,
        protectFromFreezing: false,
        requiredDriverCertificates: [],
        hazmat: false,
        temperatures: [],

        $hasTemperatureControl: false,
      },
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher", "dispatchers"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError", "showSnackbarSuccess"]),
    async load() {
      if (this.$route.params.id === undefined) {
        this.order.createdBy = this.dispatcher.id;

        this.setTitle("New Order", "Orders");

        // add empty stops
        await this.addStop(this.STOP_PICK_UP);
        await this.addStop(this.STOP_DELIVERY);

        return;
      }

      this.loading = true;
      const response = await this.$api.orders.findOrderById(this.$route.params.id);
      if (response.success) {
        this.originalOrder = JSON.parse(JSON.stringify(response.data));
        this.order = response.data;
        if ((this.order.temperatures && this.order.temperatures.length === 2) || this.order.protectFromFreezing) {
          this.order.$hasTemperatureControl = true;
        }
      } else {
        this.showSnackbarError("Error loading order data");
      }

      this.loading = false;
    },
    async addFreight(stop) {
      const id = this.generateId();
      const freight = {
        id: id,
        type: "pl",
        quantity: 1,
        weight: 0,
        stackable: false,
      };

      this.order.freights.push(freight);
      stop.freights.push(freight.id);
    },
    async addStop(stopType) {
      const stop = {
        id: this.generateId(),
        type: stopType,
        freights: [],
        note: "",
        timeType: "f",
        timeFrom: null,
        timeTo: null,
        time2Type: "f",
        time2From: null,
        time2To: null,
      };
      if (stopType === this.STOP_PICK_UP) await this.addFreight(stop);
      this.order.orderStops.push(stop);
    },
    async save() {
      const { valid } = await this.$refs.form.validate();
      const isOrderValid = new OrderValidator(this.order).validate();
      if (!valid || !isOrderValid) {
        this.scrollToError();
        return;
      }

      delete this.order.polyline;
      const response = await this.$api.orders.upsertOrder(this.order);
      if (response.success) {
        await this.$refs.filesForm.saveFiles(response.data.id);
        this.showSnackbarSuccess(this.$t("general.saved"));
        if (this.order.tripId) {
          this.$router.push({ name: "trip-edit", params: { id: this.order.tripId } });
        } else {
          this.$router.push({ name: "order-view", params: { id: response.data.id } });
        }
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<template>
  <omni-form ref="form">
    <div class="d-flex align-center justify-space-between mb-4 flex-wrap">
      <div v-if="order.id" class="d-flex align-center">
        <h3 class="title font-weight-medium">Order #{{ order.number }}</h3>
        <order-status v-if="order.status" :order="order" class="ml-4"></order-status>
        <info-message v-if="order.tripId" class="ml-6">
          After changing order information, you will be automatically redirected to the Trip manage page to synchronize
          information
        </info-message>
      </div>
      <h3 v-else class="title font-weight-medium">Create new order</h3>
      <div class="d-flex align-center flex-wrap">
        <div class="d-flex align-center mr-5">
          <span class="text-grey-darken-1 mr-2 mb-1">Created date:</span>
          <span class="text-grey-darken-3">{{ dateTZ(order.createdAt, dispatcher.timezone) }}</span>
        </div>
        <dispatcher-select v-model="order.createdBy" :disabled="true" data-qa="created_by" label="Created by" />
      </div>
    </div>

    <order-edit-info v-model="order"></order-edit-info>
    <order-edit-stops v-model="order" :original-order="originalOrder"></order-edit-stops>
    <order-edit-files ref="filesForm" :order="order"></order-edit-files>
    <form-actions @cancel="goBack" @submit="save"></form-actions>
  </omni-form>
</template>

<style lang="scss">
.v-application--is-ltr .create-order .v-text-field .v-input__prepend-inner {
  padding-right: 8px;
}

.v-input.v-input--is-label-active {
  &.text-orange {
    .theme--light.v-label {
      color: #fb8c00;
    }
  }

  &.text-green {
    .theme--light.v-label {
      color: #4caf50;
    }
  }
}
</style>
