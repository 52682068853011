<script>
import BaseView from "@/views/BaseView.vue";

export default {
  name: "OpenChatButton",
  mixins: [BaseView],
  props: {
    user: {
      type: Object,
      required: true,
    },
    bgColor: String,
    type: {
      type: String,
      default: "icon",
    },
  },
  computed: {
    isIcon() {
      return this.type === "icon";
    },
  },
};
</script>

<template>
  <span v-if="isIcon" class="chat-button d-flex align-center justify-center" @click="showChat(user)">
    <v-icon class="text-primary" size="14">mdi-message-outline</v-icon>
  </span>
  <v-btn
    v-else
    :block="true"
    class="text-uppercase font-weight-600"
    color="primary"
    variant="outlined"
    @click="showChat(user)"
  >
    Open chat
  </v-btn>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.chat-button {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  cursor: pointer;
  background-color: $grey-light2;
  border-radius: 50%;
  transition: background-color ease-in-out 150ms;

  .v-icon {
    transition: color ease-in-out 150ms;
  }

  &:hover {
    background-color: $primary;

    .v-icon {
      color: $white !important;
    }
  }
}
</style>
