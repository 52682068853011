<script>
import TrackingStatus from "@/views/users/components/TrackingStatus.vue";
import OnlineStatus from "@/views/users/components/OnlineStatus.vue";
import DeviceInfo from "@/views/users/components/DeviceInfo.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "UserViewUserInfo",
  components: { DeviceInfo, OnlineStatus, TrackingStatus },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(useAppStore, ["dispatchers"]),
    hiredByName() {
      const hiredBy = this.dispatchers.find((d) => d.id === this.user.hiredById);
      return hiredBy ? hiredBy.name : null;
    },
  },
};
</script>

<template>
  <div class="user-block pt-5 pr-5 pb-7 pl-5 bg-white rounded mt-4">
    <div class="text-grey-darken-3 mb-6 font-weight-medium">User info</div>
    <v-row>
      <div class="label">
        <span class="text-grey-darken-1">GPS Tracking</span>
      </div>
      <div class="description">
        <tracking-status :user="user"></tracking-status>
      </div>
    </v-row>
    <v-row align="center">
      <div class="label">
        <span class="text-grey-darken-1">Last online</span>
      </div>
      <div class="description">
        <online-status :user="user"></online-status>
      </div>
    </v-row>
    <v-row align="center">
      <div class="label">
        <span class="text-grey-darken-1">Device info</span>
      </div>
      <div class="description">
        <device-info :user="user"></device-info>
      </div>
    </v-row>
    <v-row>
      <div class="label">
        <span class="text-grey-darken-1">Hired by:</span>
      </div>
      <div class="description">
        <router-link class="text-primary text-decoration-none" to="">{{ hiredByName }}</router-link>
      </div>
    </v-row>
    <v-row>
      <div class="label">
        <span class="text-grey-darken-1">Created date:</span>
      </div>
      <div class="description">
        {{ $filters.date(user.createdTime || $dayjs()) }}
      </div>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>
