<script lang="ts" setup>
import { computed, defineEmits, defineProps, withDefaults } from "vue";

const props = withDefaults(
  defineProps<{
    id?: string;
    modelValue: any;
    items: Array<any>;
    multiple?: boolean;
    label?: string;
    itemTitle?: string;
    itemValue?: string;
    clearable?: boolean;
    required?: boolean;
    rules?: Array<any>;
  }>(),
  {
    itemTitle: "name",
    itemValue: "id",
  },
);

const emit = defineEmits(["change", "update:modelValue"]);

const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("change", value);
    emit("update:modelValue", value);
  },
});
</script>

<template>
  <v-select
    :id="props.id"
    v-model="model"
    :class="{ required: props.required }"
    :clearable="props.clearable"
    :item-title="props.itemTitle"
    :item-value="props.itemValue"
    :items="props.items"
    :label="props.label"
    :multiple="props.multiple"
    :rules="props.rules"
    color="primary"
    density="compact"
    hide-details="auto"
    variant="outlined"
  >
    <template #item="{ item, props: itemProps }">
      <v-divider v-if="typeof item.raw === 'object' && 'divider' in item.raw" />
      <v-list-subheader v-else-if="typeof item.raw === 'object' && 'header' in item.raw" :title="item.raw.header" />
      <v-list-item v-else :disabled="item.raw.disabled" :label="item.title" :value="item.value" v-bind="itemProps">
        <template v-if="props.multiple" #prepend="status">
          <v-checkbox-btn
            :key="item.value"
            :model-value="status.isSelected"
            :ripple="false"
            color="primary"
            tabindex="-1"
          />
        </template>
      </v-list-item>
    </template>
  </v-select>
</template>

<style lang="scss">
@import "@/assets/style/color";

.v-list-subheader {
  font-size: 11px;
  color: $grey;
}
</style>
