<script>
import BaseView from "@/views/BaseView.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "ChangePassword",
  mixins: [BaseView],
  data: () => ({
    error: "",
    form: { password: "", newPassword: "", newPassword2: "" },
  }),
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarSuccess"]),
    async changePassword() {
      this.error = "";
      try {
        const resp = await this.$api.auth.changePassword({
          password: this.form.password,
          newPassword: this.form.newPassword,
          newPassword2: this.form.newPassword2,
        });
        if (resp.success) {
          this.showSnackbarSuccess(this.$t("auth.password-changed"));
        }
      } catch (e) {
        console.error("Error changing password", e);
        if (e.data) {
          this.error = e.data.detail;
        }
      }
    },
  },
};
</script>

<template>
  <v-container :fluid="true">
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-card class="mt-6">
          <v-card-title>{{ $t("auth.change-password") }}</v-card-title>
          <v-card-text>
            <v-alert v-if="error" density="compact" type="warning">{{ error }}</v-alert>
            <v-text-field
              v-model="form.password"
              :label="$t('auth.password')"
              color="primary"
              density="compact"
              type="password"
              variant="outlined"
            />
            <v-text-field
              v-model="form.newPassword"
              :label="$t('auth.new-password')"
              color="primary"
              density="compact"
              type="password"
              variant="outlined"
            />
            <v-text-field
              v-model="form.newPassword2"
              :label="$t('auth.new-password')"
              color="primary"
              density="compact"
              type="password"
              variant="outlined"
            />
            <div class="text-center">
              <v-btn class="mx-2" color="primary" dark @click="changePassword">{{ $t("auth.change-password") }}</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
