<script>
export default {
  name: "DriverCoordinator",
  props: {
    user: Object,
  },
  computed: {
    coordinator() {
      if (
        this.user.owner &&
        this.user.owner.coordinator &&
        this.user.owner.coordinator.id === this.user.contactPersonId
      ) {
        return this.user.owner.coordinator;
      }
      if (this.user.coordinator && this.user.coordinator.id === this.user.contactPersonId) {
        return this.user.coordinator;
      }
      return null;
    },
  },
};
</script>

<template>
  <div v-if="coordinator" class="d-flex align-center mt-2">
    <div class="bg-grey-lighten-4 pa-0 status-item text-uppercase text-blue-lighten-2 mr-2">
      {{ $t("users.cr") }}
    </div>
    <span v-if="user.contactPersonId && user.contactPersonId === coordinator.id" class="currency-icon mr-2 small" />
    <router-link
      :to="{ name: 'user-view', params: { id: coordinator.id } }"
      class="mr-1 text-decoration-none text-primary"
    >
      {{ coordinator.name }}
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.status-item {
  font-size: 9px;
  line-height: 16px !important;
  height: 16px !important;
  border-radius: 100px;
  padding: 0 2px !important;
  min-width: auto !important;
}
</style>
