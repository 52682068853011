<script>
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { OrderFreightTypeMap, OrderStopTimeTypeMap, OrderStopType } from "@/data/order";
import { FacilityTypeMap } from "@/data/facility";

export default {
  name: "OrdersMixin",
  mixins: [DateTimeMixin],
  data() {
    return {
      STOP_DELIVERY: OrderStopType.delivery,
      STOP_PICK_UP: OrderStopType.pickup,
      STOP_RELOAD: OrderStopType.reload,
      freightTypePallets: "pl",
      freightTypePieces: "pc",
      timeTypesMap: {
        ap: "Appointment",
        as: "ASAP",
        d: "Direct",
        f: "FCFS",
      },
      certificates: ["Hazmat", "Tanker", "TSA", "TWIC"],
    };
  },
  computed: {
    facilityTypesMap() {
      const map = {};
      for (const facilityType of FacilityTypeMap) {
        map[facilityType.id] = facilityType.name;
      }
      return map;
    },
  },
  methods: {
    getOrderRoute(order) {
      let firstPickup = null,
        lastDelivery = null;
      for (const stop of order.orderStops) {
        if (stop.type === this.STOP_PICK_UP && firstPickup === null) {
          firstPickup = stop;
        }
        if (stop.type === this.STOP_DELIVERY) {
          lastDelivery = stop;
        }
      }

      return `${firstPickup.facility.address} → ${lastDelivery.facility.address}`;
    },
    getStopDistance(stops, distances, orderIndex) {
      const distanceKey = `${stops[orderIndex].id}-${stops[orderIndex + 1].id}`;
      const distanceInMiles = distances[distanceKey] || 0;
      return this.$filters.miles(distanceInMiles);
    },
    getStopTime(stop) {
      const times = [];
      if (stop.timeTo) {
        times.push(this.getTimeInterval(stop.timeFrom, stop.timeTo, stop.timezone));
      }
      if (stop.time2To) {
        times.push(this.getTimeInterval(stop.time2From, stop.time2To, stop.timezone));
      }
      return times.join(", ");
    },
    getTimeType(id) {
      return OrderStopTimeTypeMap.find((t) => t.id === id)?.name;
    },
    getTimeInterval(from, to, timezone) {
      const tzShort = this.getTZNames(timezone);
      const intervals = [];

      if (from) intervals.push(this.getDateToDisplay(from, timezone));
      if (to) intervals.push(this.getDateToDisplay(to, timezone));
      if (intervals.length === 0) return "";
      if (intervals.length === 2) {
        if (intervals[0].date === intervals[1].date) {
          return intervals[0].date + " " + intervals[0].time + " - " + intervals[1].time + " " + tzShort;
        }
      }

      return intervals.map((d) => d.date + " " + d.time + " " + tzShort).join(" - ");
    },
    getDateToDisplay(date, timezone) {
      const dateTz = this.$dayjs(date).tz(timezone);
      return {
        date: dateTz.format("MM/DD/YYYY"),
        time: dateTz.format("HH:mm"),
      };
    },
    getTimeTypes(type) {
      if (type === this.STOP_PICK_UP) {
        return Object.keys(this.timeTypesMap)
          .filter((t) => t !== "d")
          .map((k) => ({
            id: k,
            name: this.timeTypesMap[k],
          }));
      } else {
        return Object.keys(this.timeTypesMap)
          .filter((t) => t !== "as")
          .map((k) => ({
            id: k,
            name: this.timeTypesMap[k],
          }));
      }
    },
    getFreightName(freight, number = null, firstName = "Freight", withStackable = true) {
      const nameParts = [firstName, number ? "#" + number + ":" : ""];
      if (freight.quantity) {
        nameParts.push(freight.quantity);
        nameParts.push(freight.type === "pl" ? "pallets" : "pieces");
      }
      if (freight.weight) {
        nameParts.push(freight.weight);
        nameParts.push("lbs");
      }
      if (freight.length && freight.width && freight.height) {
        nameParts.push(freight.length);
        nameParts.push("x");
        nameParts.push(freight.width);
        nameParts.push("x");
        nameParts.push(freight.height);
      }
      if (!freight.stackable && withStackable) {
        nameParts.push("Not stackable");
      }
      return nameParts.join(" ");
    },
    getFreightTypeName(freight) {
      return OrderFreightTypeMap.find((ft) => ft.id === freight.type)?.name;
    },
  },
};
</script>
