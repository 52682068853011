<script>
export default {
  name: "DeviceInfo",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deviceInfo() {
      const infoChunks = [];
      if (this.user.mobileUserDeviceOs) {
        infoChunks.push(this.user.mobileUserDeviceOs);
      }
      if (this.user.mobileUserDeviceModel) {
        infoChunks.push(this.user.mobileUserDeviceModel);
      }
      if (this.user.mobileUserAppVersion) {
        infoChunks.push(this.user.mobileUserAppVersion);
      }
      return infoChunks.length > 0 ? infoChunks.join(", ") : "—";
    },
  },
};
</script>

<template>
  {{ deviceInfo }}
</template>

<style lang="scss" scoped></style>
