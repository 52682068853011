<script>
import BaseView from "@/views/BaseView.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import UserRole from "@/views/users/components/UserRole.vue";
import CoordinatorsSearchForm from "@/views/coordinators/CoordinatorsSearchForm.vue";
import OmniTable from "@/components/OmniTable.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import PageHeader from "@/components/PageHeader.vue";
import MobileStatus from "@/components/status/MobileStatus.vue";
import OpenChatButton from "@/components/controls/OpenChatButton.vue";

export default {
  name: "CoordinatorsPage",
  components: {
    OpenChatButton,
    MobileStatus,
    PageHeader,
    LinkButton,
    OmniTable,
    CoordinatorsSearchForm,
    PhoneNumber,
    UserRole,
  },
  mixins: [BaseView, UsersMixin],
  data() {
    return {
      perm: "coordinators",
      headers: [
        { title: this.$t("general.full-name"), key: "name", class: "col-name", sortable: false },
        {
          title: this.$t("general.phone"),
          key: "phone",
          align: "left",
          class: "col-phone",
          sortable: false,
        },
        {
          title: this.$t("general.owners"),
          key: "owners",
          align: "left",
          class: "col-owner",
          sortable: false,
        },
        {
          title: this.$t("general.email"),
          key: "email",
          align: "left",
          class: "col-email",
          sortable: false,
        },
        {
          title: this.$t("general.address"),
          key: "address",
          class: "col-address",
          sortable: false,
        },
        {
          title: this.$t("general.actions-btn"),
          key: "actions",
          class: "row-actions",
          sortable: false,
        },
      ],
      filter: {},
      data: { total: 0 },
      loading: true,
      archived: false,
    };
  },
  computed: {
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
  },
  watch: {
    archived() {
      this.refresh();
    },
  },
  async created() {
    this.setTitle(this.$t("drivers.coordinators"));
  },
  methods: {
    async refresh() {
      const params = { ...this.filter, ...this.params };
      params.archived = this.archived;

      this.loading = true;
      try {
        const resp = await this.$api.users.findCoordinators(params);
        this.data = resp.data;
      } catch (error) {
        console.error("Error load coordinators list: ", error);
      }
      this.loading = false;
    },
    async search(params) {
      this.params.page = 1;
      if (params.phone) params.phone = this.normalizePhone(params.phone);
      this.filter = params;

      await this.refresh();
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100">
    <page-header :header="$t('users.coordinators')">
      <template #suffix>
        <link-button id="create-user-btn" :to="{ name: 'user-create' }" color="success">
          {{ $t("general.create-new") }}
        </link-button>
      </template>
    </page-header>
    <coordinators-search-form @search="search" />

    <omni-table
      v-model:archived="archived"
      :footer-props="{ 'items-per-page-options': pagination.itemsPerPage }"
      :headers="headers"
      :items="data.items"
      :items-length="data.total"
      :items-per-page="params.pageSize"
      :loading="loading"
      :mobile-breakpoint="0"
      :page="params.page"
      class="coordinators-table"
      show-archive
      @update:page="updatePage"
      @update:items-per-page="updatePageSize"
    >
      <template #[`item.name`]="{ item }">
        <user-role :user="item" />
        <router-link
          :to="{ name: 'user-view', params: { id: item.id } }"
          class="text-primary text-decoration-none d-block coordinator-name"
        >
          <mobile-status :user="item"></mobile-status>
          {{ item.name }}
        </router-link>
      </template>
      <template #[`item.phone`]="{ item }">
        <div class="d-flex align-center">
          <phone-number :number="item.phone" class="text-primary text-decoration-none" />
          <span
            class="ml-2 text-uppercase bg-grey-lighten-4 rounded text-grey-darken-1 pr-1 pl-1 caption type-language"
          >
            {{ item.language }}
          </span>
        </div>
      </template>
      <template #[`item.owners`]="{ item }">
        <div v-if="item.owners">
          <router-link
            v-for="owner in item.owners"
            :key="owner.id"
            :to="{ name: 'user-view', params: { id: owner.id } }"
            class="mr-1 text-decoration-none text-primary"
          >
            {{ owner.name }}
          </router-link>
        </div>
      </template>
      <template #[`item.email`]="{ item }">
        <span v-clipboard="item.email" class="text-grey-darken-3">{{ item.email }}</span>
      </template>
      <template #[`item.address`]="{ item }">
        <span class="text-grey-darken-3">
          {{ item.fullAddress }}
        </span>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center">
          <v-btn
            :to="{ name: 'user-view', params: { id: item.id } }"
            class="pa-1 coordinators-table-action"
            size="small"
            variant="flat"
          >
            <v-icon class="text-primary">mdi-eye-outline</v-icon>
          </v-btn>
          <open-chat-button :user="item"></open-chat-button>
        </div>
      </template>
    </omni-table>
  </v-container>
</template>

<style lang="scss" scoped>
.coordinator-name {
  margin-right: 50px;
}

.coordinators-filter-title {
  margin-right: 35px;
  @media (max-width: 1500px) {
    margin-bottom: 20px;
  }
}

.col-long {
  max-width: 330px;
  flex: 0 0 330px;
  padding: 12px;
  @media (max-width: 1400px) {
    max-width: 200px;
    flex: 0 0 200px;
  }
  @media (max-width: 1199px) {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.col-small {
  max-width: 200px;
  flex: 0 0 200px;
  padding: 12px;
  @media (max-width: 1199px) {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.col-medium {
  max-width: 290px;
  flex: 0 0 290px;
  padding: 12px;
  @media (max-width: 1400px) {
    max-width: 200px;
    flex: 0 0 200px;
  }
  @media (max-width: 1199px) {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.type-language {
  line-height: 16px;
}

.coordinators-table-action {
  width: auto !important;
  min-width: auto !important;
  padding: 0 2px !important;
  background: transparent !important;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

@media (max-width: 1199px) {
  .coordinators-filter__right {
    flex-wrap: wrap;
  }
  .coordinators-filter__inputs {
    margin-bottom: 5px;
  }
  .coordinators-filter__buttons {
    flex: 1;
    text-align: right;
  }
}
</style>
