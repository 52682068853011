<script>
import BaseView from "@/views/BaseView.vue";
import OmniTable from "@/components/OmniTable.vue";
import SearchField from "@/components/inputs/SearchField.vue";
import PageHeader from "@/components/PageHeader.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { mapActions, mapState } from "pinia";
import { useAppStore } from "@/store/app.store";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "LogsPage",
  components: { TableFilter, TableFilterRow, PageHeader, SearchField, OmniTable },
  mixins: [BaseView, DateTimeMixin],
  data() {
    return {
      perm: "logs",
      headers: [
        {
          title: this.$t("dispatchers.log-action"),
          key: "action",
          sortable: false,
        },
        {
          title: this.$t("dispatchers.log-entity-id"),
          key: "entityId",
          sortable: false,
        },
        { title: this.$t("dispatchers.dispatcher"), key: "dispatcher.name" },
        { title: this.$t("general.date"), key: "date" },
        {
          title: "",
          key: "actions",
          align: "end",
          width: 58,
          sortable: false,
        },
      ],
      filter: { search: "" },
      items: [],
      loading: true,
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
    rolesMap() {
      const roles = {};
      for (const role of this.roles) {
        roles[role.id] = role.name;
      }
      return roles;
    },
  },
  async created() {
    this.setTitle(this.$t("dispatchers.logs"));

    await this.refresh();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    async refresh() {
      const params = {
        search: this.filter.search,
        pageSize: 100,
      };

      this.loading = true;

      const resp = await this.$api.service.fetchLogs(params);
      if (resp.success) {
        this.items = resp.data;
      } else {
        this.showSnackbarError(resp.error?.message || "Fetching logs failed");
      }

      this.loading = false;
    },
    clear() {
      this.filter.search = "";
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100">
    <page-header header="Logs"></page-header>
    <table-filter :expandable="false" @apply="refresh" @clear="clear">
      <template #header>
        <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
          <v-col cols="12" sm="2">
            <search-field v-model="filter.search" :label="$t('general.search')" @search="refresh" />
          </v-col>
        </table-filter-row>
      </template>
    </table-filter>

    <omni-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :mobile-breakpoint="0"
      :server-data="false"
      hide-default-footer
    >
      <template #[`item.date`]="{ item }">
        {{ dateTZ(item.created, dispatcher.timezone) }}
      </template>
    </omni-table>
  </v-container>
</template>
