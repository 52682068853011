<script>
import { RouteStopStatus, TripStatus } from "@/data/trip";
import { OrderStopType } from "@/data/order";
import OmniDialog from "@/components/Dialog.vue";
import PickupInfoDialog from "@/views/trips/view/dialogs/PickupInfoDialog.vue";
import DeliveryInfoDialog from "@/views/trips/view/dialogs/DeliveryInfoDialog.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "RouteStopActions",
  components: { DeliveryInfoDialog, PickupInfoDialog, OmniDialog },
  inheritAttrs: false,
  props: {
    trip: Object,
    route: Object,
    stop: Object,
  },
  emits: ["change"],
  data() {
    return {
      dialogTitle: "",
      dialogText: "",
      dialogAction: null,
      dialogVisible: false,
      infoVisible: false,
    };
  },
  computed: {
    isTripComplete() {
      return this.trip.status === TripStatus.completed;
    },
    isCheckedIn() {
      return this.stop.status === RouteStopStatus.waitingForLoading;
    },
    isLoaded() {
      return this.stop.status === RouteStopStatus.waitingForVerification && this.isPickUp;
    },
    isLoadedAndVerified() {
      return this.stop.status === RouteStopStatus.waitingForGoodToGo && this.isPickUp;
    },
    isUnloaded() {
      return this.stop.status === RouteStopStatus.waitingForVerification && this.isDelivery;
    },
    isUnloadedAndVerified() {
      return this.stop.status === RouteStopStatus.waitingForGoodToGo && this.isDelivery;
    },
    isComplete() {
      return this.stop.status === RouteStopStatus.completed;
    },
    isLastStop() {
      return this.route.routeStops.slice(-1).pop().id === this.stop.id;
    },
    isPickUp() {
      return this.stop.type === OrderStopType.pickup;
    },
    isDelivery() {
      return this.stop.type === OrderStopType.delivery;
    },
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    showDialog(title, text, action) {
      this.dialogTitle = title;
      this.dialogText = text;
      this.dialogAction = action;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    undoDispatchConfirmation() {
      this.showDialog(
        "Undo dispatch confirmation",
        "Are you sure want to undo dispatch confirmation at this route?",
        async () => {
          const response = await this.$api.trips.cancelDispatchConfirmation(this.trip.id, this.route.id);
          if (response.success) {
            this.$emit("change");
          } else {
            this.showSnackbarError("Error undoing dispatch confirmation");
          }
          this.closeDialog();
        },
      );
    },
    undoCheckIn() {
      this.showDialog("Undo check in", "Are you sure want to undo check in?", async () => {
        const response = await this.$api.trips.cancelCheckIn(this.trip.id, this.stop.id);
        if (response.success) {
          this.$emit("change");
        } else {
          this.showSnackbarError("Error undoing check in");
        }
        this.closeDialog();
      });
    },
    undoLoad() {
      this.showDialog("Undo load", "Are you sure want to undo load on Pick up?", async () => {
        const response = await this.$api.trips.cancelLoad(this.trip.id, this.stop.id);
        if (response.success) {
          this.$emit("change");
        } else {
          this.showSnackbarError("Error undoing load");
        }
        this.closeDialog();
      });
    },
    undoLoadVerification() {
      this.showDialog("Undo verify", "Are you sure want to undo verify information on Pick up?", async () => {
        const response = await this.$api.trips.cancelLoadVerification(this.trip.id, this.stop.id);
        if (response.success) {
          this.$emit("change");
        } else {
          this.showSnackbarError("Error undoing load verification");
        }
        this.closeDialog();
      });
    },
    undoUnload() {
      this.showDialog("Undo unload", "Are you sure want to undo unload on Delivery?", async () => {
        const response = await this.$api.trips.cancelUnload(this.trip.id, this.stop.id);
        if (response.success) {
          this.$emit("change");
        } else {
          this.showSnackbarError("Error undoing unload");
        }
        this.closeDialog();
      });
    },
    undoUnloadVerification() {
      this.showDialog("Undo verify", "Are you sure want to undo verify information on Delivery?", async () => {
        const response = await this.$api.trips.cancelUnloadVerification(this.trip.id, this.stop.id);
        if (response.success) {
          this.$emit("change");
        } else {
          this.showSnackbarError("Error undoing unload verification");
        }
        this.closeDialog();
      });
    },
    undoGoodToGo() {
      this.showDialog("Undo good to go", "Are you sure want to undo check out?", async () => {
        const response = await this.$api.trips.cancelGoodToGo(this.trip.id, this.stop.id);
        if (response.success) {
          this.$emit("change");
        } else {
          this.showSnackbarError("Error undoing good to go");
        }
        this.closeDialog();
      });
    },
    showInfo() {
      this.infoVisible = true;
    },
  },
};
</script>

<template>
  <v-menu offset-y>
    <template #activator="{ props }">
      <v-btn icon="mdi-dots-horizontal" size="x-small" v-bind="{ ...$attrs, ...props }" variant="text" />
    </template>
    <v-list dense>
      <v-list-item v-if="!stop?.id" @click="undoDispatchConfirmation">
        <v-list-item-title>Undo dispatch confirmation</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isCheckedIn" @click="undoCheckIn">
        <v-list-item-title>Undo check in</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isLoaded" @click="undoLoad">
        <v-list-item-title>Undo load</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isLoadedAndVerified" @click="undoLoadVerification">
        <v-list-item-title>Undo load verification</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isUnloaded" @click="undoUnload">
        <v-list-item-title>Undo unload</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isUnloadedAndVerified" @click="undoUnloadVerification">
        <v-list-item-title>Undo unload verification</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="(!isTripComplete && isComplete) || (isTripComplete && isLastStop)" @click="undoGoodToGo">
        <v-list-item-title>Undo good to go</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="isComplete" @click="showInfo">
        <v-list-item-title>View info</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

  <omni-dialog
    v-model="dialogVisible"
    :title="dialogTitle"
    primary-action-label="Apply"
    size="small"
    @primary-action:click="dialogAction"
  >
    <div class="text-grey-darken-1">
      {{ dialogText }}
    </div>
  </omni-dialog>

  <pickup-info-dialog
    v-if="isPickUp"
    v-model="infoVisible"
    :route="route"
    :stop="stop"
    :trip="trip"
    preload
    readonly
    title="Pick up information"
  ></pickup-info-dialog>
  <delivery-info-dialog
    v-if="isDelivery"
    v-model="infoVisible"
    :route="route"
    :stop="stop"
    :trip="trip"
    preload
    readonly
    title="Delivery information"
  ></delivery-info-dialog>
</template>

<style lang="scss" scoped></style>
