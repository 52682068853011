<script>
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import BaseView from "@/views/BaseView.vue";
import ValidationMixin from "@/mixins/validation.mixin";

export default {
  name: "LocationPicker",
  mixins: [GoogleMapsMixin, BaseView, ValidationMixin],
  props: {
    modelValue: Object,
    disabled: Boolean,
    hideCoordinates: Boolean,
    required: Boolean,
    qaAttr: String,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      model: null,
      suggestions: [],
      isLoading: false,
      search: "",
      skipSearch: false,
    };
  },
  watch: {
    async search(query) {
      if (this.skipSearch) {
        this.skipSearch = false;
        return;
      }
      if (!query || query.length < 2) {
        this.suggestions = [];
        return;
      }

      this.isLoading = true;
      try {
        const response = await this.$api.geo.searchGeneral(query);
        if (!response.success) return;
        this.suggestions = response.data.map((suggestion) => {
          suggestion.lng = suggestion.lon;
          suggestion.name = suggestion.fullAddress;
          return suggestion;
        });
      } catch (error) {
        console.error("Error load locations list: ", error);
      } finally {
        this.isLoading = false;
      }
    },
    model(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue() {
      if (this.modelValue) {
        this.skipSearch = true;
        this.model = this.modelValue;
        this.suggestions = [this.modelValue];
      }
    },
  },
};
</script>

<template>
  <div>
    <v-autocomplete
      v-model="model"
      v-model:search="search"
      :class="{ required: required }"
      :data-qa="qaAttr"
      :disabled="disabled"
      :items="suggestions"
      :loading="isLoading"
      :rules="required ? [requiredValidator] : []"
      class="_v-location-picker"
      color="primary"
      density="compact"
      hide-details="auto"
      item-title="fullAddress"
      item-value="fullAddress"
      label="Location"
      no-filter
      placeholder="Start typing to Search"
      return-object
      v-bind="$props"
      variant="outlined"
    />
    <div v-if="!hideCoordinates && model?.lat && model?.lng" class="_v-location-picker__location mt-3">
      <v-icon color="green" size="small">mdi-map-marker-radius-outline</v-icon>
      <a :href="getPlaceUrl(model)" class="text-decoration-underline text-green" target="_blank">
        Lat: {{ model.lat }} Lon: {{ model.lng }}
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
._v-location-picker {
  &__location {
    font-size: 11px;
  }
}
</style>
