<script>
export default {
  name: "OwnerCompany",
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="light pt-4 pb-4 pr-5 pl-5 rounded">
    <div class="text-grey-darken-3 font-weight-medium mb-3" data-qa="owner-info-company-name">
      {{ company.name }}
    </div>
    <div class="d-flex mb-4 align-center">
      <v-icon class="mr-2 text-grey-darken-2" small> mdi-map-marker-radius-outline</v-icon>
      <span class="text-grey-darken-1 font-size-12" data-qa="owner-info-company-address">
        {{ company.addressCity }}, {{ company.addressState }} {{ company.addressZipCode }}, US
      </span>
    </div>
    <div class="d-flex flex-wrap mb-3">
      <span class="text-grey-darken-1 font-size-12 mr-5">Doc. number</span>
      <span class="text-grey-darken-3" data-qa="owner-info-document-number">
        {{ $filters.upper(company.documentType) }} {{ company.documentNumber }}
      </span>
    </div>
    <div class="d-flex flex-wrap">
      <span class="text-grey-darken-1 font-size-12 mr-5">Insurance exp.</span>
      <span class="text-grey-darken-3" data-qa="owner-info-insurance-exp">
        {{ $filters.date(company.insuranceExpireDate) }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
