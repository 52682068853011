<script>
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import BaseView from "@/views/BaseView.vue";
import UserTruck from "@/views/chats/user-trucks/UserTruck.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "ChatUserTrucks",
  components: { UserTruck },
  mixins: [BaseView, DateTimeMixin, TrucksMixin],
  props: {
    user: Object,
    initialTrucks: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      trucks: [],
      trucksFiltered: [],
      numberSearchTerm: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
  },
  watch: {
    async user() {
      await this.loadTrucks();
    },
    initialTrucks() {
      this.trucks = this.initialTrucks;
      this.applyFilters();
    },
    numberSearchTerm() {
      this.applyFilters();
    },
  },
  async mounted() {
    await this.loadTrucks();
  },
  methods: {
    async loadTrucks() {
      if (this.initialTrucks) {
        this.trucks = this.initialTrucks;
        this.applyFilters();
        return;
      }
      if (!this.user.id) return;

      const params = {};
      if (this.user.isOwner) {
        params.owner = this.user.id;
      } else if (this.user.isCoordinator) {
        params.owner = this.user.ownerId;
      }
      if (this.user.trucks && this.user.trucks.length === 0) {
        return;
      }
      params.pageSize = this.user.trucks ? this.user.trucks.length : 100;

      try {
        this.isLoading = true;
        const resp = await this.$api.trucks.findTrucks(params, false);
        if (resp.success) {
          this.trucks = resp.data.items;
          this.applyFilters();
        }
      } catch (e) {
        console.error("Error loading trucks", e);
      } finally {
        this.isLoading = false;
      }
    },
    applyFilters() {
      this.trucksFiltered = this.trucks.filter((t) =>
        t.number.toLowerCase().includes(this.numberSearchTerm.toLowerCase()),
      );
    },
  },
};
</script>

<template>
  <div class="mb-3">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-title
          :readonly="trucks.length === 0"
          class="pa-2"
          collapse-icon="mdi-menu-up"
          expand-icon="mdi-menu-down"
          height="38"
        >
          <template #default="{ props }">
            <div class="trucks-dropdown__btn width-100 d-block" v-bind="props">
              <div class="d-flex align-center justify-space-between width-100">
                <div class="d-flex align-center">
                  <span class="user-contact__icon mr-2 d-flex align-center justify-center">
                    <v-icon class="text-grey-darken-2" size="16">mdi-truck-outline</v-icon>
                  </span>

                  <span class="font-size-12 mr-2 text-grey-darken-2">{{ $t("trucks.trucks") }}:</span>
                  <span class="font-size-12 font-weight-600 text-grey-darken-2">{{ trucks.length }} </span>
                </div>
              </div>
              <v-progress-linear v-if="isLoading" :indeterminate="true" color="primary"></v-progress-linear>
            </div>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-list class="pt-6 pb-6 pl-4 pr-4">
            <v-text-field
              v-model="numberSearchTerm"
              color="primary"
              density="compact"
              label="Truck number"
              variant="outlined"
            />
            <v-list-item v-for="truck in trucksFiltered" :key="truck.id" class="bg-grey-lighten-5 pa-3 rounded-lg">
              <user-truck :truck="truck"></user-truck>
            </v-list-item>
          </v-list>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<style lang="scss" scoped>
.trucks-dropdown__btn {
  height: auto !important;
  min-width: auto !important;
  padding: 0 !important;

  &:before {
    display: none;
  }
}

.v-list {
  // padding: 6px 8px !important;
  box-shadow: none !important;
  border-radius: 0 0 4px 4px;
}

.v-list-item {
  border: 1px solid #e0e0e0;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.v-menu__content {
  box-shadow: 0 10px 20px rgba(18, 38, 63, 0.04) !important;
  border-radius: 4px;
}

.user-contact__icon {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  cursor: pointer;
}

.content {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.16);
}

.v-expansion-panel {
  :deep(.v-expansion-panel-title) {
    min-height: 38px;
  }

  :deep(.v-expansion-panel-title__icon) {
    font-size: 10px;
    color: #757575;
  }

  :deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
  }

  :deep(.v-expansion-panel__shadow) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.16);
  }
}

:deep(.v-expansion-panel--active > .v-expansion-panel-title) {
  min-height: 38px;
}

.driver-switch-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-item {
  font-size: 9px;
  width: 17px;
  height: 17px;
}

.truck-driver__info:not(:last-child) {
  margin-bottom: 12px;
}
</style>
