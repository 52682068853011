const FileMixin = {
  methods: {
    getObjectUrl(file: File): string {
      return URL.createObjectURL(file);
    },
    isPdf(file: File): boolean {
      return file.type === "application/pdf";
    },
    isImage(file: File): boolean {
      const imageFileTypes: string[] = ["image/jpeg", "image/png"];
      return imageFileTypes.indexOf(file.type) > -1;
    },
    isImageUrl(url: string): boolean {
      const imageExtensions: string[] = ["jpg", "jpeg", "gif", "png", "bmp"];
      return this.isUrlOfExtensions(url, imageExtensions);
    },
    isPdfUrl(url: string): boolean {
      const pdfExtensions: string[] = ["pdf"];
      return this.isUrlOfExtensions(url, pdfExtensions);
    },
    isUrlOfExtensions(url: string, extensions: string[]): boolean {
      const regexp = new RegExp("^.+\\.(" + extensions.join("|") + ")$");
      return regexp.test(url);
    },
    downloadUrl(url: string): void {
      const link = document.createElement("a");
      link.href = url + "?download=true";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    dataURLtoFile(data: string, filename: string): File {
      const arr = data.split(","),
        mime = (arr[0].match(/:(.*?);/) || [])[1],
        bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    detectMimeType(base64: string): string | undefined {
      const chunks = base64.split(",");
      base64 = chunks[1] || chunks[0];
      const signatures: Record<string, string> = {
        JVBERi0: "application/pdf",
        R0lGODdh: "image/gif",
        R0lGODlh: "image/gif",
        iVBORw0KGgo: "image/png",
        "/9j/": "image/jpg",
      };
      for (const s in signatures) {
        if (base64.indexOf(s) === 0) {
          return signatures[s];
        }
      }
    },
  },
};

export default FileMixin;
