import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";
import type { App } from "vue";

export default {
  install(app: App) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(advancedFormat);
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    dayjs.extend(customParseFormat);
    app.config.globalProperties.$dayjs = dayjs;
  },
};
