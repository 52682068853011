<script>
import ValidationMixin from "@/mixins/validation.mixin";
import OmniForm from "@/components/form/OmniForm.vue";
import FormHeader from "@/components/form/FormHeader.vue";
import FormBlock from "@/components/form/FormBlock.vue";
import FormActions from "@/components/form/FormActions.vue";
import BaseView from "@/views/BaseView.vue";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import SelectFacilityType from "@/views/companies/facilities/components/SelectFacilityType.vue";
import FacilityAddressForm from "@/views/companies/facilities/components/FacilityAddressForm.vue";
import DriverLocationBlock from "@/views/companies/facilities/components/DriverLocationBlock.vue";
import CalculatingLocationBlock from "@/views/companies/facilities/components/CalculatingLocationBlock.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "FacilityEditForm",
  components: {
    CalculatingLocationBlock,
    DriverLocationBlock,
    FacilityAddressForm,
    SelectFacilityType,
    OmniTextarea,
    FormActions,
    FormBlock,
    FormHeader,
    OmniForm,
  },
  mixins: [BaseView, ValidationMixin],
  props: {
    facility: Object,
    modal: Boolean,
  },
  emits: ["close", "change"],
  data() {
    return {
      item: {},
      driverLocationState: null,
      calculatingLocationState: null,
    };
  },
  computed: {
    formTitle() {
      return this.item?.id ? `Edit facility ${this.item.name}` : "Create facility";
    },
  },
  watch: {
    facility(value) {
      this.item = { ...value };
    },
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    async save() {
      this.item.$locationMatchError = false;
      const result = await this.$refs.form.validate();
      if (!result.valid) {
        this.scrollToError();
        return;
      }

      if (
        this.driverLocationState &&
        this.calculatingLocationState &&
        this.driverLocationState !== this.calculatingLocationState
      ) {
        this.item.$locationMatchError = true;
        return;
      }

      const itemToSave = { ...this.item };

      const resp = await this.$api.company.upsertFacility(itemToSave);
      if (resp.success) {
        this.$emit("change", resp.data);
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
    goBack() {
      this.$emit("close");
    },
    updateDriverLocationState(state) {
      this.driverLocationState = state;
    },
    updateCalculatingLocationState(state) {
      this.calculatingLocationState = state;
    },
  },
};
</script>

<template>
  <omni-form ref="form" :flat="modal">
    <form-header v-if="!modal" :title="formTitle"></form-header>
    <form-block :flat="modal" title="Facility information">
      <v-row>
        <v-col lg="6">
          <v-text-field
            v-model="item.name"
            :rules="[requiredValidator]"
            autocomplete="off1"
            class="required"
            color="primary"
            data-qa="facility-name"
            density="compact"
            hide-details="auto"
            label="Name"
            variant="outlined"
          />
        </v-col>
        <v-col lg="6">
          <select-facility-type v-model="item.type" data-qa="facility-type" required></select-facility-type>
        </v-col>
      </v-row>
    </form-block>
    <form-block :flat="modal" title="Address">
      <facility-address-form v-model="item" :modal="modal"></facility-address-form>
    </form-block>
    <form-block :flat="modal">
      <v-row>
        <v-col>
          <omni-textarea v-model="item.note" data-qa="notes" label="Facility note" rows="4"></omni-textarea>
        </v-col>
      </v-row>
    </form-block>
    <v-row class="mb-5">
      <v-col>
        <driver-location-block :facility="item" @update:state="updateDriverLocationState"></driver-location-block>
      </v-col>
      <v-col>
        <calculating-location-block
          :facility="item"
          @update:state="updateCalculatingLocationState"
        ></calculating-location-block>
      </v-col>
    </v-row>
    <form-actions @cancel="goBack" @submit="save"></form-actions>
  </omni-form>
</template>
