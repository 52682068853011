<script>
export default {
  name: "UsersMixin",
  data() {
    const data = {
      ownerStatusActive: "a",
      ownerStatusNONOwner: "u",
      ownerStatusOnHold: "h",
      driverStatusActive: "a",
      driverStatusNotActive: "n",
      driverStatusOnHold: "h",
      driverStatusOnSilentHold: "s",
    };
    return {
      ...data,
      citizenships: [
        { id: "c", name: "Citizen" },
        { id: "g", name: "Green card" },
        { id: "e", name: "Employment Auth" },
        { id: "n", name: "Not legal" },
      ],
      licenseTypes: [
        { id: "s", name: this.$t("drivers.license-s") },
        { id: "c", name: this.$t("drivers.license-c") },
      ],
      driverCertificates: [
        { id: "hazmat", name: "Hazmat" },
        { id: "tsa", name: "TSA" },
        { id: "twic", name: "TWIC" },
        { id: "tanker_endorsement", name: "Tanker Endorsement" },
      ],
      driverWillingsToCrossBorder: [
        { id: "c", name: "Canada" },
        { id: "m", name: "Mexico" },
      ],
      driverStatuses: [
        { id: data.driverStatusActive, name: this.$t("drivers.status-a") },
        { id: data.driverStatusNotActive, name: this.$t("drivers.status-n") },
        { id: data.driverStatusOnHold, name: this.$t("drivers.status-h") },
        {
          id: data.driverStatusOnSilentHold,
          name: this.$t("drivers.status-s"),
        },
      ],
      ownerStatuses: [
        { id: "a", name: this.$t("drivers.status-a") },
        { id: "n", name: this.$t("drivers.status-n") },
        { id: "h", name: this.$t("drivers.status-h") },
      ],
      languages: [
        { id: "en", name: "English" },
        { id: "es", name: "Spanish" },
        { id: "uk", name: "Ukrainian" },
        { id: "ru", name: "Russian" },
      ],
    };
  },
  methods: {
    getUserTypes(user, shortNames = false) {
      if (!user) return "";

      const types = [];
      if (user.isOwner) types.push(shortNames ? "O" : this.$t("drivers.owner"));
      if (user.isDriver) types.push(shortNames ? "D" : this.$t("drivers.driver"));
      if (user.isCoordinator) types.push(shortNames ? "C" : this.$t("drivers.coordinator"));
      return types.join("/");
    },
  },
};
</script>
