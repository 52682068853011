<script>
import { OrderStopType } from "@/data/order";

export default {
  name: "RouteStopFreightDetails",
  props: {
    freights: Array,
    stop: Object,
  },
  computed: {
    showDiff() {
      return this.stop.type === OrderStopType.pickup && (this.stop.loadUnloadTime || this.stop.verifyTime);
    },
    plannedQuantity() {
      return this.freights.reduce((sum, f) => sum + f.plannedQuantity, 0);
    },
    plannedWeight() {
      return this.freights.reduce((sum, f) => sum + f.plannedWeight, 0);
    },
    quantity() {
      return this.freights.reduce((sum, f) => sum + f.quantity, 0);
    },
    weight() {
      return this.freights.reduce((sum, f) => sum + f.weight, 0);
    },
    quantityClass() {
      if (this.quantity > this.plannedQuantity) {
        return "text-error";
      } else if (this.quantity < this.plannedQuantity) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
    weightClass() {
      if (this.weight > this.plannedWeight) {
        return "text-error";
      } else if (this.weight < this.plannedWeight) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
  },
};
</script>

<template>
  <div class="d-flex align-center mt-6 text-grey-darken-2 font-weight-500">
    <svg height="20" width="20">
      <use xlink:href="@/assets/images/pallet.svg#pallet"></use>
    </svg>
    <div class="ml-2">
      <template v-if="showDiff"
        >{{ plannedQuantity }} /
        <span :class="quantityClass" data-qa="rote-stops-actual-pallers">{{ quantity }}</span> pallets
      </template>
      <template v-else>{{ plannedQuantity }} pallets</template>
    </div>
    <svg class="ml-4" height="20" width="20">
      <use xlink:href="@/assets/images/weight.svg#weight"></use>
    </svg>
    <div class="ml-2">
      <template v-if="showDiff"
        >{{ plannedWeight }} / <span :class="weightClass">{{ weight }}</span> lbs
      </template>
      <template v-else>{{ plannedWeight }} lbs</template>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
