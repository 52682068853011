<script>
import draggable from "vuedraggable";
import OrdersMixin from "@/views/orders/OrdersMixin.vue";

export default {
  name: "ReorderOrderStops",
  components: { draggable },
  mixins: [OrdersMixin],
  props: {
    orders: Array,
    modelValue: Array,
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      colors: ["indigo accent-2", "light-green"],
      sstops: [],
    };
  },
  computed: {
    stops: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
  },
};
</script>

<template>
  <div class="py-4">
    <div class="stops-order__info d-flex align-center flex-wrap mb-6">
      <div v-for="order in orders" :key="order.id" class="stops-order__info-block d-flex align-center mr-4">
        <span class="stops-order__info-color rounded mr-2 d-block" />
        <span class="text-grey-darken-3 font-size-12 font-weight-medium flex-none"> Order #{{ order.number }} </span>
      </div>
    </div>
    <div class="stops-order__blocks">
      <draggable v-model="stops">
        <template #item="{ element }">
          <div class="stops-order__block mb-6">
            <div class="stops-order__block-inner border-blue pa-4 bg-grey-lighten-4 rounded">
              <div class="block-header d-flex align-center justify-space-between mb-6">
                <div class="block-header__left d-flex align-center flex-wrap">
                  <v-icon v-if="element.type === STOP_PICK_UP" class="mr-2" color="green" size="16">
                    mdi-package-variant-closed
                  </v-icon>
                  <v-icon v-if="element.type === STOP_DELIVERY" class="mr-2" color="red accent-2" size="16">
                    mdi-map-marker-radius-outline
                  </v-icon>
                  <span class="font-weight-600 blue-text-grey-darken-1 mr-3">{{ element.name }}</span>
                  <span class="font-weight-medium text-primary">Order #{{ element.order.number }}</span>
                </div>
                <div class="block-header__right">
                  <v-icon class="text-grey-darken-2 cursor-pointer" size="20">mdi-drag</v-icon>
                </div>
              </div>
              <div class="mb-6">
                <div class="text-primary mb-2 text-uppercase font-weight-medium">{{ element.facility.name }}</div>
                <div class="body-1 text-grey-darken-2">{{ element.facility.address }}</div>
              </div>
              <div class="d-flex align-center mb-6">
                <v-icon class="mr-2 text-grey-darken-1" size="17">mdi-clock-outline</v-icon>
                <span class="body-2 text-grey-darken-1 font-weight-medium">
                  {{ timeTypesMap[element.timeType] }}: {{ getStopTime(element) }}
                </span>
              </div>
              <div v-if="element.type === STOP_PICK_UP" class="d-flex align-center flex-wrap">
                <div class="d-flex align-center mr-4">
                  <span class="mr-2 line-height-1">
                    <svg height="20" width="20">
                      <use xlink:href="@/assets/images/pallet.svg#pallet"></use>
                    </svg>
                  </span>
                  <span class="text-grey-darken-2 body-1 font-weight-medium">11 pieces</span>
                </div>
                <div class="d-flex align-center mr-4">
                  <span class="mr-2 line-height-1">
                    <svg height="20" width="20">
                      <use xlink:href="@/assets/images/weight.svg#weight"></use>
                    </svg>
                  </span>
                  <span class="text-grey-darken-2 body-1 font-weight-medium">1082 lbs</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>
