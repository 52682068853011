<script>
import PhoneNumber from "@/components/PhoneNumber.vue";
import OpenChatButton from "@/components/controls/OpenChatButton.vue";
import BaseView from "@/views/BaseView.vue";
import UserTruck from "@/views/chats/user-trucks/UserTruck.vue";
import LanguageTag from "@/components/status/LanguageTag.vue";
import SidebarUserBlock from "@/views/users/components/view/SidebarUserBlock.vue";

export default {
  name: "UserViewSidebar",
  components: { SidebarUserBlock, LanguageTag, UserTruck, OpenChatButton, PhoneNumber },
  mixins: [BaseView],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactPerson: {},
      truck: null,
    };
  },
  watch: {
    async user() {
      if (this.user.isDriver) {
        const resp = await this.$api.trucks.findTrucks({ driver: this.user.id });
        if (resp.success && resp.data.items.length > 0) {
          this.truck = resp.data.items[0];
        }
      }

      // calculate contact person
      const contactPersonId = this.user.contactPersonId;
      switch (this.user.contactPersonId) {
        case this.user.id:
          this.contactPerson = { ...this.user };
          break;
        case this.user.owner?.id:
          this.contactPerson = { ...this.user.owner };
          break;
        default: {
          // load contact person user data
          if (contactPersonId) {
            const resp = await this.$api.users.findUserById(contactPersonId);
            if (resp.success) {
              this.contactPerson = resp.data;
            }
          }
        }
      }
    },
  },
};
</script>

<template>
  <div class="pt-5 pr-5 pb-5 pl-5 bg-white rounded">
    <div class="text-grey-darken-3 mb-6 font-weight-medium">Personal information</div>
    <div class="d-flex align-center mb-5">
      <v-avatar :color="avatarColor(user)" class="mr-3" size="60">
        <span class="text-white font-weight-medium text-h5 grey">
          {{ $filters.initials(user.name) }}
        </span>
      </v-avatar>
      <div>
        <div class="title mb-2 font-weight-medium d-flex align-center font-size-24" data-qa="sidebar-user-name">
          <span
            v-if="user.id === user.contactPersonId"
            :class="{ 'bg-grey-darken-2': user.archived }"
            class="currency-icon mr-2"
          />
          {{ user.name }}
        </div>
        <span
          v-if="user.isDriver"
          :class="{ 'bg-grey-darken-2': user.archived }"
          class="bg-indigo-lighten-2 text-white text-uppercase font-size-12 pr-2 pl-2 font-weight-600 rounded-pill user-page__label mr-2"
          data-qa="sidebar-driver-label"
        >
          {{ $t("drivers.driver") }}
        </span>
        <span
          v-if="user.isOwner"
          :class="{ 'bg-grey-darken-2': user.archived }"
          class="bg-green-lighten-1 text-white text-uppercase font-size-12 pr-2 pl-2 font-weight-600 rounded-pill user-page__label mr-2"
          data-qa="sidebar-owner-label"
        >
          {{ $t("drivers.owner") }}
        </span>
        <span
          v-if="user.isCoordinator"
          :class="{ 'bg-grey-darken-2': user.archived }"
          class="bg-blue-lighten-2 text-white text-uppercase font-size-12 pr-2 pl-2 font-weight-600 rounded-pill user-page__label"
          data-qa="sidebar-coordinator-label"
        >
          {{ $t("drivers.coordinator") }}
        </span>
      </div>
    </div>
    <div v-if="user.archived" class="d-flex align-center px-3 py-2 bg-indigo-lighten-5 rounded text-grey-darken-3">
      <v-icon class="mr-2">mdi-archive-outline</v-icon>
      <div>Archived</div>
    </div>

    <div class="user-info__blocks mb-7">
      <div v-if="truck" class="user-info__block d-flex align-start pt-3 pb-3">
        <div class="truck--block bg-grey-lighten-5 pa-3 rounded-lg w-100">
          <user-truck :truck="truck" :user="user"></user-truck>
        </div>
      </div>
      <div class="user-info__block d-flex align-start pt-3 pb-3">
        <v-icon class="mr-8" color="primary"> mdi-phone-outline</v-icon>
        <div class="user-info__block-content">
          <div class="user-info__block-line">
            <div class="d-flex align-center mb-2">
              <phone-number
                :number="user.mobilePhone"
                class="text-primary text-body-2"
                data-qa="sidebar-mobile-phone-1"
                is-callable
              />
              <language-tag :language="user.language" class="ml-2"></language-tag>
            </div>
            <div class="text-grey-darken-1 caption">Mobile phone 1</div>
          </div>
          <div v-if="user.mobilePhone2" class="user-info__block-line">
            <div class="d-flex align-center mb-2">
              <phone-number
                :number="user.mobilePhone2"
                class="text-primary text-body-2"
                data-qa="sidebar-mobile-phone-2"
                is-callable
              />
            </div>
            <div class="text-grey-darken-1 caption">Mobile phone 2</div>
          </div>
          <div v-if="user.homePhone" class="user-info__block-line">
            <div class="d-flex align-center mb-2">
              <phone-number :number="user.homePhone" class="text-primary text-body-2" is-callable />
            </div>
            <div class="text-grey-darken-1 caption">Home phone</div>
          </div>
        </div>
      </div>

      <div class="user-info__block d-flex align-start pt-3 pb-3">
        <v-icon class="mr-8" color="primary"> mdi-email-outline</v-icon>
        <div class="user-info__block-content">
          <a v-clipboard="user.email" class="text-body-2 text-primary text-decoration-none" data-qa="sidebar-email">
            {{ user.email }}
          </a>
        </div>
      </div>

      <div class="user-info__block d-flex align-center pt-3 pb-3">
        <v-icon class="mr-8" color="primary"> mdi-home-outline</v-icon>
        <div class="user-info__block-content">
          <div class="text-grey-darken-3 mb-2">{{ user.addressLine }}</div>
          <div class="text-grey-darken-1 font-size-12" data-qa="sidebar-user-address-1">
            {{ user.addressCity }} {{ user.addressState }}, {{ user.addressZipCode }}, {{ user.addressCountry }}
          </div>
        </div>
      </div>

      <div class="user-info__block d-flex align-center pt-3 pb-3">
        <v-icon class="mr-8" color="primary"> mdi-cake-variant-outline</v-icon>
        <div class="user-info__block-content">
          <div class="text-grey-darken-3 mb-2" data-qa="sidebar-user-birthday">{{ $filters.date(user.birthday) }}</div>
          <div class="text-grey-darken-1 font-size-11">Date of birthday</div>
        </div>
      </div>

      <sidebar-user-block
        :is-contact-person="user.contactPersonId === user.ownerId"
        :user="user.owner"
        label="Owner"
      ></sidebar-user-block>

      <sidebar-user-block
        v-if="contactPerson"
        :is-contact-person="user.contactPersonId === contactPerson.id"
        :user="contactPerson"
        label="Contact Person"
      ></sidebar-user-block>

      <sidebar-user-block
        v-for="coordinator in user.coordinators"
        :key="coordinator.userId"
        :full-access="coordinator.fullAccess"
        :is-contact-person="user.contactPersonId === coordinator.userId"
        :user="coordinator.user"
        label="Coordinator"
      ></sidebar-user-block>

      <div v-if="user.emergencyContactPhone" class="user-info__block d-flex align-center pt-5 pb-5">
        <div class="user-info__block-content">
          <div class="text-grey-darken-3 mb-2">
            {{ user.emergencyContactFullName }}
            <span v-if="user.emergencyContactRelation">({{ user.emergencyContactRelation }})</span>
          </div>
          <phone-number :number="user.emergencyContactPhone" class="text-primary mb-2 d-block" is-callable />
          <div class="mb-2 text-grey-darken-1 font-size-12">Emergency contact</div>
        </div>
      </div>
    </div>

    <div class="user-actions">
      <open-chat-button :user="user" type="button"></open-chat-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-info__block:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

.truck--block {
  border: 1px solid #e0e0e0;
}
</style>
