<script>
import BaseView from "@/views/BaseView.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import { MasksMixin } from "@/mixins/masks.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import UserFormInfo from "@/views/users/form/UserFormInfo.vue";
import UserFormDriver from "@/views/users/form/UserFormDriver.vue";
import UserFormOwner from "@/views/users/form/UserFormOwner.vue";
import UserFormCoordinator from "@/views/users/form/UserFormCoordinator.vue";
import { mapActions, mapState } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";
import { useAppStore } from "@/store/app.store";

export default {
  name: "UserEditForm",
  components: {
    UserFormCoordinator,
    UserFormOwner,
    UserFormDriver,
    UserFormInfo,
  },
  mixins: [BaseView, UsersMixin, MasksMixin, ValidationMixin],
  props: {
    user: Object,
  },
  data() {
    return {
      isNewCompany: false,
      item: {
        id: 0,
        companies: [],
        coordinators: [],
        driverId: null,
        addressCountry: "US",
        addressState: "",
        addressCity: "",
        driverStatus: "",
        ownerStatus: "",
      },
      tab: 0,
      emailError: "",
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
  },
  watch: {
    user() {
      this.processUser();
    },
    dispatcher() {
      this.processUser();
    },
  },
  beforeMount() {
    this.processUser();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError", "showSnackbarSuccess"]),
    processUser() {
      this.item = { ...this.user };
      if (!this.item.id) {
        this.item.hiredById = this.dispatcher?.id;
        this.item.score = 0;
      }

      if (this.item.companies) {
        this.item.companies = this.item.companies.map((c) => {
          c.changed = false;
          return c;
        });
      }
    },
    async save() {
      const result = await this.$refs.form.validate();
      if (!result.valid) {
        this.scrollToError();
        return;
      }
      if (!this.item.isOwner && !this.item.isDriver && !this.item.isCoordinator) {
        this.showSnackbarError("User doesn't have any roles. Please select at least one role");
        return;
      }
      const item = { ...this.item };

      item.name = item.name?.trim();
      item.addressLine = item.addressLine?.trim();
      item.addressCity = item.addressCity?.trim();
      item.licenseClass = item.licenseClass?.trim();
      item.licenseNumber = item.licenseNumber?.trim();

      if (!this.item.isOwner) item.ownerStatus = "u"; // non-owner status
      if (!this.item.isDriver) item.driverId = null;
      if (item.mobilePhone) item.mobilePhone = this.normalizePhone(item.mobilePhone);
      if (item.mobilePhone2) item.mobilePhone2 = this.normalizePhone(item.mobilePhone2);
      if (item.homePhone) item.homePhone = this.normalizePhone(item.homePhone);
      if (item.emergencyContactPhone) {
        item.emergencyContactPhone = this.normalizePhone(item.emergencyContactPhone);
      }

      const resp = this.item.isDriver
        ? await this.$api.users.upsertDriver(item)
        : await this.$api.users.upsertUser(item);
      if (resp.success) {
        const userId = resp.data.id;

        // save owner companies
        item.companies.forEach((company) => this.saveCompany(company, userId));

        this.showSnackbarSuccess(this.$t("general.saved"));
        return this.$router.push({
          name: "user-view",
          params: { id: userId },
        });
      } else {
        this.showSnackbarError(resp.data.detail);
      }
    },
    async saveCompany(company, userId) {
      company.ownerId = userId;
      const resp = await this.$api.users.upsertCompany(userId, company);
      if (!resp.success) {
        this.showSnackbarError(resp.data.detail);
      }
    },
  },
};
</script>

<template>
  <v-form ref="form" lazy-validation>
    <div class="about-user">
      <user-form-info v-model="item"></user-form-info>
      <user-form-driver v-model="item"></user-form-driver>
      <user-form-owner v-model="item"></user-form-owner>
      <user-form-coordinator v-model="item"></user-form-coordinator>
    </div>

    <div class="d-flex justify-end pa-5 bg-white user-info__buttons">
      <v-btn
        id="cancel-btn"
        class="mr-3 text-primary text-uppercase font-weight-600"
        height="36"
        variant="flat"
        @click="$router.back()"
      >
        {{ $t("general.cancel") }}
      </v-btn>
      <v-btn
        id="save-btn"
        class="pr-6 pl-6 font-weight-600 text-uppercase"
        color="primary"
        height="36"
        variant="flat"
        @click="save"
      >
        {{ $t("general.save") }}
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.about-user {
  padding-bottom: 43px;
}

.user-info__buttons {
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  box-shadow: 0px -8px 20px -7px rgba(0, 0, 0, 0.1);
}

.vertical_collpsed {
  .user-info__buttons {
    width: 100%;
  }
}
</style>
