<script lang="ts" setup>
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import type { CoordinatesDto } from "@/api/dto/coordinates.dto";

defineProps<{
  coordinates: CoordinatesDto;
}>();

const { getPlaceUrl } = GoogleMapsMixin.methods;
</script>

<template>
  <a
    :href="getPlaceUrl(coordinates)"
    class="d-flex mx-2 align-center text-decoration-none text-success"
    target="_blank"
  >
    <v-icon size="16">mdi-map-marker-radius-outline</v-icon>
  </a>
</template>

<style lang="scss" scoped></style>
