<script>
import BaseView from "@/views/BaseView.vue";
import ChatMixin from "@/views/chats/ChatMixin.vue";
import FileMixin from "@/mixins/file.mixin";

export default {
  name: "ChatMessage",
  mixins: [BaseView, ChatMixin, FileMixin],
  props: {
    message: Object,
    thread: Object,
  },
  emits: ["media"],
  computed: {
    avatar() {
      return null;
    },
    name() {
      if (this.message.dispatcher) {
        return this.message.dispatcher.name;
      }
      return this.thread.name;
    },
    isSystemMessage() {
      return this.message.type.startsWith("system_");
    },
    getSystemMessageColor() {
      switch (this.message.type) {
        case "system_success":
          return "success";
        case "system_warning":
          return "warning";
        case "system_error":
          return "error";
        default:
          return "grey-darken-2";
      }
    },
    getSystemMessageIcon() {
      switch (this.message.type) {
        case "system_success":
          return "mdi-check-circle-outline";
        case "system_warning":
          return "mdi-alert-circle-outline";
        case "system_error":
          return "mdi-alert-circle-outline";
        default:
          return null;
      }
    },
  },
  methods: {
    adaptMessage(text) {
      // escape any html tags
      text = text.replaceAll(/&/g, "&amp;").replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt;");

      // linkify links
      text = text.replaceAll(/(https?:\/\/\S+)/g, '<a href="$1" target="_blank">$1</a>');

      // add new lines
      text = text.replaceAll("\n", "<br />");

      return text;
    },
    fileClick() {
      this.$emit("media", this.message.id);
    },
  },
};
</script>

<template>
  <div :data-id="message.id" class="message-block">
    <div v-if="isSystemMessage" class="mb-10 alert-block">
      <div :class="'text-' + getSystemMessageColor" class="d-flex justify-center align-center">
        <v-icon v-if="getSystemMessageIcon" :icon="getSystemMessageIcon" class="mr-2"></v-icon>
        <div>
          {{ message.text }}
        </div>
        <div class="text-grey-darken-1 ml-3 white-space-nowrap">
          {{ showMessageTime(message.created) }}
        </div>
      </div>
    </div>

    <div v-else :class="{ user: message.dispatcherId }" class="message-block__inner">
      <div
        v-if="message.dispatcherId"
        class="message-block__user-name mb-1 text-grey-darken-3 font-weight-medium text-caption"
      >
        {{ name }}
      </div>
      <div
        v-if="message.type === 't'"
        class="message-block__text pa-3 pl-4 mb-2 text-grey-darken-3 d-inline-flex flex-wrap justify-end"
      >
        <span class="message-block__text-content" v-html="adaptMessage(message.text)" />
        <span class="message-block__time">
          {{ showMessageTime(message.created) }}
          <span v-if="message.dispatcherId">
            <v-icon v-if="message.read" class="ml-2" color="success" size="small">mdi-check-all</v-icon>
            <v-icon v-else class="ml-2" color="success" size="small">mdi-check</v-icon>
          </span>
        </span>
      </div>
      <div v-if="message.type === 'i'" class="message-block__text pa-3 pl-4 mb-2 text-grey-darken-1">
        <v-row class="d-flex">
          <v-col style="flex: 0 0 100px" @click="fileClick">
            <img
              :height="90"
              :src="message.previewUrl"
              :width="90"
              alt="Image preview"
              class="rounded d-block"
              style="cursor: pointer; object-fit: cover"
            />
          </v-col>
          <v-col>
            <div class="message-block__filename" @click="fileClick">
              {{ $filters.ellipsis(message.filename, 40, "middle") }}
            </div>
            <div class="message-block__filesize mt-3 mb-3 text-grey-darken-2">
              {{ $filters.bytes(message.size) }}
            </div>
            <div class="message-block__file-action">
              <v-btn
                class="text-uppercase font-size-12 font-weight-600"
                color="primary"
                variant="outlined"
                @click="downloadUrl(message.url)"
              >
                Download
              </v-btn>
              <div class="message-block__time mt-5">
                {{ showMessageTime(message.created) }}
                <span v-if="message.dispatcherId">
                  <v-icon v-if="message.read" class="ml-2" color="success" size="small">mdi-check-all</v-icon>
                  <v-icon v-else class="ml-2" color="success" size="small">mdi-check</v-icon>
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.message-block__text-content {
  overflow-wrap: anywhere;
}

.alert-block {
  :deep(.v-icon) {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .v-alert {
    border-radius: 12px;
  }
}

.circle {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.status-label {
  line-height: 14px !important;
}

.day {
  position: relative;

  &:after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    border: 1px solid #dee2e6;
  }

  span {
    background: #f8f9fa;
    display: inline-block;
    position: relative;
    z-index: 1;
  }
}

.bordered {
  border: solid 1px #616161;
}

.message {
  &-block:not(:last-child) {
    margin-bottom: 20px;
  }

  &-block__info {
    font-size: 14px;
  }

  .text {
    word-break: break-word;
  }

  &-block__inner {
    max-width: 90%;
    width: fit-content;

    @media (max-width: 991px) {
      max-width: 95%;
    }

    &.user {
      margin: 0 0 0 auto;
      text-align: right;
      width: fit-content;

      .message-block__text {
        background: $primary-light;
        color: $grey-text;
        border-radius: 8px 8px 0 8px;
        text-align: left;
        width: fit-content;
      }

      .message-block__time {
        color: $grey;
      }
    }
  }

  &-block__text {
    background: $primary-light-2;
    font-size: 14px;
    line-height: 16px;
    border-radius: 8px 8px 8px 0;
    width: fit-content;

    .text-span {
      overflow-wrap: anywhere;
    }
  }

  &-block__time {
    float: right;
    color: #757575;
    font-size: 12px;
    line-height: 18px;
    margin-left: 16px;
  }

  &-block__filename {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-align: left;
  }

  &-block__filesize {
    font-size: 11px;
    line-height: 12px;
    text-align: left;
  }

  &-block__file-action {
    text-align: left;
  }
}

:deep(.message-block__text-content) {
  a {
    color: $primary;
  }
}
</style>
