import { BaseApi } from "@/api/base";
import type { CompanyProfileDto } from "@/api/company/dto/company-profile.dto";
import type { Response } from "@/api/client";
import type { ListResponseDto } from "@/api/dto/list-response.dto";
import type { CarrierDto } from "@/api/company/dto/carrier.dto";
import type { CustomerDto } from "@/api/company/dto/customer.dto";
import type { FacilityDto } from "@/api/company/dto/facility.dto";
import type { QueryParams } from "@/api/types";

export class CompanyApi extends BaseApi {
  getProfile(): Promise<Response<CompanyProfileDto>> {
    return this.client.get<CompanyProfileDto>("/company");
  }

  findCarriers(options: QueryParams = {}): Promise<Response<ListResponseDto<CarrierDto>>> {
    return this.client.get<ListResponseDto<CarrierDto>>("/carriers", options);
  }

  findCarrierById(carrierId: number): Promise<Response<CarrierDto>> {
    return this.client.get<CarrierDto>("/carriers/" + carrierId);
  }

  async upsertCarrier(carrier: CarrierDto): Promise<Response<CarrierDto>> {
    return this.client.save<CarrierDto>("/carriers", carrier);
  }

  findCustomers(options: QueryParams = {}): Promise<Response<ListResponseDto<CustomerDto>>> {
    return this.client.get<ListResponseDto<CustomerDto>>("/customers", options);
  }

  findCustomerById(customerId: number): Promise<Response<CustomerDto>> {
    return this.client.get<CustomerDto>("/customers/" + customerId);
  }

  async upsertCustomer(customer: CustomerDto): Promise<Response<CustomerDto>> {
    return this.client.save<CustomerDto>("/customers", customer);
  }

  findFacilities(options: QueryParams = {}): Promise<Response<ListResponseDto<FacilityDto>>> {
    return this.client.get<ListResponseDto<FacilityDto>>("/facilities", options);
  }

  findFacilityById(facilityId: number): Promise<Response<FacilityDto>> {
    return this.client.get<FacilityDto>("/facilities/" + facilityId);
  }

  async upsertFacility(facility: FacilityDto): Promise<Response<FacilityDto>> {
    return this.client.save<FacilityDto>("/facilities", facility);
  }
}
