import { useChatStore } from "@/store/chat.store";
import type { ThreadDto } from "@/api/chat/dto/thread.dto";
import type { MessageDto } from "@/api/chat/dto/message.dto";

type Thread = {
  id: string;
  // Add other properties of the thread object if needed
};

const MessageDraftMixin = {
  methods: {
    draft(thread: ThreadDto): MessageDto | {} {
      const chatStore = useChatStore();
      return chatStore.getDraftMessage(thread.id);
    },
    saveDraft(threadId: number, message: string, file: File | null): void {
      const chatStore = useChatStore();
      chatStore.saveDraftMessage({ id: threadId, message, file });
    },
    removeDraft(threadId: number): void {
      const chatStore = useChatStore();
      chatStore.removeDraftMessage(threadId);
    },
  },
  props: {
    thread: {
      type: Object as () => Thread,
      required: true,
    },
  },
};

export default MessageDraftMixin;
