<script>
import DeliveryInfoDialog from "@/views/trips/view/dialogs/DeliveryInfoDialog.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "UnloadVerificationAction",
  components: {
    DeliveryInfoDialog,
  },
  inheritAttrs: false,
  props: {
    trip: Object,
    route: Object,
    stop: Object,
  },
  emits: ["verify"],
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    showUnloadVerification() {
      this.showDialog = true;
    },
    save() {
      this.$emit("verify");
    },
  },
};
</script>

<template>
  <v-btn
    block
    class="text-uppercase font-weight-600"
    color="primary"
    v-bind="$attrs"
    variant="flat"
    @click="showUnloadVerification"
  >
    Verify
  </v-btn>
  <delivery-info-dialog
    v-model="showDialog"
    :route="route"
    :stop="stop"
    :trip="trip"
    preload
    title="Verify information"
    @save="save"
  ></delivery-info-dialog>
</template>

<style lang="scss" scoped></style>
