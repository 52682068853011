<script>
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import DeviceInfo from "@/views/users/components/DeviceInfo.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "MobileStatus",
  components: { DeviceInfo },
  mixins: [DateTimeMixin],
  inheritAttrs: false,
  props: {
    user: Object,
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
    trackingStatus() {
      switch (this.user.mobileUserTrackingStatus) {
        case "available":
          return "Active";
        case "not_available":
          return "Not available";
        case "denied":
          return "Denied";
        case "not_provided_in_use":
        case "not_provided_always":
          return "Permissions required";
        default:
          return "—";
      }
    },
    lastTrackingTime() {
      return this.user.mobileUserTrackingStatusDt ? this.timeAgo(this.user.mobileUserTrackingStatusDt) : "—";
    },
    lastOnline() {
      return this.user.mobileUserLastOnline ? this.timeAgo(this.user.mobileUserLastOnline) : "—";
    },
  },
};
</script>

<template>
  <v-tooltip v-if="user.mobileUserHasPassword" :disabled="!user.mobileUserLastOnline" location="top start">
    <template #activator="{ props }">
      <v-icon
        :class="$attrs.class"
        :color="user.mobileUserLastOnline ? 'success' : 'grey'"
        icon="mdi-cellphone"
        v-bind="props"
      ></v-icon>
    </template>
    <div>
      Device:
      <device-info :user="user"></device-info>
    </div>
    <div>Last online: {{ lastOnline }}</div>
    <div>GPS tracking: {{ trackingStatus }}, {{ lastTrackingTime }}</div>
  </v-tooltip>
</template>

<style lang="scss" scoped></style>
