<script>
import { FacilityType } from "@/data/facility";
import ValidationMixin from "@/mixins/validation.mixin";
import LocationPicker from "@/components/pickers/LocationPicker.vue";
import CoordinatesField from "@/views/companies/facilities/components/CoordinatesField.vue";
import LocationField from "@/views/companies/facilities/components/LocationField.vue";

export default {
  name: "FacilityAddressForm",
  components: { LocationField, CoordinatesField, LocationPicker },
  mixins: [ValidationMixin],
  props: {
    modelValue: null,
    modal: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      location: null,
    };
  },
  computed: {
    facility: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    isStandard() {
      return this.facility.type === FacilityType.standard;
    },
    isPoint() {
      return this.facility.type === FacilityType.point;
    },
    isToBeDiscussed() {
      return this.facility.type === FacilityType.toBeDiscussed;
    },
    isManual() {
      return this.facility.type === FacilityType.manual;
    },
  },
  watch: {
    location() {
      this.facility.address = this.location.fullAddress;
      this.facility.addressCoordinates = { lat: this.location.lat, lon: this.location.lon };
    },
    modelValue(value) {
      if (value.address && !this.location) {
        this.location = {
          fullAddress: value.address,
          lat: value.addressCoordinates.lat,
          lon: value.addressCoordinates.lon,
        };
      }
    },
  },
};
</script>

<template>
  <v-row v-if="isStandard">
    <v-col lg="6">
      <v-text-field
        v-model="facility.addressLine"
        :rules="[requiredValidator]"
        autocomplete="off1"
        class="required"
        color="primary"
        data-qa="address-line"
        density="compact"
        hide-details="auto"
        label="Address line"
        variant="outlined"
      />
    </v-col>
    <v-col lg="6">
      <div class="d-flex align-center">
        <location-picker
          v-model="location"
          class="flex-1"
          hide-coordinates
          qa-attr="location"
          required
        ></location-picker>
        <v-tooltip location="top end">
          <template #activator="{ props }">
            <v-icon class="ml-3" color="primary" icon="mdi-information-outline" v-bind="props"></v-icon>
          </template>
          Loaded miles will be calculated based on this field
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
  <v-row v-if="isPoint">
    <v-col lg="6">
      <coordinates-field v-model="facility.preciseCoordinates" data-qa="coordinates" required></coordinates-field>
    </v-col>
    <v-col lg="6">
      <div class="d-flex align-center">
        <location-picker
          v-model="location"
          class="flex-1"
          hide-coordinates
          qa-attr="location"
          required
        ></location-picker>
        <v-tooltip location="top end">
          <template #activator="{ props }">
            <v-icon class="ml-3" color="primary" icon="mdi-information-outline" v-bind="props"></v-icon>
          </template>
          Loaded miles will be calculated based on this field
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
  <v-row v-if="isToBeDiscussed">
    <v-col>
      <div class="d-flex align-center">
        <location-picker
          v-model="location"
          class="flex-1"
          hide-coordinates
          qa-attr="location"
          required
        ></location-picker>
        <v-tooltip location="top end">
          <template #activator="{ props }">
            <v-icon class="ml-3" color="primary" icon="mdi-information-outline" v-bind="props"></v-icon>
          </template>
          Loaded miles will be calculated based on this field
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
  <v-row v-if="isManual">
    <v-col :lg="modal ? 6 : 4">
      <v-text-field
        v-model="facility.addressLine"
        :rules="[requiredValidator]"
        autocomplete="off1"
        class="required"
        color="primary"
        data-qa="address-line"
        density="compact"
        hide-details="auto"
        label="Address line"
        variant="outlined"
      />
    </v-col>
    <v-col :lg="modal ? 6 : 4">
      <location-field v-model="facility.address" data-qa="location" required />
    </v-col>
    <v-col :lg="modal ? 12 : 4">
      <div class="d-flex align-center">
        <coordinates-field v-model="facility.addressCoordinates" data-qa="coordinates" required></coordinates-field>
        <v-tooltip location="top end">
          <template #activator="{ props }">
            <v-icon class="ml-3" color="primary" icon="mdi-information-outline" v-bind="props"></v-icon>
          </template>
          Loaded miles will be calculated based on this field
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped></style>
