<script>
import OmniTextarea from "@/components/inputs/Textarea.vue";

export default {
  name: "OrderViewNote",
  components: { OmniTextarea },
  props: {
    order: Object,
  },
};
</script>

<template>
  <div class="user-block pt-5 pr-5 pb-7 pl-5 bg-white rounded mt-4">
    <v-row>
      <v-col>
        <div class="text-grey-darken-3 mb-6 font-weight-medium">General note</div>
        <v-row>
          <v-col>
            <omni-textarea :model-value="order.generalNote" data-qa="general-note" readonly rows="4"></omni-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>
