import { TruckAdditionalEquipment, TruckDoorType, TruckEquipment } from "@/data/truck.js";
import type { TruckDto } from "@/api/trucks/dto/truck.dto";

export default class TruckHelper {
  static equipmentToString(truck: TruckDto) {
    if (!truck.trailer?.equipment || truck.trailer?.equipment.length === 0) {
      return "-";
    }
    return truck.trailer.equipment.map((e) => this.getEquipmentName(e)).join(", ");
  }

  static additionalEquipmentToString(truck: TruckDto) {
    if (!truck.trailer?.additionalEquipment || truck.trailer?.additionalEquipment.length === 0) {
      return "-";
    }
    return truck.trailer.additionalEquipment.map((e) => this.getAdditionalEquipmentName(e)).join(", ");
  }

  static getEquipmentName(id: string) {
    return TruckEquipment.find((e) => e.id === id)?.name || "-";
  }

  static getAdditionalEquipmentName(id: string) {
    return TruckAdditionalEquipment.find((e) => e.id === id)?.name || "-";
  }

  static doorTypeToString(truck: TruckDto) {
    if (!truck.trailer?.doorType) {
      return "-";
    }
    return TruckDoorType.find((dt) => dt.id === truck.trailer?.doorType)?.name;
  }
}
