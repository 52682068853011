<script>
import BaseView from "@/views/BaseView.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "ResetPassword",
  mixins: [BaseView],
  data: () => ({
    error: "",
    form: { email: "", token: "" },
  }),
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarSuccess"]),
    async setPassword() {
      this.error = "";
      this.form.token = this.$route.query.token;
      try {
        const resp = await this.$api.auth.resetPassword({ email: this.form.email });
        if (resp.success) {
          this.showSnackbarSuccess(this.$t("auth.password-reset"));
        } else {
          this.error = resp.data.detail;
        }
      } catch (e) {
        console.error("Error resetting a password", e);
        if (e.data) {
          this.error = e.data.detail;
        }
      }
    },
  },
};
</script>

<template>
  <v-layout class="forgot-form pa-0 align-center justify-center" full-height>
    <div class="pa-7 forgot-form__inner">
      <div class="forgot-form__top pt-10 pr-6 pb-8 pl-6">
        <v-card variant="flat">
          <div class="mb-8">
            <div class="forgot-form__logo mb-4">
              <img alt="Logo" src="@/assets/images/logo.svg" width="50" />
            </div>
            <div class="forgot-form__title text-h5 text-center font-weight-medium mb-2">
              {{ $t("auth.reset-password") }}
            </div>
            <div class="forgot-form__subtitle text-grey-darken-1 text-body-2 text-center mb-0">
              Enter your email and we will send you a link to reset your password!
            </div>
          </div>

          <v-card-text class="pa-0">
            <v-text-field
              v-model="form.email"
              :error-messages="error"
              :label="$t('general.email')"
              class="mb-6"
              color="primary"
              density="compact"
              height="55"
              hide-details="auto"
              variant="outlined"
            />
            <div class="text-center">
              <v-btn
                class="font-weight-600 text-uppercase width-100 mb-4"
                color="primary"
                height="45"
                @click="setPassword"
                >{{ $t("auth.reset-password") }}
              </v-btn>
            </div>
            <div>
              <router-link
                class="font-size-12 text-uppercase text-primery text-decoration-none font-weight-600 text-center d-block"
                to="login"
              >
                Back to login
              </router-link>
            </div>
          </v-card-text>
        </v-card>

        <!-- Показывать когда успешно отправлено -->

        <!--<div class="reset-pass">
                          <div class="reset-pass__header mb-8">
                            <div class="reset-pass__icon text-center mb-5">
                              <img src="@/assets/images/check.svg">
                            </div>
                            <div class="headline mb-2 text-center text-grey-darken-3 font-weight-medium">Email sent</div>
                            <div class="text-center text-grey-darken-1">
                              We have already sent email to pavlo.n@hugohunter.com, please check it and open the link to continue
                            </div>
                          </div>
                          <div class="reset-pass__content">
                            <v-btn to="login" height="45px" color="primary" class="rounded width-100 text-uppercase text-white text-decoration-none font-weight-600 text-center mb-3">
                              Back to log in
                            </v-btn>
                            <router-link to=""  class="pa-1 d-block font-size-12 text-uppercase text-primery text-decoration-none font-weight-600 text-center d-block">
                              Send me link again
                            </router-link>
                          </div>
                        </div> -->
        <!--- -->

        <!-- Показывать когда не отправлено -->

        <!-- <div class="reset-pass">
                          <div class="reset-pass__header mb-8">
                            <div class="reset-pass__icon text-center mb-5">
                              <img src="@/assets/images/error.svg">
                            </div>
                            <div class="headline mb-2 text-center text-grey-darken-3 font-weight-medium">Email not sent</div>
                            <div class="text-center text-grey-darken-1">
                              Something went wrong, please try to send link again
                            </div>
                          </div>
                          <div class="reset-pass__content">
                            <v-btn to="login" height="45px" color="primary" class="rounded width-100 text-uppercase text-white text-decoration-none font-weight-600 text-center mb-3">
                              Send link to email
                            </v-btn>
                            <router-link to="login"  class="pa-1 d-block font-size-12 text-uppercase text-primery text-decoration-none font-weight-600 text-center d-block">
                              Back to log in
                            </router-link>
                          </div>
                        </div> -->
        <!-- -->
      </div>
    </div>
  </v-layout>
</template>

<style lang="scss" scoped>
.forgot-form__inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .v-form > .v-card {
    box-shadow: none !important;
    background: transparent !important;
  }

  .forgot-form__top {
    width: 430px;
    margin: 0 auto;
    box-shadow:
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14),
      0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    background: #fff;
  }
}

.forgot-form__logo {
  max-width: 50px;
  margin: 0 auto;
}
</style>

<style lang="scss">
.forgot-form__inner {
  .v-text-field--outlined.v-input--dense .v-label {
    top: 50%;
    transform: translateY(-50%);
  }

  .v-text-field--outlined.v-input--dense .v-label--active {
    top: 0;
    transform: translateY(-8px) scale(0.75);
  }

  .v-input__append-inner {
    align-self: center;
    margin-top: 0 !important;
  }
}
</style>
