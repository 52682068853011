<script>
import ValidationMixin from "@/mixins/validation.mixin";
import { VInput } from "vuetify/components";
import BaseView from "@/views/BaseView.vue";

export default {
  name: "ContactPerson",
  extends: VInput,
  mixins: [BaseView, ValidationMixin],
  props: {
    modelValue: null,
    user: Object,
    id: String,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      contactPersons: [],
      owner: null,
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    "user.isOwner"() {
      this.processValue(false);
    },
    "user.isCoordinator"() {
      this.processValue(false);
    },
    "user.name"() {
      this.processValue(false);
    },
    "user.coordinators": {
      handler() {
        this.processValue(false);
      },
      deep: true,
    },
    "user.ownerId"() {
      this.processValue();
    },
  },
  async mounted() {
    await this.processValue();
  },
  methods: {
    processUser() {
      const userRoles = ["Driver"];
      if (this.user.isOwner) {
        userRoles.push("Owner");
      }
      if (this.user.isCoordinator) {
        userRoles.push("Coordinator");
      }

      return {
        id: this.user.id || 0,
        role: userRoles.join("-"),
        name: this.user.name,
      };
    },
    async processOwner(refresh = true) {
      const result = [];

      if (!this.user.ownerId) {
        return result;
      }

      const addOwnerWithCoordinators = () => {
        result.push({
          id: this.owner.id,
          role: this.$t("users.owner"),
          name: this.owner.name,
        });

        // add owner's coordinators as contact persons
        result.push(
          ...this.owner.coordinators.map((coordinator) => ({
            id: coordinator.id,
            role: this.$t("users.coordinator"),
            name: coordinator.name,
          })),
        );
      };

      if (refresh) {
        if (this.user.ownerId && this.user.ownerId !== this.user.id) {
          // add owner as option to contact persons
          const ownerResp = await this.$api.users.findOwnerById(this.user.ownerId);
          if (ownerResp.success) {
            this.owner = ownerResp.data;
            addOwnerWithCoordinators();
          }
        }
      } else {
        addOwnerWithCoordinators();
      }
      return result;
    },
    processCoordinators() {
      return this.user.coordinators.map((coordinator) => ({
        id: coordinator.userId,
        role: this.$t("users.coordinator"),
        name: coordinator.user.name,
      }));
    },
    async processValue(refreshOwner = true) {
      if (!this.user) return;
      const contactPersons = [];

      // add current user
      contactPersons.push(this.processUser());

      // add owner & owner's coordinator for current user
      contactPersons.push(...(await this.processOwner(refreshOwner)));

      // add coordinators
      contactPersons.push(...this.processCoordinators());

      // clear selection if removed
      this.ensureSelection(contactPersons);

      this.contactPersons = this.uniqueArray(contactPersons, ["id"]);
    },
    ensureSelection(contactPersons) {
      const existsInList = contactPersons.find((contactPerson) => contactPerson.id === this.user.contactPersonId);
      if (this.user.contactPersonId && !existsInList) {
        this.$emit("update:modelValue", null);
      }
    },
  },
};
</script>

<template>
  <v-select
    :id="id"
    v-model="model"
    :items="contactPersons"
    :menu-props="{ closeOnContentClick: true }"
    :rules="[requiredValidator]"
    class="mb-0 width-100 required"
    color="primary"
    density="compact"
    hide-details="auto"
    item-title="name"
    item-value="id"
    label="Contact Person"
    variant="outlined"
  >
    <template #item="{ item }">
      <v-list-item :subtitle="item.raw.role" :title="item.raw.name" @click="model = item.raw.id" />
    </template>
    <template #selection="{ item }">
      <div>
        <span class="body-2">{{ item.raw.name }}</span>
        <span class="ml-6">{{ item.raw.role }}</span>
      </div>
    </template>
  </v-select>
</template>

<style lang="scss">
.contact-person-container {
  width: 100%;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
}
</style>
