import { defineStore } from "pinia";

interface GalleryState {
  media: any[];
  mediaId: number | null;
}

export const useGalleryStore = defineStore("galleryStore", {
  state: (): GalleryState => ({
    media: [],
    mediaId: null,
  }),
  actions: {
    setMedia(payload: any) {
      this.media = payload;
    },
    setMediaId(id: number) {
      this.mediaId = id;
    },
  },
});
