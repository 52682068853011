<script>
import CompaniesField from "@/views/users/CompaniesField.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import OwnerCoordinatorsBlock from "@/views/users/components/OwnerCoordinatorsBlock.vue";

export default {
  name: "UserFormOwner",
  components: { OwnerCoordinatorsBlock, CompaniesField },
  mixins: [UsersMixin, ValidationMixin],
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      hasDriver: null,
      hasTruck: null,
    };
  },
  computed: {
    user: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    async modelValue() {
      if (this.modelValue.isOwner && this.hasDriver === null) {
        const resp = await this.$api.users.findDrivers({ owner: this.modelValue.id });
        if (resp.success) {
          this.hasDriver = resp.data.total > 0 ? resp.data.items[0] : false;
        }
      }
      if (this.modelValue.isOwner && this.hasTruck === null) {
        const resp = await this.$api.trucks.findTrucks({ owner: this.modelValue.id });
        if (resp.success) {
          this.hasTruck = resp.data.total > 0 ? resp.data.items[0] : false;
        }
      }
    },
  },
  methods: {
    onOwnerChanged() {
      if (this.user.isOwner) {
        this.addCompany();
      } else {
        this.user.companies = [];
      }
    },
    addCompany() {
      this.user.companies.push({
        changed: false,
        name: "",
        number: "",
        insuranceExpireDate: "",
        addressCountry: "US",
        addressState: "",
        addressCity: "",
        addressZipCode: "",
        addressLine: "",
      });
    },
    coordinatorsChanged(coordinators) {
      this.user.owner.coordinators = coordinators;
    },
  },
};
</script>

<template>
  <div class="pa-2 mb-6">
    <div class="user-header mb-3 d-flex align-center">
      <div class="subheading font-weight-medium">Owner information</div>
      <v-btn
        v-if="user.isOwner && user.companies && user.companies.length < 10"
        id="add_company"
        class="text-uppercase pr-3 pl-2 ml-5 font-weight-600"
        color="success"
        height="28"
        size="small"
        variant="flat"
        @click="addCompany"
      >
        <v-icon size="small">mdi-plus</v-icon>
        {{ $t("companies.add") }}
      </v-btn>
    </div>

    <div class="pa-5 bg-white rounded personal-block">
      <div class="d-flex align-center justify-start flex-wrap">
        <v-menu :disabled="!(hasDriver || hasTruck)" :open-on-hover="true" location="top start" offset="4">
          <template #activator="{ props }">
            <div v-bind="props">
              <v-switch
                id="is_owner"
                v-model="user.isOwner"
                :disabled="!!(user.id && (hasDriver || hasTruck))"
                :inset="true"
                :label="$t('drivers.owner')"
                class="ma-0"
                color="primary"
                density="compact"
                hide-details
                @change="onOwnerChanged"
              />
            </div>
          </template>
          <v-card>
            <v-card-text>
              <div v-if="hasDriver">
                Owner has active driver
                <a :href="`/users/` + hasDriver.id + `/view`" target="_blank">{{ hasDriver.name }}</a>
              </div>
              <div v-if="hasTruck">
                Owner has active truck
                <a :href="`/fleets/trucks/` + hasTruck.id" target="_blank">{{ hasTruck.number }}</a>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </div>

    <v-row v-if="user.isOwner" class="pt-6">
      <v-col cols="6">
        <div class="pa-5 bg-white rounded personal-block">
          <div class="caption text-grey-darken-1 mb-3">Owner information</div>
          <v-select
            id="owner_status"
            v-model="user.ownerStatus"
            :items="ownerStatuses"
            :label="$t('drivers.owner-status')"
            :rules="[requiredValidator]"
            class="required"
            color="primary"
            density="compact"
            hide-details
            item-title="name"
            item-value="id"
            variant="outlined"
          />
        </div>
      </v-col>
      <v-col cols="6">
        <div class="pa-5 bg-white rounded personal-block">
          <div class="caption text-grey-darken-1 mb-3">Coordinator(s)</div>
          <owner-coordinators-block :user="user" @change="coordinatorsChanged" />
        </div>
      </v-col>
    </v-row>

    <companies-field v-model="user.companies" />
  </div>
</template>

<style lang="scss" scoped></style>
