<script>
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import LocationPicker from "@/components/pickers/LocationPicker.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import OmniDialog from "@/components/Dialog.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import DateTimePicker from "@/components/pickers/DateTimePicker.vue";

export default {
  name: "ChangeTruckStatusAction",
  components: { DateTimePicker, OmniSelect, OmniDialog, LocationPicker },
  mixins: [DateTimeMixin, TrucksMixin, UsersMixin],
  props: {
    truck: Object,
    closeOnSuccess: { type: Boolean, default: true },
    closeOnError: { type: Boolean, default: true },
  },

  emits: ["success", "error"],
  data() {
    return {
      dialogVisible: false,

      location: null,
      date: null,
      status: null,

      truckStatuses: [
        { id: "a", name: this.$t("trucks.status-a") },
        { id: "w", name: this.$t("trucks.status-w") },
        { id: "n", name: this.$t("trucks.status-n") },
      ],
    };
  },
  computed: {
    requiredDate() {
      return this.status === this.truckStatusWillBeAvailable || this.status === this.truckStatusNotAvailable;
    },
    disableLocation() {
      return this.status === this.truckStatusNotAvailable;
    },
    showDate() {
      return (
        this.status === this.truckStatusWillBeAvailable ||
        (this.status === this.truckStatusNotAvailable && this.location)
      );
    },
    hasError() {
      if (!this.location) {
        return true;
      } else if (this.status === this.truckStatusWillBeAvailable) {
        return !this.date;
      }
      return false;
    },
    changeEnabled() {
      return (
        this.truck.status !== this.truckStatusInRoute &&
        this.truck.status !== this.truckStatusOnHold &&
        this.truck.driver &&
        this.truck.owner?.status !== this.driverStatusOnHold &&
        this.truck.driver?.status !== this.driverStatusOnHold &&
        this.truck.driver2?.status !== this.driverStatusOnHold
      );
    },
    timeFieldLabel() {
      let tz = null;
      if (this.location) {
        if (this.date) {
          const dt = this.$dayjs(this.date).toDate();
          tz = this.getShortTZForDate(dt, this.location.timezone);
        } else {
          tz = this.getTZNames(this.location.timezone);
        }
      }
      return tz ? `Local time (${tz})` : "Local time";
    },
    minDate() {
      return this.requiredDate ? this.$dayjs().tz(this.location?.timezone).format("YYYY-MM-DD HH:mm:ss") : null;
    },
  },
  mounted() {
    this.updateTruckInfo();
  },
  methods: {
    getLocationFromTruckAvailableLocation() {
      let location = {};
      if (this.truck.availableLocation) {
        const availableLocation = this.truck.availableLocation;
        location.timezone = availableLocation.timezone;
        location.address = {
          city: availableLocation.city,
          state: availableLocation.state,
          zipCode: availableLocation.postcode,
          country: availableLocation.country,
        };
        location.fullAddress = availableLocation.fullAddress || "";
        if (availableLocation.coordinates) {
          location.lat = availableLocation.coordinates.lat;
          location.lon = availableLocation.coordinates.lon;
        }
      }
      return location;
    },
    showDialog() {
      this.updateTruckInfo();
      this.dialogVisible = true;
    },
    onStatusChange() {
      if (this.status === this.truckStatusNotAvailable) {
        this.date = null;
        let location = this.getLocationFromTruckAvailableLocation();
        if (location.address.city && location.address.city !== "Unknown") {
          this.location = location;
        }
      } else if (this.status === this.truckStatusWillBeAvailable) {
        this.date = null;
      }
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    async save() {
      const result = await this.$refs.form.validate();

      if (!result.valid) {
        return;
      }

      const data = {
        status: this.status,
      };
      if (this.status === this.truckStatusAvailable || this.status === this.truckStatusWillBeAvailable) {
        data.address = this.location.fullAddress;
        data.city = this.location.address.city;
        data.state = this.location.address.state;
        data.zipCode = this.location.address.postcode || "";
        data.country = this.location.address.country;
        data.lat = this.location.lat;
        data.lon = this.location.lon;
      }
      if (this.showDate && this.date) {
        data.availableDate = this.date;
      }

      try {
        await this.$api.trucks.updateTruckStatus(this.truck.id, data);
        this.$emit("success");
        if (this.closeOnSuccess) {
          this.closeDialog();
        }
      } catch (error) {
        this.$emit("error");
        if (this.closeOnSuccess) {
          this.closeDialog();
        }
        console.error("Error save truck location: ", error);
      }
    },
    updateTruckInfo() {
      if (this.truck.id === undefined) return;

      this.date = null;
      this.location = null;
      this.status = this.truck.status;
      let location = this.getLocationFromTruckAvailableLocation();

      if (location.address?.city && location.address.city !== "Unknown") {
        this.location = location;
      }

      if (this.truck.availableDate) {
        const availableDate = this.$dayjs(this.truck.availableDate).tz(this.location.timezone);
        if (
          this.truck.status !== this.truckStatusNotAvailable ||
          (this.truck.status === this.truckStatusNotAvailable && availableDate.isAfter(this.$dayjs()))
        ) {
          this.date = availableDate.format("YYYY-MM-DD HH:mm");
        }
      }
    },
  },
};
</script>
<template>
  <div v-if="changeEnabled" @click="showDialog">
    <slot></slot>
  </div>
  <div v-if="!changeEnabled">
    <slot v-if="$slots.disabled" name="disabled">
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn class="mt-3 text-uppercase font-weight-600 width-100" color="grey" v-bind="props" variant="outlined">
            CHANGE STATUS
          </v-btn>
        </template>
        <span> The status cannot be changed when truck doesn't have a first driver </span>
      </v-tooltip>
    </slot>
    <slot v-else></slot>
  </div>
  <omni-dialog
    v-model="dialogVisible"
    :disabled="hasError"
    :title="'Change status for truck #' + truck.number"
    primary-action-label="Save"
    size="large"
    @primary-action:click="save"
  >
    <v-form ref="form">
      <v-row>
        <v-col>
          <omni-select
            v-model="status"
            :items="truckStatuses"
            data-qa="truck-status-change"
            label="Status"
            @update:model-value="onStatusChange"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <location-picker v-model="location" :disabled="disableLocation" label="Location" />
        </v-col>
      </v-row>

      <div
        v-if="status === truckStatusNotAvailable && location"
        class="py-3 font-size-16 font-weight-500 text-grey-darken-2"
      >
        <span>Not available till:</span>
      </div>
      <date-time-picker v-if="showDate" v-model="date" :min="minDate" :time-label="timeFieldLabel"></date-time-picker>
    </v-form>
  </omni-dialog>
</template>
