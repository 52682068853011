<script>
import BaseView from "@/views/BaseView.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import SidebarLayout from "@/components/layout/SidebarLayout.vue";
import SidebarToggle from "@/components/layout/SidebarToggle.vue";
import NotFoundMixin from "@/mixins/not-found.mixin";
import NotFound from "@/views/misc/NotFound.vue";
import OrderViewInfo from "@/views/orders/components/view/OrderViewInfo.vue";
import OrderViewNote from "@/views/orders/components/view/OrderViewNote.vue";
import OrderViewStops from "@/views/orders/components/view/OrderViewStops.vue";
import OmniTabs from "@/components/controls/OmniTabs.vue";
import OrderFiles from "@/views/orders/components/view/OrderFiles.vue";
import OrderViewSidebar from "@/views/orders/components/view/OrderViewSidebar.vue";
import { mapActions, mapState } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";
import { useAppStore } from "@/store/app.store";

export default {
  name: "OrderView",
  components: {
    OrderFiles,
    OrderViewSidebar,
    OmniTabs,
    OrderViewStops,
    OrderViewNote,
    OrderViewInfo,
    NotFound,
    SidebarToggle,
    SidebarLayout,
  },
  mixins: [BaseView, UsersMixin, TrucksMixin, DateTimeMixin, NotFoundMixin],
  data() {
    return {
      order: {},
      loading: false,
      showRoute: false,
      tab: 0,
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatchers", "dispatcher"]),
    editLink() {
      return {
        name: this.$route.name.replace("view", "edit"),
        params: { id: this.order.id },
      };
    },
  },
  async mounted() {
    await this.loadOrder();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    async loadOrder() {
      const resp = await this.$api.orders.findOrderById(this.$route.params.id);
      if (!this.validateEntityResponse(resp)) return;

      const order = { ...resp.data, trip: { routes: [] } };

      // load trip
      if (order.tripId) {
        const tripResp = await this.$api.trips.getTripById(order.tripId);
        if (tripResp.success) {
          order.trip = tripResp.data;

          // load trip trucks
          for (const route of order.trip.routes) {
            const truckResp = await this.$api.trucks.findTruckById(route.truckId);
            route.truck = truckResp.data;
          }
        } else {
          this.showSnackbarError("Error loading trip");
        }
      }

      this.order = order;

      this.setTitle("Order #" + this.order.number, "Orders");
    },
    isSidebarOpenDefault() {
      let windowWidth = window.innerWidth;
      return windowWidth > 1250;
    },
  },
};
</script>

<template>
  <v-container v-if="!entityNotFound" :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100 users-page">
    <sidebar-layout :sidebar-opened="isSidebarOpenDefault()">
      <div class="w-100">
        <div class="d-flex justify-space-between align-center flex-wrap mb-4">
          <div class="text-grey-darken-4 title font-weight-medium font-size-24">Order #{{ order.number }}</div>
          <div v-if="order.id">
            <v-btn
              v-if="hasAccess('orders', 'edit')"
              :to="editLink"
              class="text-uppercase font-weight-600 text-white mr-2"
              color="primary"
              variant="flat"
            >
              <v-icon class="mr-2" size="20"> mdi-pencil-outline</v-icon>
              Edit order
            </v-btn>
            <sidebar-toggle class="mr-2"></sidebar-toggle>
          </div>
        </div>

        <v-card variant="flat">
          <omni-tabs v-model="tab" class="rounded">
            <v-tab key="overview" class="text-uppercase">Overview</v-tab>
            <v-tab class="text-uppercase" value="updates">Order updates</v-tab>
            <v-tab class="text-uppercase" value="files">Files</v-tab>
          </omni-tabs>

          <v-window v-model="tab" :touch="false">
            <v-window-item value="overview">
              <order-view-info :order="order"></order-view-info>
              <order-view-note :order="order"></order-view-note>
              <order-view-stops :order="order"></order-view-stops>
            </v-window-item>

            <v-window-item value="updates"></v-window-item>

            <v-window-item value="files">
              <order-files :order="order"></order-files>
            </v-window-item>
          </v-window>
        </v-card>
      </div>
      <template #sidebar>
        <order-view-sidebar :order="order"></order-view-sidebar>
      </template>
    </sidebar-layout>
  </v-container>
  <not-found v-else icon="mdi-account-remove-outline" text="Order not found"></not-found>
</template>

<style lang="scss" scoped>
b {
  color: black;
}

h3 {
  margin: 0 0 3px;
  color: black;
}

h4 {
  margin: 0 0 2px;
  color: black;
}

.v-card {
  background-color: transparent !important;
}
</style>
