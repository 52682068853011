<script>
import BaseView from "@/views/BaseView.vue";
import TrucksList from "@/views/trucks/TrucksList.vue";
import TrucksListSearchForm from "@/views/trucks/TrucksListSearchForm.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "TrucksPage",
  components: { PageHeader, LinkButton, TrucksListSearchForm, TrucksList },
  mixins: [BaseView],
  data() {
    return {
      perm: "trucks",
      searchParams: {},
    };
  },
  computed: {
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
  },
  created() {
    this.setTitle(this.$t("trucks.trucks"));
  },
  methods: {
    search(params) {
      this.searchParams = { ...params };
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100 trucks-page">
    <page-header :header="$t('trucks.trucks')">
      <template #suffix>
        <link-button id="create-truck-btn" :to="{ name: 'truck-edit', params: { id: 'new' } }" color="success">
          {{ $t("general.create-new") }}
        </link-button>
      </template>
    </page-header>

    <trucks-list-search-form @search="search" />
    <trucks-list
      :available-columns="['name', 'status', 'driver', 'size', 'available', 'current']"
      :can-edit="canEdit"
      :search-params="searchParams"
    />
  </v-container>
</template>

<style lang="scss" scoped>
.trucks-page {
  & > .v-card {
    background: transparent;
  }
}
</style>
