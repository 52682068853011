<script>
import UsersMixin from "@/views/users/UsersMixin.vue";
import BaseView from "@/views/BaseView.vue";
import SearchField from "@/components/inputs/SearchField.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import DispatcherSelect from "@/components/inputs/DispatcherSelect.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import { defineComponent } from "vue";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import CustomerSelect from "@/views/orders/components/CustomerSelect.vue";
import FacilitySelect from "@/views/orders/components/FacilitySelect.vue";
import DateRangePicker from "@/components/pickers/DateRangePicker.vue";
import { OrderStatusMap } from "@/data/order";

const searchParamsKey = "orders";

export default defineComponent({
  name: "TrucksListSearchForm",
  components: {
    DateRangePicker,
    FacilitySelect,
    CustomerSelect,
    OmniSelect,
    TableFilter,
    TableFilterRow,
    DispatcherSelect,
    SearchField,
  },
  mixins: [BaseView, UsersMixin, TrucksMixin],
  emits: ["search"],
  data() {
    return {
      params: {},
      showAdditionalFilters: false,
    };
  },
  computed: {
    statuses() {
      return OrderStatusMap;
    },
    createdDate: {
      get() {
        return this.params.createdFrom && this.params.createdTo
          ? [this.params.createdFrom, this.params.createdTo]
          : null;
      },
      set(value) {
        if (Array.isArray(value)) {
          this.params.createdFrom = value[0];
          this.params.createdTo = value[1];
        }
      },
    },
    pickupDate: {
      get() {
        return this.params.pickupFrom && this.params.pickupTo
          ? [this.params.pickupFrom, this.params.pickupTo]
          : null;
      },
      set(value) {
        if (Array.isArray(value)) {
          this.params.pickupFrom = value[0];
          this.params.pickupTo = value[1];
        }
      },
    },
    deliveryDate: {
      get() {
        return this.params.deliveryFrom && this.params.deliveryTo
          ? [this.params.deliveryFrom, this.params.deliveryTo]
          : null;
      },
      set(value) {
        if (Array.isArray(value)) {
          this.params.deliveryFrom = value[0];
          this.params.deliveryTo = value[1];
        }
      },
    },
  },
  created() {
    this.params = this.getSearchParams(searchParamsKey);
    this.search();
  },
  methods: {
    clear() {
      this.params = {};
      this.search();
    },
    search() {
      this.setSearchParams(searchParamsKey, this.params);
      this.$emit("search", this.params);
    },
  },
});
</script>

<template>
  <table-filter @apply="search" @clear="clear">
    <template #header>
      <table-filter-row :divider="false" icon="mdi-filter-variant" label="Main information">
        <v-col>
          <search-field v-model="params.number" label="Order" name="number" @search="search" />
        </v-col>
        <v-col>
          <search-field v-model="params.refNumber" label="Ref number" name="refNumber" @search="search" />
        </v-col>
        <v-col>
          <search-field v-model="params.truckNumber" label="Truck number" name="truck" @search="search" />
        </v-col>
        <v-col>
          <omni-select
            v-model="params.status"
            :items="statuses"
            clearable
            label="Status"
            name="status"
            @change="search"
          />
        </v-col>
      </table-filter-row>
    </template>
    <table-filter-row icon="mdi-map-outline" label="Trip details">
      <v-col>
        <search-field v-model="params.routeNumber" label="Route number" name="routeNumber" @search="search" />
      </v-col>
      <v-col>
        <search-field
          v-model="params.travelOrderNumber"
          label="Travel order number"
          name="travelOrderNumber"
          @search="search"
        />
      </v-col>
      <v-col>
        <search-field v-model="params.tripNumber" label="Trip number" name="tripNumber" @search="search" />
      </v-col>
      <v-col>
        <customer-select v-model="params.billToCustomer" label="Bill to company" @change="search"></customer-select>
      </v-col>
    </table-filter-row>
    <table-filter-row icon="mdi-badge-account-horizontal-outline" label="Dispatch info">
      <v-col>
        <dispatcher-select
          v-model="params.bookedBy"
          label="Booked by"
          name="bookedBy"
          @change="search"
        ></dispatcher-select>
      </v-col>
      <v-col>
        <dispatcher-select
          v-model="params.assignTo"
          label="Assign to"
          name="assignTo"
          @change="search"
        ></dispatcher-select>
      </v-col>
      <v-col>
        <dispatcher-select
          v-model="params.createdBy"
          label="Created by"
          name="createdBy"
          @change="search"
        ></dispatcher-select>
      </v-col>
      <v-col>
        <date-range-picker v-model="createdDate" label="Created from - to" @search="search"></date-range-picker>
      </v-col>
    </table-filter-row>
    <table-filter-row :divider="false" icon="mdi-account-multiple-outline" label="Shipper/Receiver">
      <v-col>
        <v-row>
          <v-col cols="6">
            <facility-select v-model="params.facility" hide-details label="Facility"></facility-select>
          </v-col>
          <v-col>
            <date-range-picker v-model="pickupDate" label="Picked up from - to" @search="search"></date-range-picker>
          </v-col>
          <v-col>
            <date-range-picker v-model="deliveryDate" label="Delivered from - to" @search="search"></date-range-picker>
          </v-col>
        </v-row>
      </v-col>
    </table-filter-row>
  </table-filter>
</template>

<style lang="scss" scoped>
.dimension-part {
  position: relative;

  &:after {
    content: "х";
    position: absolute;
    top: 50%;
    right: -4px;
    transform: translateY(-50%);
  }
}
</style>
