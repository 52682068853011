<script>
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import { MasksMixin } from "@/mixins/masks.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import Countries from "@/data/countries";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import OmniTextField from "@/components/controls/OmniTextField.vue";

export default {
  name: "AddressField",
  components: { OmniTextField, OmniSelect },
  mixins: [GoogleMapsMixin, MasksMixin, ValidationMixin],
  props: {
    modelValue: Object,
    label: {
      type: String,
      default: "",
    },
    modelPrefix: {
      type: String,
      default: "",
    },
    modelSuffix: {
      type: String,
      default: "",
    },
    hideLocation: Boolean,
    disabled: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      loading: false,
      countries: Countries,
    };
  },
  computed: {
    addressLineName() {
      return this.asCamelCase([this.modelPrefix, "addressLine", this.modelSuffix]);
    },
    addressCityName() {
      return this.asCamelCase([this.modelPrefix, "addressCity", this.modelSuffix]);
    },
    addressStateName() {
      return this.asCamelCase([this.modelPrefix, "addressState", this.modelSuffix]);
    },
    addressZipCodeName() {
      return this.asCamelCase([this.modelPrefix, "addressZipCode", this.modelSuffix]);
    },
    addressCountryName() {
      return this.asCamelCase([this.modelPrefix, "addressCountry", this.modelSuffix]);
    },
    addressLocationName() {
      return this.asCamelCase([this.modelPrefix, "addressLocation", this.modelSuffix]);
    },
    addressLine: {
      get() {
        return this.modelValue[this.addressLineName];
      },
      set(value) {
        this.update(this.addressLineName, value);
      },
    },
    addressCity: {
      get() {
        return this.modelValue[this.addressCityName];
      },
      set(value) {
        this.update(this.addressCityName, value);
      },
    },
    addressState: {
      get() {
        return this.modelValue[this.addressStateName];
      },
      set(value) {
        this.update(this.addressStateName, value);
      },
    },
    addressZipCode: {
      get() {
        return this.modelValue[this.addressZipCodeName];
      },
      set(value) {
        this.update(this.addressZipCodeName, value);
      },
    },
    addressCountry: {
      get() {
        return this.modelValue[this.addressCountryName];
      },
      set(value) {
        this.update(this.addressCountryName, value);
      },
    },
    hasLocation() {
      return this.location.lat !== null && this.location.lon !== null;
    },
    location() {
      if (!this.modelValue.addressLocation) {
        return { lat: null, lon: null };
      }
      return { lat: this.modelValue.addressLocation.lat, lon: this.modelValue.addressLocation.lon };
    },
  },
  methods: {
    async validateZipCode(zipCode) {
      if (zipCode.length > 4) {
        this.loading = true;

        const resp = await this.$api.geo.searchPostalCode(zipCode);
        if (resp.success && resp.data.length > 0) {
          const result = resp.data[0];
          const data = {};
          data[this.addressLocationName] = { lon: result.lon, lat: result.lat };
          if (!this.addressCountry && result.address.countryCode) {
            data[this.addressCountryName] = result.address.countryCode.toUpperCase();
          }
          if (!this.addressState && result.address.state) {
            data[this.addressStateName] = result.address.state.substring(0, 2).toUpperCase();
          }
          if (!this.addressCity && result.address.city) {
            data[this.addressCityName] = result.address.city;
          }
          this.updateAll(data);
          this.loading = false;
          return true;
        } else {
          this.loading = false;
          return "Wrong ZIP code";
        }
      } else {
        this.loading = false;
        return "Wrong ZIP code";
      }
    },
    asCamelCase(chunks) {
      return chunks
        .filter((chunk) => chunk !== "" && chunk !== undefined && chunk !== null)
        .map((chunk, index) => (index === 0 ? chunk : this.capitalize(chunk)))
        .join("");
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    update(key, value) {
      this.$emit("update:modelValue", { ...this.modelValue, [key]: value });
    },
    updateAll(object) {
      this.$emit("update:modelValue", { ...this.modelValue, ...object });
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-3">
      <div class="caption text-grey-darken-1">{{ label }}</div>
      <div v-if="!hideLocation" class="d-flex align-center">
        <v-icon
          :class="{
            'text-grey-darken-1': !hasLocation,
            'text-green': hasLocation,
          }"
          class="mr-2"
          size="14"
          >mdi-map-marker-radius-outline
        </v-icon>
        <a
          v-if="hasLocation"
          :href="getPlaceUrl(location)"
          class="text-decoration-underline text-green caption"
          target="_blank"
        >
          Lat:
          {{ location.lat }}
          Lon:
          {{ location.lon }}
        </a>
        <span v-if="!hasLocation" class="text-decoration-underline text-grey-darken-1 caption"> Lat: ... Lon: ...</span>
      </div>
      <slot name="label:suffix"></slot>
    </div>

    <v-row>
      <v-col>
        <omni-text-field
          v-model="addressLine"
          :disabled="disabled"
          :rules="[requiredValidator]"
          data-qa="address_line"
          label="Address line"
          required
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <omni-text-field
          v-model="addressCity"
          :disabled="disabled"
          :rules="[requiredValidator]"
          data-qa="address_city"
          label="City"
          required
        />
      </v-col>
      <v-col cols="3">
        <omni-text-field
          v-model="addressState"
          v-maska:[stateShortMask]
          :disabled="disabled"
          :rules="[requiredValidator, stateValidator]"
          data-qa="address_state"
          label="State"
          required
        />
      </v-col>
      <v-col cols="3">
        <omni-text-field
          v-model="addressZipCode"
          v-maska:[zipCodeMask]
          :disabled="disabled"
          :loading="loading"
          :rules="[requiredValidator, digitsValidator, validateZipCode]"
          data-qa="address_zip_code"
          label="Zip code"
          required
        />
      </v-col>
      <v-col cols="2">
        <omni-select
          v-model="addressCountry"
          :disabled="disabled"
          :items="countries"
          :rules="[requiredValidator]"
          data-qa="address_country"
          label="Country"
        />
      </v-col>
    </v-row>
  </div>
</template>
