<script>
import PhoneNumber from "@/components/PhoneNumber.vue";
import BaseView from "@/views/BaseView.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import DropdownList from "@/components/DropdownList.vue";
import UserRole from "@/views/users/components/UserRole.vue";
import OwnersSearchForm from "@/views/owners/OwnersSearchForm.vue";
import OmniTable from "@/components/OmniTable.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import PageHeader from "@/components/PageHeader.vue";
import MobileStatus from "@/components/status/MobileStatus.vue";
import OpenChatButton from "@/components/controls/OpenChatButton.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";
import { RoleAction } from "@/enums/navigation";

export default {
  name: "OwnersPage",
  components: {
    OpenChatButton,
    MobileStatus,
    PageHeader,
    LinkButton,
    OmniTable,
    OwnersSearchForm,
    DropdownList,
    PhoneNumber,
    UserRole,
  },
  mixins: [BaseView, UsersMixin],
  data() {
    return {
      menuTrucks: false,
      perm: "owners",
      headers: [
        { title: this.$t("general.full-name"), key: "name", sortable: false, class: "col-name" },
        {
          title: this.$t("general.status"),
          key: "status",
          class: "col-status",
          sortable: false,
          align: "center",
        },
        {
          title: this.$t("general.phone"),
          key: "phone",
          class: "col-phone",
          sortable: false,
          align: "left",
        },
        {
          title: "Coordinator(s)",
          key: "coordinators",
          class: "col-coordinators",
          sortable: false,
          align: "left",
        },
        {
          title: this.$t("users.drivers"),
          key: "drivers",
          class: "col-drivers",
          sortable: false,
          align: "left",
        },
        { title: this.$t("drivers.trucks"), key: "trucks", sortable: false, class: "col-trucks" },
        {
          title: this.$t("general.email"),
          key: "email",
          class: "col-email",
          sortable: false,
          align: "left",
        },
        {
          title: this.$t("general.address"),
          key: "address",
          sortable: false,
          class: "col-address",
        },
        {
          title: "Actions",
          key: "actions",
          align: "left",
          class: "col-actions",
          sortable: false,
        },
      ],
      filter: {},
      data: { total: 0 },
      loading: true,
      archived: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["mobile"]),
    canEdit() {
      return this.hasAccess(this.perm, RoleAction.edit);
    },
  },
  watch: {
    archived() {
      this.refresh();
    },
  },
  async created() {
    this.setTitle(this.$t("drivers.owners"));
  },
  methods: {
    getItemClass(item) {
      return item.ownerStatus === "h" ? "status-hold" : "";
    },
    getStatusClass(status) {
      let color = "bg-grey-lighten-4 text-grey-darken-3";
      switch (status) {
        case "a":
          color = "bg-green-lighten-2 text-white";
          break;
        case "h":
          color = "bg-red-lighten-2 text-white";
          break;
      }
      return color;
    },
    async refresh() {
      this.loading = true;
      const filter = { ...this.filter, ...this.params };
      filter.archived = this.archived;

      try {
        const resp = await this.$api.users.findOwners(filter);
        this.data = resp.data;
      } catch (error) {
        console.error("Error load owners list: ", error);
      }

      this.loading = false;
    },
    async search(params) {
      this.params.page = 1;
      if (params.phone) params.phone = this.normalizePhone(params.phone);
      this.filter = params;

      await this.refresh();
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100 owner-page">
    <page-header :header="$t('users.owners')">
      <template #suffix>
        <link-button id="create-user-btn" :to="{ name: 'user-create' }" color="success">
          {{ $t("general.create-new") }}
        </link-button>
      </template>
    </page-header>
    <owners-search-form @search="search" />

    <omni-table
      v-model:archived="archived"
      :footer-props="{ 'items-per-page-options': pagination.itemsPerPage }"
      :headers="headers"
      :items="data.items"
      :items-length="data.total"
      :items-per-page="params.pageSize"
      :loading="loading"
      :mobile-breakpoint="0"
      :page="params.page"
      show-archive
      @update:page="updatePage"
      @update:items-per-page="updatePageSize"
    >
      <template #[`item.name`]="{ item }">
        <div :class="getItemClass(item)">
          <user-role :user="item" />
          <router-link
            :to="{ name: 'user-view', params: { id: item.id } }"
            class="text-decoration-none text-primary owner-name"
          >
            <mobile-status :user="item"></mobile-status>
            {{ item.name }}
          </router-link>
        </div>
      </template>
      <template #[`item.status`]="{ item }">
        <span :class="getStatusClass(item.ownerStatus)" class="caption pr-2 pl-2 table-status text-white rounded-lg">
          {{ $t("drivers.status-" + item.ownerStatus) }}
        </span>
      </template>
      <template #[`item.phone`]="{ item }">
        <div class="d-flex align-center">
          <phone-number :number="item.mobilePhone" class="text-decoration-none text-primary" is-callable />
          <span
            class="ml-2 text-uppercase bg-grey-lighten-4 rounded text-grey-darken-1 pr-1 pl-1 caption type-language"
          >
            {{ item.language }}
          </span>
        </div>
      </template>
      <template #[`item.coordinators`]="{ item }">
        <dropdown-list :count="1" :items="item.coordinators" :route="'user-view'" label="Coordinators" />
      </template>
      <template #[`item.drivers`]="{ item }">
        <dropdown-list :items="item.drivers" :label="$t('drivers.drivers')" :route="'user-view'" />
      </template>
      <template #[`item.address`]="{ item }">
        {{ item.fullAddress }}
      </template>
      <template #[`item.trucks`]="{ item }">
        <dropdown-list :field="'number'" :items="item.trucks" :label="$t('trucks.trucks')" :route="'truck-view'" />
      </template>
      <template #[`item.email`]="{ item }">
        <span v-clipboard="item.email">
          {{ item.email }}
        </span>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center">
          <v-btn
            :to="{ name: 'user-view', params: { id: item.id } }"
            class="pa-1 owners-table-action"
            size="small"
            variant="flat"
          >
            <v-icon class="text-primary">mdi-eye-outline</v-icon>
          </v-btn>
          <open-chat-button :user="item" bg-color="white"></open-chat-button>
        </div>
      </template>
    </omni-table>
  </v-container>
</template>

<style lang="scss" scoped>
.owner-name {
  display: block;
  margin-right: 50px;
}

.table-status {
  line-height: 16px !important;
  white-space: nowrap;
}

.owners-table-action {
  width: auto !important;
  min-width: auto !important;
  padding: 0 2px !important;
  background: transparent !important;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.type-language {
  line-height: 16px;
}

.owner-page {
  & > .v-card {
    background: transparent;
  }
}

td:has(.status-hold) {
  border-left: 3px solid #ff5252 !important;
}
</style>
