<script lang="ts" setup>
import { defineProps, withDefaults } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = withDefaults(
  defineProps<{
    id?: string;
    color?: string;
    to: Record<string, any>;
  }>(),
  {
    color: "primary",
  },
);

const click = () => {
  router.push(props.to);
};
</script>

<template>
  <v-btn :id="props.id" :color="props.color" class="text-uppercase" variant="flat" @click="click">
    <slot></slot>
  </v-btn>
</template>

<style lang="scss" scoped></style>
