type ItemWithId = {
  id: string | number;
  // Add other properties if needed
};

export const UtilsMixin = {
  methods: {
    mergeById(array1: ItemWithId[], array2: ItemWithId[]): ItemWithId[] {
      const ids = new Set(array1.map((d) => d.id));
      return [...array1, ...array2.filter((d) => !ids.has(d.id))];
    },
  },
};
