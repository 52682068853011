<script>
import OmniTextarea from "@/components/inputs/Textarea.vue";
import OmniDialog from "@/components/Dialog.vue";

export default {
  name: "TruckNotes",
  components: { OmniDialog, OmniTextarea },
  props: {
    truck: Object,
  },
  emits: ["update"],
  data() {
    return {
      editNoteDialog: false,
      field: null,
      note: "",
      menu: false,
    };
  },
  methods: {
    editNote(field, note) {
      this.field = field;
      this.note = note;
      this.editNoteDialog = true;
    },
    async saveNote() {
      let resp = null;
      switch (this.field) {
        case "note":
          resp = await this.$api.trucks.updateTruck(this.truck.id, { note: this.note });
          if (resp.success) {
            const truck = { ...this.truck };
            truck.note = resp.data.note;
            this.$emit("update", truck);
          }
          break;

        case "driver.note":
          resp = await this.$api.users.updateDriver(this.truck.driverId, { note: this.note });
          if (resp.success) {
            const truck = { ...this.truck };
            truck.driver.note = resp.data.note;
            this.$emit("update", truck);
          }
          break;

        case "driver.driverNote":
          resp = await this.$api.users.updateDriver(this.truck.driverId, {
            driverNote: this.note,
          });
          if (resp.success) {
            const truck = { ...this.truck };
            truck.driver.driverNoteText = resp.data.driverNote;
            this.$emit("update", truck);
          }
          break;

        case "driver2.note":
          resp = await this.$api.users.updateDriver(this.truck.driver2Id, { note: this.note });
          if (resp.success) {
            const truck = { ...this.truck };
            truck.driver.note = resp.data.note;
            this.$emit("update", truck);
          }
          break;

        case "driver2.driverNote":
          resp = await this.$api.users.updateDriver(this.truck.driver2Id, {
            driverNote: this.note,
          });
          if (resp.success) {
            const truck = { ...this.truck };
            truck.driver2.driverNoteText = resp.data.driverNote;
            this.$emit("update", truck);
          }
          break;
      }
      this.editNoteDialog = false;
    },
  },
};
</script>

<template>
  <v-menu v-model="menu" :close-on-content-click="false" :width="500" class="quote-menu" offset="10">
    <template #activator="{ props }">
      <v-btn class="pa-0 quote-btn" size="small" v-bind="props" variant="flat">
        <v-icon class="text-primary">mdi-format-quote-close</v-icon>
      </v-btn>
    </template>
    <v-card class="elevation-12 border">
      <div class="pt-5 pb-5 pr-6 pl-6">
        <v-row class="mb-4">
          <v-col>
            <div class="text-grey-darken-3 text-body-2 mb-2">Truck note</div>
            <div class="text-grey-darken-2 font-size-12">
              {{ $filters.mdash(truck.note) }}
              <span class="ml-2">
                <v-icon color="primary" size="small" @click="editNote('note', truck.note)">mdi-pencil-outline</v-icon>
              </span>
            </div>
          </v-col>
          <v-col />
        </v-row>
        <v-row v-if="truck.driver" class="mb-4">
          <v-col>
            <div>
              <div class="text-grey-darken-3 text-body-2 mb-2">Driver note</div>
              <div class="text-grey-darken-2 font-size-12">
                {{ $filters.mdash(truck.driver?.driverNoteText) }}
                <span class="ml-2">
                  <v-icon
                    color="primary"
                    size="small"
                    @click="editNote('driver.driverNote', truck.driver.driverNoteText)"
                  >
                    mdi-pencil-outline</v-icon
                  >
                </span>
              </div>
            </div>
          </v-col>
          <v-col>
            <div>
              <div class="text-grey-darken-3 text-body-2 mb-2">Dispatcher note (1st driver)</div>
              <div class="text-grey-darken-2 font-size-12">
                {{ $filters.mdash(truck.driver.note) }}
                <v-icon color="primary" size="small" @click="editNote('driver.note', truck.driver.note)">
                  mdi-pencil-outline
                </v-icon>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="truck.driver2">
          <v-col>
            <div>
              <div class="text-grey-darken-3 text-body-2 mb-2">Second driver note</div>
              <div class="text-grey-darken-2 font-size-12">
                {{ $filters.mdash(truck.driver2.driverNoteText) }}
                <v-icon
                  color="primary"
                  size="small"
                  @click="editNote('driver2.driverNote', truck.driver2.driverNote)"
                >
                  mdi-pencil-outline
                </v-icon>
              </div>
            </div>
          </v-col>
          <v-col>
            <div>
              <div class="text-grey-darken-3 text-body-2 mb-2">Dispatcher note (2nd driver)</div>
              <div class="text-grey-darken-2 font-size-12">
                {{ $filters.mdash(truck.driver2.note) }}
                <v-icon color="primary" size="small" @click="editNote('driver2.note', truck.driver2.note)">
                  mdi-pencil-outline
                </v-icon>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <omni-dialog
      v-model="editNoteDialog"
      primary-action-label="Save"
      size="small"
      title="Edit note"
      @primary-action:click="saveNote"
    >
      <omni-textarea v-model="note" class="my-4" rows="4" />
    </omni-dialog>
  </v-menu>
</template>

<style lang="scss" scoped>
.border {
  border: solid 1px #cccccc;
}

.v-menu__content {
  box-shadow: 0 8px 20px -7px rgba(0, 0, 0, 0.1) !important;
}

.quote-btn {
  position: absolute;
  top: 2px;
  right: 4px;
  min-width: auto !important;
  height: auto !important;
  background-color: transparent !important;
}
</style>
