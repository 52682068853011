<script>
export default {
  name: "FmcsaNumberInput",
  props: {
    modelValue: [String, null],
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<template>
  <v-text-field
    v-model="model"
    :label="$t('company.fmcsa_number')"
    :readonly="readonly"
    autocomplete="off1"
    color="primary"
    density="compact"
    hide-details="auto"
    maxlength="6"
    name="fmcsaNumber"
    variant="outlined"
  />
</template>

<style lang="scss" scoped></style>
