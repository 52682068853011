<script lang="ts" setup>
import { computed, defineProps, ref } from "vue";

const props = withDefaults(
  defineProps<{
    items: string[];
    itemClass?: string;
    limit?: number;
  }>(),
  { limit: 1 },
);

const popover = ref(false);

const itemClasses = computed(() => props.itemClass || "text-grey-darken-2 font-size-14");

const closePopover = () => {
  popover.value = false;
};
</script>

<template>
  <div v-if="props.items.length > 0" class="show-more__items">
    <v-tooltip
      v-for="(item, index) in props.items.slice(0, props.limit).join(', %').split('%')"
      :key="index"
      :disabled="item.length <= 12"
      location="top start"
    >
      <template #activator="{ props: tooltipProps }">
        <span v-clipboard.uncolored="item" :class="itemClasses" class="item" v-bind="tooltipProps">
          {{ $filters.ellipsis(item, 15) }}
        </span>
      </template>
      {{ item }}
    </v-tooltip>
    <v-menu
      v-if="props.items.length > props.limit"
      v-model="popover"
      :close-on-content-click="false"
      :nudge-width="200"
      class="quote-menu"
      offset="10"
    >
      <template #activator="{ props: menuProps }">
        <span
          v-if="props.items.length > props.limit"
          class="text-primary font-size-12 font-weight-600 cursor-pointer ml-2"
          v-bind="menuProps"
        >
          ({{ props.items.length - props.limit }} more)
        </span>
      </template>
      <v-card class="show-more__items">
        <v-icon class="close-btn" size="small" @click="closePopover">mdi-close</v-icon>
        <v-card-text>
          <div class="font-size-16 font-weight-500 mb-3 pr-4">Ref numbers</div>
          <p v-for="(item, i) in props.items" :key="i" v-clipboard.uncolored="item" class="item">
            {{ item }}
          </p>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.close-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.show-more__items {
  display: flex;
  align-items: center;

  .item {
    transition: all ease-in-out 200ms;
    cursor: pointer;

    &:hover {
      color: $primary !important;
    }
  }
}
</style>
