<script>
export default {
  name: "OrderCertificates",
  props: {
    order: Object,
  },
};
</script>

<template>
  <div class="certificates-holder">
    <span
      v-for="(certificate, index) in order.requiredDriverCertificates"
      :key="index"
      class="certificate-chip d-inline-flex justify-center align-center bg-grey-lighten-4 rounded font-size-11 text-grey-darken-1"
    >
      {{ certificate }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.certificates-holder {
  display: inline-block;
  width: 120px;

  .certificate-chip {
    height: 16px;
    padding: 2px 4px;
    margin-left: 2px;

    &:not(:last-of-type) {
      margin-right: 2px;
    }
  }
}
</style>
