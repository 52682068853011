<script>
import OmniDialog from "@/components/Dialog.vue";
import LocationPicker from "@/components/pickers/LocationPicker.vue";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import DatePicker from "@/components/pickers/DatePicker.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "DispatchConfirmationAction",
  components: { TimePicker, DatePicker, OmniSelect, LocationPicker, OmniDialog },
  mixins: [ValidationMixin, DateTimeMixin],
  inheritAttrs: false,
  props: {
    trip: Object,
    route: Object,
  },
  emits: ["dispatched"],
  data() {
    return {
      showDialog: false,
      loading: false,
      dispatchData: {},
    };
  },
  watch: {
    "dispatchData.location"(value) {
      this.dispatchData.timezone = value.timezone;
      const date = this.$dayjs().tz(this.dispatchData.timezone);
      this.dispatchData.date = date.format("YYYY-MM-DD");
      this.dispatchData.time = date.format("HH:mm");
    },
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    showDispatchConfirmation() {
      this.showDialog = true;
    },
    async confirmDispatch() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      const response = await this.$api.trips.confirmRouteDispatch(this.trip.id, this.route.id, {
        dispatchAddress: this.dispatchData.location.address.fullAddress,
        dispatchCoordinates: {
          lat: this.dispatchData.location.lat,
          lon: this.dispatchData.location.lon,
        },
        dispatchTime: this.dateToUTC(
          this.dispatchData.date + " " + this.dispatchData.time,
          this.dispatchData.timezone,
        ),
      });
      if (response.success) {
        this.$emit("dispatched");
        this.showDialog = false;
      } else {
        this.showSnackbarError("Error dispatching route");
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <v-btn
    v-if="!route?.dispatchConfirmed"
    block
    class="text-uppercase font-weight-600"
    color="primary"
    v-bind="$attrs"
    variant="flat"
    @click="showDispatchConfirmation"
  >
    Confirm dispatch
  </v-btn>
  <omni-dialog
    v-model="showDialog"
    :disabled="loading"
    :loading="loading"
    primary-action-label="Save"
    title="Dispatch confirmation"
    @primary-action:click="confirmDispatch"
  >
    <v-form ref="form" class="py-6">
      <div class="pb-6 text-grey-darken-1">
        You are about to confirm dispatch for
        <router-link
          :to="{ name: 'truck-view', params: { id: route.$truck?.id } }"
          class="text-primary text-decoration-none"
          target="_blank"
        >
          Truck #{{ route.$truck?.number }}
        </router-link>
        :
      </div>
      <v-row>
        <v-col>
          <location-picker v-model="dispatchData.location" required></location-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <omni-select
            v-model="dispatchData.timezone"
            :disabled="!dispatchData.location"
            :items="timezonesShortList"
            :rules="[requiredValidator]"
            data-qa="timezone"
            label="Time zone"
            required
          />
        </v-col>
        <v-col cols="5">
          <date-picker
            v-model="dispatchData.date"
            :disabled="!dispatchData.location"
            label="Date"
            required
          ></date-picker>
        </v-col>
        <v-col cols="3">
          <time-picker
            v-model="dispatchData.time"
            :disabled="!dispatchData.location"
            label="Time"
            required
          ></time-picker>
        </v-col>
      </v-row>
    </v-form>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
