<script>
import BaseView from "@/views/BaseView.vue";
import TruckEditForm from "@/views/trucks/edit/TruckEditForm.vue";
import { defineComponent } from "vue";
import NotFoundMixin from "@/mixins/not-found.mixin";
import NotFound from "@/views/misc/NotFound.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default defineComponent({
  name: "TruckEdit",
  components: { NotFound, TruckEditForm },
  mixins: [BaseView, NotFoundMixin],
  data() {
    return {
      truck: {
        signs: true,
        status: "n",
      },
      loading: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
  },
  async created() {
    await this.loadInfo();
  },
  methods: {
    async loadInfo() {
      if (this.$route.params.id === "new") {
        this.setTitle("New truck ", "Trucks");
        this.truck.hiredById = this.dispatcher.id;
        return;
      }

      this.loading = true;

      const resp = await this.$api.trucks.findTruckById(this.$route.params.id);
      if (!this.validateEntityResponse(resp)) return;
      this.truck = resp.data;
      if (this.truck.archived) {
        return this.$router.replace({ name: "truck-view", params: { id: this.truck.id } });
      }
      this.setTitle("Truck " + this.truck.number, "Trucks");

      this.loading = false;
    },
  },
});
</script>

<template>
  <v-container v-if="!entityNotFound" :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100 trucks-edit-page">
    <div class="edit-trucks">
      <truck-edit-form :truck="truck" />
    </div>
  </v-container>
  <not-found v-else icon="mdi-truck-remove-outline" text="Truck not found"></not-found>
</template>

<style lang="scss" scoped>
.edit-trucks {
  padding-bottom: 60px;
}
</style>
