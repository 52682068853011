<script>
import OpenChatButton from "@/components/controls/OpenChatButton.vue";
import UserStatus from "@/components/status/UserStatus.vue";
import DriverCertificates from "@/views/drivers/DriverCertificates.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";

export default {
  name: "TruckContactInfo",
  components: { PhoneNumber, DriverCertificates, UserStatus, OpenChatButton },
  props: {
    label: String,
    user: Object,
    fullAccess: Boolean,
    showCertificates: { type: Boolean, default: false },
    isContactPerson: { type: Boolean, default: false },
    showStatus: { type: Boolean, default: true },
  },
  data() {
    return {
      driverData: null,
    };
  },
};
</script>

<template>
  <div class="position-relative" data-qa="user-block">
    <div class="block-item__labels">
      <driver-certificates v-if="showCertificates" :user="user" />
    </div>
    <div class="text-grey-darken-3 mb-3">{{ label }}</div>
    <div class="d-flex align-center mb-3">
      <span v-if="isContactPerson" class="currency-icon mr-2 small" />
      <router-link
        :to="{ name: 'user-view', params: { id: user.id } }"
        class="font-size-14 font-weight-500 text-primary text-decoration-none mr-2"
        data-qa="user-name"
      >
        {{ user.name }}
      </router-link>
      <div v-if="fullAccess" class="text-green mx-2">
        <v-icon size="small">mdi-check</v-icon>
      </div>
      <template v-if="showStatus">
        <v-tooltip location="top">
          <template #activator="{ props }">
            <user-status v-if="user.isDriver" :status="user.driverStatus" v-bind="props"></user-status>
          </template>
          <span class="text-body-2">Driver status</span>
        </v-tooltip>
        <span v-if="user.isDriver && user.isOwner" class="mx-1">/</span>
        <v-tooltip location="top">
          <template #activator="{ props }">
            <user-status v-if="user.isOwner" :status="user.ownerStatus" v-bind="props"></user-status>
          </template>
          <span class="text-body-2">Owner status</span>
        </v-tooltip>
      </template>
    </div>
    <div class="d-flex align-center mb-2">
      <phone-number :number="user.phone" class="font-size-12 mr-2" />
      <span class="text-uppercase bg-grey-lighten-4 rounded text-grey-darken-1 pr-1 pl-1 caption type-language">
        {{ $filters.upper(user.language) }}
      </span>
      <v-spacer></v-spacer>
      <open-chat-button :user="user"></open-chat-button>
    </div>
    <div class="mb-3 email-block">
      <span class="text-primary text-decoration-none font-size-12">{{ user.email }}</span>
    </div>
    <div class="d-flex align-center text-grey-darken-3 mb-3">
      <v-icon class="mr-1" size="small">mdi-home-outline</v-icon>
      <span class="font-size-12" data-qa="user-address">
        {{ user.fullAddress }}
      </span>
    </div>
    <div class="d-flex">
      <span
        v-if="user.isOwner"
        class="d-inline-block text-green-lighten-1 caption bg-grey-lighten-4 rounded-lg pr-1 pl-1 block-item__status text-uppercase mr-1"
        data-qa="user-owner"
      >
        {{ $filters.upper($t("drivers.owner")) }}
      </span>
      <span
        v-if="user.isDriver"
        class="d-inline-block text-indigo-lighten-2 caption bg-grey-lighten-4 rounded-lg pr-1 pl-1 block-item__status text-uppercase mr-1"
        data-qa="user-driver"
      >
        {{ $filters.upper($t("drivers.driver")) }}
      </span>
      <span
        v-if="user.isCoordinator"
        class="d-inline-block text-blue-lighten-2 caption bg-grey-lighten-4 rounded-lg pr-1 pl-1 block-item__status text-uppercase"
        data-qa="user-coordinator"
      >
        {{ $filters.upper($t("users.coordinator")) }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.block-item__labels {
  position: absolute;
  top: -8px;
  right: -4px;
  display: flex;
  justify-content: right;
}

.email-block {
  line-height: 16px;
}

.type-language {
  padding: 2px 0;
}

.block-item__status {
  line-height: 16px !important;
}
</style>
