<script>
import OrderBlock from "@/views/trips/edit/blocks/OrderBlock.vue";
import TravelOrderBlock from "@/views/trips/edit/blocks/TravelOrderBlock.vue";
import StopBlock from "@/views/trips/edit/blocks/StopBlock.vue";
import RouteBlock from "@/views/trips/edit/blocks/RouteBlock.vue";
import AddOrderDialog from "@/views/trips/edit/dialogs/AddOrderDialog.vue";
import ReorderOrderStops from "@/views/trips/edit/dialogs/ReorderOrderStops.vue";
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import AddReloadStopDialog from "@/views/trips/edit/dialogs/AddReloadStopDialog.vue";
import BaseView from "@/views/BaseView.vue";
import OmniDialog from "@/components/Dialog.vue";
import { TravelOrderCancellationStatusMap } from "@/data/trip";
import AlertMessage from "@/components/AlertMessage.vue";
import { OrderStopState } from "@/data/order";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "TripEdit",
  components: {
    AlertMessage,
    OmniDialog,
    AddReloadStopDialog,
    ReorderOrderStops,
    AddOrderDialog,
    RouteBlock,
    StopBlock,
    TravelOrderBlock,
    OrderBlock,
  },
  mixins: [BaseView, OrdersMixin],
  data() {
    return {
      orders: [],
      orderStops: [],
      routes: [],

      deliveryNum: 0,
      pickupNum: 0,

      addOrderDialog: false,
      reorderOrderStopsDialog: false,

      addReloadStopDialog: false,
      reloadStopIndex: 1,
    };
  },
  computed: {
    orderFreights() {
      let freights = [];

      for (const order of this.orders) {
        freights = freights.concat(order.freights);
      }

      return freights;
    },
    saveEnabled() {
      return this.routes?.every((r) => r.$isDispatched === true);
    },
    cancelEnabled() {
      return this.$route.params.id && this.$route.params.id !== "new";
    },
  },
  async created() {
    await this.loadTrip();
    if (this.$route.query.order) {
      const order = await this.loadOrder(this.$route.query.order);
      if (order) {
        this.addOrder(order, false);
      }
    }
    if (this.$route.query.truck) {
      const truck = await this.loadTruck(this.$route.query.truck);
      if (truck) {
        this.addRoute(truck);
      }
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarSuccess", "showSnackbarError"]),
    generateStopName(stop) {
      stop.name = stop.type === this.STOP_PICK_UP ? "Pick up" : "Delivery";
      if (stop.isReload) stop.name += " reload stop";
      if (stop.num) stop.name += " #" + stop.num;

      return stop;
    },
    async loadTrip() {
      if (this.$route.params.id === "new") return;

      const resp = await this.$api.trips.getTripById(this.$route.params.id);
      if (resp.success) {
        await this.processAPITrip(resp.data);
      }
    },
    async processAPITrip(trip) {
      const routes = trip.routes;

      // calculate max stops count in column
      const maxStopsCount = Math.max(...routes.map((r) => r.routeStops[r.routeStops.length - 1].position));

      // collect orders
      for (const route of routes) {
        // route dispatched because it was saved
        route.$isDispatched = route.redispatch !== "needed";

        // load truck
        if (route.truckId) {
          route.truck = await this.loadTruck(route.truckId);
        }

        // load orders & update travel orders
        this.orders = [];
        this.orderStops = [];
        const orders = {};
        for (const travelOrder of route.travelOrders) {
          if (orders[travelOrder.orderId] === undefined) {
            const order = await this.loadOrder(travelOrder.orderId);
            if (order) {
              orders[order.id] = order;
              // add order only once
              this.addOrder(order, false);
            }
          }

          travelOrder.order = orders[travelOrder.orderId];
          // we don't have travel order number, so use ID for it
          travelOrder.number = travelOrder.id;
        }
      }

      // collect facilities from order stops
      const facilities = {};
      for (const order of this.orders) {
        for (const orderStop of order.orderStops) {
          facilities[orderStop.facilityId] = orderStop.facility;
        }
        for (const orderStop of order.orderStops) {
          if (orderStop.oldFacilityId) {
            if (!facilities[orderStop.oldFacilityId]) {
              const res = await this.$api.company.findFacilityById(orderStop.oldFacilityId);
              if (res.success) {
                facilities[orderStop.oldFacilityId] = res.data;
              }
            }
            orderStop.$oldFacility = facilities[orderStop.oldFacilityId];
          }
        }
      }

      // update facilities in route stops
      for (const route of routes) {
        for (let i = 0; i < route.routeStops.length; i++) {
          const stop = route.routeStops[i];
          stop.use = true;
          this.generateStopName(stop);

          // add reload stop to trip stops
          if (stop.isReload && stop.type === this.STOP_PICK_UP) {
            const reloadStop = {
              type: this.STOP_RELOAD,
              isReload: true,
              facility: stop.facility,
              facilityId: stop.facilityId,
            };
            this.generateStopName(reloadStop);
            this.orderStops.splice(stop.position - 1, 0, reloadStop);
          }

          const orderStop = this.orderStops.find((os) => os.id === stop.orderStopId);
          if (orderStop.state === OrderStopState.updated) {
            stop.facility = orderStop.facility;
            stop.oldFacilityId = stop.facilityId;
            stop.facilityId = stop.facility?.id;
          } else {
            stop.facility = facilities[stop.facilityId];
          }
        }

        // add not used route stops
        if (route.routeStops[0].position > 1) {
          for (let pos = 1; pos < route.routeStops[0].position; pos++) {
            route.routeStops.splice(0, 0, { name: "", use: false });
          }
        }
        for (let pos = route.routeStops[route.routeStops.length - 1].position; pos < maxStopsCount; pos++) {
          route.routeStops.push({ name: "", use: false });
        }
      }

      this.routes = routes;
    },
    buildRouteStopFromOrderStop(orderStop) {
      const routeStop = {
        orderStopId: orderStop.id,
        orderId: orderStop.orderId,
        order: { id: orderStop.order.orderId, number: orderStop.order.number },
        type: orderStop.type,
        facility: orderStop.facility,
        facilityId: orderStop.facilityId,

        freights: [...orderStop.freights],

        editable: this.routes?.length > 1,
        isReload: false,
        use: true,
        status: orderStop.status,
        note: orderStop.note,

        timezone: orderStop.timezone,

        timeType: orderStop.timeType,
        timeFrom: orderStop.timeFrom,
        timeTo: orderStop.timeTo,
        time2Type: orderStop.time2Type,
        time2From: orderStop.time2From,
        time2To: orderStop.time2To,

        dispatchedTimeType: orderStop.timeType,
        dispatchedTimeFrom: orderStop.timeFrom,
        dispatchedTimeTo: orderStop.timeTo,
      };
      this.generateStopName(routeStop);
      return routeStop;
    },
    addOrder(order, showReorderDialog = true) {
      this.addOrderDialog = false;
      delete order.polyline;
      this.orders.push(order);

      // add travel orders to routes
      for (const route of this.routes) {
        route.travelOrders.push({ rate: 0, order: order, orderId: order.id, number: "", status: "new" });
      }

      // add order stops
      for (const orderStop of order.orderStops) {
        orderStop.use = true; // stop always "use" for trip stops
        orderStop.order = { orderId: order.id, number: order.number, freights: order.freights, editable: false };
        this.orderStops.push(orderStop);
        this.numerateOrderStops();

        // add order stop as route stop for all routes
        for (const route of this.routes) {
          route.routeStops.push(this.buildRouteStopFromOrderStop(orderStop));
        }
      }

      this.reorderOrderStopsDialog = showReorderDialog;
    },
    removeOrder(order) {
      // remove order from orders list
      const index = this.orders.indexOf(order);
      if (index !== -1) {
        this.orders.splice(index, 1);
      }

      // remove order stops
      this.orderStops = this.orderStops.filter((os) => os.orderId !== order.id);

      // remove route stops for deleted order
      for (const route of this.routes) {
        route.routeStops = route.routeStops.filter((rs) => rs.orderId !== order.id);
      }
    },
    addRoute(truck = null, copyData = true) {
      const route = { truck: truck, travelOrders: [], routeStops: [], sharedFiles: [] };

      if (copyData) {
        let position = 1;
        for (const orderStop of this.orderStops) {
          const stop = this.buildRouteStopFromOrderStop(orderStop);

          if (stop.type === this.STOP_RELOAD) {
            // add reload route stops

            const deliveryReloadRouteStop = { ...stop, type: this.STOP_DELIVERY, isReload: true, position };
            this.generateStopName(deliveryReloadRouteStop);
            route.routeStops.push(deliveryReloadRouteStop);

            position++;

            const pickupReloadRouteStop = { ...stop, type: this.STOP_PICK_UP, isReload: true, position };
            this.generateStopName(pickupReloadRouteStop);
            route.routeStops.push(pickupReloadRouteStop);
          } else {
            route.routeStops.push(stop);
          }

          position++;
        }

        for (const i in this.orders) {
          route.travelOrders.push({
            rate: 0,
            order: this.orders[i],
            orderId: this.orders[i].id,
            number: i + 1,
            status: "new",
          });
        }
      }

      this.routes.push(route);
    },
    async loadOrder(orderId) {
      let order = null;
      const resp = await this.$api.orders.findOrderById(orderId);
      if (resp.success) {
        // set freights numbers in order
        resp.data.freights.map((f, i) => {
          f.number = i + 1;
          return f;
        });
        order = { ...resp.data, files: [] };
      }
      if (order === null) return null;

      // load order files for trips (skip rate confirmation files)
      const fileResp = await this.$api.orders.findOrderFiles(order.id);
      if (fileResp.success) {
        order.files = fileResp.data;
      }

      return order;
    },
    async loadTruck(truckId) {
      const resp = await this.$api.trucks.findTruckById(truckId);
      if (resp.success) {
        return resp.data;
      }
      return null;
    },
    numerateOrderStops() {
      let position = 1;
      let deliveryNum = 1;
      let pickupNum = 1;

      for (const orderStop of this.orderStops) {
        orderStop.position = position;
        position++;

        if (orderStop.type === this.STOP_DELIVERY) {
          orderStop.num = deliveryNum;
          deliveryNum++;
        }
        if (orderStop.type === this.STOP_PICK_UP) {
          orderStop.num = pickupNum;
          pickupNum++;
        }

        this.generateStopName(orderStop);
      }
    },
    closeReorderStopsDialog() {
      this.reorderOrderStopsDialog = false;

      this.numerateOrderStops();

      // reorder reload stops
      for (const route of this.routes) {
        const routeStops = [...route.routeStops];
        route.routeStops = [];

        for (const orderStop of this.orderStops) {
          for (const routeStop of routeStops) {
            if (routeStop.orderStopId === orderStop.id) {
              route.routeStops.push(routeStop);
            }
          }
        }
      }
    },
    showReloadStopDialog(index) {
      this.addReloadStopDialog = true;
      this.reloadStopIndex = index;
    },
    addReloadStop(reloadStop) {
      const index = this.reloadStopIndex + 1;
      reloadStop.position = index + 1;
      this.orderStops.splice(index, 0, reloadStop);

      // insert reload route stops after adding new reload stop
      const deliveryStop = { ...reloadStop, type: this.STOP_DELIVERY, use: false },
        pickUpStop = { ...reloadStop, type: this.STOP_PICK_UP, use: false };
      this.generateStopName(deliveryStop);
      this.generateStopName(pickUpStop);
      for (const route of this.routes) {
        route.routeStops.splice(index, 0, deliveryStop);
        route.routeStops.splice(index + 1, 0, pickUpStop);
      }
    },
    async save() {
      const item = { routes: this.routes };
      // add trip id for redispatch and save exists trip
      if (this.$route.params.id !== "new") {
        item.id = this.$route.params.id;
      }

      item.routes.forEach((route) => {
        // @todo: refactor this setup during splits implementation
        route.freights = this.orderFreights.map((of) => ({
          ...of,
          freightId: of.id,
          plannedWeight: of.weight,
          plannedQuantity: of.quantity,
        }));
        route.truckId = route.truck.id;
        route.routeStops.forEach((stop, index) => {
          stop.position = index;
        });
      });
      const resp = await this.$api.trips.upsertTrip(item);
      if (resp.success) {
        this.toView(resp.data.id);
        this.showSnackbarSuccess(this.$t("general.saved"));
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
    setTruck(route, truck) {
      if (truck) {
        route.truck = truck;
        route.truckId = truck.id;
      } else {
        route.truck = null;
        route.truckId = null;
      }
    },
    toView(id) {
      this.$router.push({ name: "trip-view", params: { id: id || this.$route.params.id } });
    },
    isRouteStopDisabled(route, stop) {
      const travelOrder = route.travelOrders.find((to) => to.orderId === stop.order?.id);
      return travelOrder ? this.isTravelOrderCancelled(travelOrder) : false;
    },
    isTravelOrderCancelled(travelOrder) {
      return TravelOrderCancellationStatusMap.some((s) => s.id === travelOrder.status);
    },
    isOrderChanged(order) {
      return order.orderStops.some((os) => os.state !== OrderStopState.normal);
    },
    canChangeOrderStatus(travelOrder) {
      return this.routes
        .flatMap((r) => r.travelOrders.filter((to) => to.orderId === travelOrder.orderId && to.id !== travelOrder.id))
        .every((to) => TravelOrderCancellationStatusMap.some((s) => s.id === to.status));
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-8 pb-10 pl-6 pr-6">
    <div class="pb-15">
      <div>
        <v-row>
          <v-col col="6" xl="3">
            <div class="trip-block mb-4" data-qa="trip-block-main">
              <div class="trip-block__inner bg-white pa-4 rounded-lg">
                <div class="trip-block__header d-flex align-center justify-space-between mb-3">
                  <span class="text-grey-darken-2 font-weight-medium">Trip</span>
                  <v-menu offset-y>
                    <template #activator="{ props }">
                      <v-btn class="btn-custom pa-0" v-bind="props">
                        <v-icon class="text-grey-darken-1" size="14">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="() => addRoute(null, true)">
                        <v-list-item-title data-qa="add-route">Add route</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="addOrderDialog = true">
                        <v-list-item-title data-qa="add-order">Add order</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <template v-for="order in orders" :key="order.id">
                  <alert-message v-if="isOrderChanged(order)" bg-color="grey-lighten-4" data-qa="update-order-message">
                    Order #{{ order.number }} has been changed, please check details below
                  </alert-message>
                </template>
              </div>
            </div>

            <order-block
              v-for="order in orders"
              :key="order.id"
              :can-edit="orders.length > 1"
              :order="order"
              class="mb-4"
              @remove="removeOrder"
            />

            <div
              class="trip-block bg-white pa-4 pt-0 rounded-lg position-relative rounded-tl-0 rounded-tr-0 rounded-bl-0 rounded-br-0"
            >
              <div class="trip-block__inner">
                <div class="trip-stops__blocks">
                  <div class="trip-block__header d-flex align-center justify-space-between my-2">
                    <span class="text-grey-darken-2 font-weight-medium">Trip stops</span>
                    <v-btn
                      elevation="0"
                      icon="mdi-dots-horizontal"
                      size="x-small"
                      @click="showReloadStopDialog = false"
                    />
                  </div>

                  <div v-for="(stop, i) in orderStops" :key="stop.id">
                    <stop-block :order-freights="orderFreights" :stop="stop" is-order-stop />
                    <div v-if="i < orderStops.length - 1" class="plus-block text-center">
                      <v-btn
                        class="text-primary plus-btn"
                        elevation="0"
                        icon="mdi-plus"
                        size="x-small"
                        @click="() => showReloadStopDialog(i)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col v-for="(route, i) in routes" :key="i" cols="6" xl="3">
            <route-block
              v-model="routes[i]"
              :can-edit="routes.length > 1"
              class="mb-4"
              @attach="(p) => setTruck(routes[i], p.truck)"
              @remove-truck="setTruck(routes[i], null)"
              @remove-route="routes.splice(i, 1)"
            />

            <travel-order-block
              v-for="(travelOrder, j) in route.travelOrders"
              :key="j"
              v-model="route.travelOrders[j]"
              :can-change-order-status="canChangeOrderStatus(travelOrder)"
              :trip-id="$route.params.id"
              class="mb-4"
              @change="loadTrip"
            />

            <div
              v-if="route.routeStops.length > 0"
              class="trip-block bg-white pa-4 rounded-lg position-relative rounded-tl-0 rounded-tr-0 rounded-bl-0 rounded-br-0"
              data-qa="route-stops"
            >
              <div class="trip-block__inner">
                <div class="trip-stops__blocks">
                  <div class="trip-block__header d-flex align-center justify-space-between mb-3">
                    <span class="text-grey-darken-2 font-weight-medium">Route stops</span>
                  </div>
                  <stop-block
                    v-for="(stop, k) in route.routeStops"
                    :key="k"
                    :class="{ 'mt-8': k !== 0 }"
                    :disabled="isRouteStopDisabled(route, stop)"
                    :order-freights="orderFreights"
                    :stop="stop"
                  />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex justify-end pa-5 bg-white user-info__buttons">
        <v-btn
          v-if="cancelEnabled"
          id="cancel-btn"
          class="pr-6 pl-6 font-weight-600 text-uppercase"
          color="primary"
          height="36"
          variant="text"
          @click="() => toView()"
        >
          Cancel
        </v-btn>
        <v-btn
          id="save-btn"
          :disabled="!saveEnabled"
          class="pr-6 pl-6 font-weight-600 text-uppercase"
          color="primary"
          height="36"
          variant="flat"
          @click="save"
        >
          <template v-if="!saveEnabled">Save</template>
          <template v-else>Save and dispatch</template>
        </v-btn>
      </div>
    </div>

    <omni-dialog
      v-model="addOrderDialog"
      primary-action-label="Add"
      title="Add order"
      width="360"
      @primary-action:click="addOrder"
    >
      <add-order-dialog />
    </omni-dialog>
    <omni-dialog
      v-model="addReloadStopDialog"
      primary-action-label="Add"
      title="Add reload stop"
      width="585"
      @primary-action:click="addReloadStop"
    >
      <add-reload-stop-dialog />
    </omni-dialog>
    <omni-dialog
      v-model="reorderOrderStopsDialog"
      primary-action-label="Save"
      title="Stops order"
      width="585"
      @primary-action:click="closeReorderStopsDialog"
    >
      <reorder-order-stops v-model="orderStops" :orders="orders" />
    </omni-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.btn-custom {
  min-width: auto !important;
  height: auto !important;
  box-shadow: none !important;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
}

.stops-order__info-color {
  width: 16px;
  height: 16px;
}

.line-height-1 {
  line-height: 1 !important;
}

.stops-order__block-inner {
  border-left: 6px solid;

  &.border-blue {
    border-color: #536dfe !important;
  }

  &.border-green {
    border-color: #7cb342 !important;
  }
}

/*.line-one .trips-stops__blocks {
		height: calc(100% - 32px);
	}*/
.trip-block__inner {
  min-height: 180px;
  display: flex;
  flex-direction: column;
}

.trip-stops__blocks {
  flex: 1;
}

.trip-stops__block-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trip-route-blocks .v-row + .v-row {
  margin-top: -24px;
}

.plus-block {
  line-height: 1;
}

.reload-stop {
  border: 2px dashed #556ee6;
}

.trip-stops__empty {
  border: 1px solid #bdbdbd !important;
}

.status-item {
  font-size: 9px;
  line-height: 16px;
  height: 16px !important;
  border-radius: 100px;
  padding: 0 2px !important;
  min-width: auto !important;
}

.table-status {
  line-height: 16px !important;
  white-space: nowrap;
}

.upload-file__input {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.v-row.dispatch-info__blocks {
  margin-left: -20px;
  margin-right: -20px;
}

.v-col.dispatch-info__block {
  padding-left: 20px;
  padding-right: 20px;
}

.arrow-right {
  position: relative;

  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: url("@/assets/images/arrow-right.svg") no-repeat;
  }
}

.upload-photo-block {
  min-height: 317px;
}
</style>
