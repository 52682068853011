<script>
import MessageDraftMixin from "@/mixins/message-draft.mixin";
import FileMixin from "@/mixins/file.mixin";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import { useChatStore } from "@/store/chat.store";

export default {
  name: "NewMessageManager",
  components: { OmniTextarea },
  mixins: [MessageDraftMixin, FileMixin],
  props: {
    thread: Object,
  },
  emits: ["aftersent"],
  data() {
    return {
      text: "",
      file: null,
      dragover: false,
      loading: false,
      cmdPressed: false,
    };
  },
  computed: {
    fileUrl() {
      return this.getObjectUrl(this.file);
    },
    isPdfFile() {
      return this.isPdf(this.file);
    },
    isImageFile() {
      return this.isImage(this.file);
    },
  },
  watch: {
    thread() {
      this.restoreDraft();
    },
  },
  mounted() {
    this.restoreDraft();
  },
  methods: {
    restoreDraft() {
      const draft = this.draft(this.thread);
      this.text = draft.message;
      this.file = draft.file ? this.dataURLtoFile(draft.file.data, draft.file.name) : null;
    },
    saveAsDraft() {
      if (this.file) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.saveDraft(this.thread.id, this.text, {
            name: this.file.name,
            data: reader.result,
          });
        };
      } else {
        this.saveDraft(this.thread.id, this.text, this.file);
      }
    },
    async sendMessages() {
      const text = (this.text || "").trim();
      if (this.loading || (text.length < 1 && this.file === null)) return;
      this.loading = true;
      const thread = { ...this.thread };
      try {
        if (text.length > 0) {
          const response = await this.sendText(thread.id, text);
          this.processResponse(thread, response);
          this.text = "";
        }
        if (this.file != null) {
          const response = await this.sendFile(thread.id, this.file);
          this.processResponse(thread, response);
          this.removeFile();
        }
        this.removeDraft(thread.id);
        this.$emit("aftersent");
      } catch (err) {
        console.error("Error add a new message", err);
      } finally {
        this.loading = false;
      }
    },
    processResponse(thread, resp) {
      const newMessage = resp.data;
      newMessage.threadId = thread.id;
      const chatStore = useChatStore();
      chatStore.updateThreadMessage(newMessage);

      // update thread last message
      thread.lastMessage = newMessage.text;
      thread.lastMessageDt = newMessage.created;
      thread.newMessagesCount = 0;
      chatStore.updateThread(thread);
    },
    sendText(threadId, text) {
      return this.$api.chat.sendMessage(threadId, text);
    },
    sendFile(threadId, file) {
      const formData = new FormData();
      formData.append("file", file);
      return this.$api.chat.sendFile(threadId, formData);
    },
    chooseFile() {
      this.$refs.attachment.click();
    },
    fileChanged(event) {
      this.file = event.target.files[0];
      this.saveAsDraft();
    },
    removeFile() {
      this.file = null;
      this.$refs.attachment.value = null;
      this.saveAsDraft();
    },
    onFileDrop(event) {
      this.dragover = false;
      if (event.dataTransfer.files.length === 0) return;
      this.file = event.dataTransfer.files[0];
      this.saveAsDraft();
    },
    onPaste(event) {
      const file = event.clipboardData.files[0];
      if (file) {
        this.file = file;
        this.saveAsDraft();
      }
    },
  },
};
</script>
<template>
  <div v-if="!thread.user.archived">
    <div
      :class="{ dragover: dragover }"
      class="pt-3 pb-4 px-4 new-message d-flex align-center"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      @dragover.prevent
      @drop.prevent="onFileDrop($event)"
    >
      <div v-if="dragover" class="file-dropzone text-center rounded flex-1 py-5">
        <v-icon>mdi-upload</v-icon>
        <div class="text-center font-size-12">Drop file here to send</div>
      </div>
      <template v-else>
        <div class="d-flex flex-1 flex-column">
          <omni-textarea
            v-model="text"
            :custom-new-line="true"
            :disabled="!thread.id || thread.status === 'b'"
            :loading="loading"
            :readonly="loading"
            max-rows="10"
            placeholder="Enter Message..."
            rows="1.5"
            @change="saveAsDraft"
            @clear="saveAsDraft"
            @paste="onPaste"
            @submit="sendMessages"
          >
            <template #button:suffix>
              <div class="ml-2" @click="chooseFile">
                <v-icon> mdi-attachment</v-icon>
                <input
                  ref="attachment"
                  accept="application/pdf, image/jpeg, image/png"
                  class="file-input"
                  name="attachment"
                  type="file"
                  @change="fileChanged"
                />
              </div>
            </template>
            <template #button:suffix2>
              <!-- :disabled="text?.trim() === '' && file === null"-->
              <div class="ml-2" @click="sendMessages">
                <v-icon color="primary">mdi-send-outline</v-icon>
              </div>
            </template>
          </omni-textarea>
        </div>
      </template>
    </div>
    <div v-if="file" class="file-attachment px-6 pb-2">
      <div class="file-attachment__icon">
        <v-img v-if="isImageFile" :cover="true" :src="fileUrl" class="rounded" max-height="40" max-width="50" />
        <div v-else-if="isPdfFile" class="file-pdf">
          <div class="file-caption">
            <div>
              <v-icon color="#bdbdbd">mdi-attachment</v-icon>
            </div>
            <div>PDF</div>
          </div>
        </div>
      </div>
      {{ file.name }}
      <v-icon class="close-button" size="18" @click="removeFile">mdi-close</v-icon>
    </div>
  </div>
  <div v-else class="border rounded mx-4 my-3 pa-3 d-flex align-center justify-center">
    <v-icon color="grey-darken-1" icon="mdi-alert-circle-outline" size="20"></v-icon>
    <div class="text-grey-darken-1 font-size-16 ml-2">Sending messages to archived users is not allowed</div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color.scss";

.file-input {
  width: 0;
  height: 0;
  opacity: 0;
}

.file-attachment {
  display: flex;
  align-items: center;
  column-gap: 12px;

  .file-pdf {
    width: 40px;
    height: 40px;
    border: 1px solid $grey-light;
    border-radius: 4px;
    position: relative;

    .file-caption {
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      font-weight: bold;
      color: $grey-light;
    }
  }
}

.file-dropzone {
  border: 2px dashed $primary;
  height: 96px;
  background-color: $grey-300;
}

.dragover {
  & * {
    pointer-events: none;
  }
}
</style>
