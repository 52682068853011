<script>
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "OmniDialog",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: String,
    primaryActionLabel: String,
    secondaryActionLabel: String,
    size: {
      type: String,
      default: "normal",
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "primaryAction:click", "secondaryAction:click"],
  computed: {
    ...mapState(useAppStore, ["mobile"]),
    isVisible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    dialogWidth() {
      if (this.width) {
        return this.width;
      }
      switch (this.size) {
        case "small":
          return 400;
        case "normal":
          return 600;
        case "large":
          return 900;
        case "xlarge":
          return 1200;
        default:
          return this.size;
      }
    },
    dialogHeight() {
      return this.height || null;
    },
  },
  methods: {
    closeDialog() {
      this.isVisible = false;
    },
    clickPrimaryAction() {
      this.$emit("primaryAction:click");
    },
    clickSecondaryAction() {
      this.$emit("secondaryAction:click");
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="isVisible"
    :fullscreen="mobile"
    :height="dialogHeight"
    :persistent="persistent"
    :width="dialogWidth"
    class="omni-dialog"
    scrollable
    transition="dialog-top-transition"
  >
    <v-card :class="{ dialog__open: isVisible }" :loading="loading">
      <v-card-title class="pa-6 pb-0 d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <div class="font-size-20 font-weight-500 text-grey-darken-4 line-height-24px text-wrap mr-6">{{ title }}</div>
          <slot name="title:append"></slot>
        </div>
        <v-btn density="comfortable" icon="mdi-close" size="small" variant="flat" @click="closeDialog"></v-btn>
      </v-card-title>
      <v-card-subtitle v-if="subtitle" class="pa-0 font-size-14 text-grey-darken-3 line-height-18px">
        {{ subtitle }}
      </v-card-subtitle>
      <v-card-text class="pa-6">
        <slot></slot>
      </v-card-text>
      <v-card-actions v-if="!hideActions" class="pa-6 pt-0 omni-dialog__actions">
        <slot name="actions:custom"></slot>
        <v-spacer></v-spacer>
        <v-btn
          v-if="secondaryActionLabel"
          :disabled="disabled"
          class="text-uppercase font-weight-600 font-size-14 px-4"
          color="primary"
          variant="outlined"
          @click="clickSecondaryAction"
        >
          {{ secondaryActionLabel }}
        </v-btn>
        <v-btn
          v-if="primaryActionLabel"
          :disabled="disabled"
          class="text-uppercase font-weight-600 font-size-14 px-4"
          color="primary"
          variant="flat"
          @click="clickPrimaryAction"
        >
          {{ primaryActionLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
