<script>
import UserSelect from "@/views/users/components/UserSelect.vue";

export default {
  name: "OwnerCoordinatorsBlock",
  components: { UserSelect },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["change"],
  data() {
    return {
      coordinators: [],
    };
  },
  watch: {
    async user() {
      if (this.user.isOwner && this.coordinators.length === 0) {
        await this.loadCoordinators();
      }
    },
  },
  methods: {
    addCoordinator() {
      this.coordinators.push({ userId: null, user: null, fullAccess: false, canDelete: true });
    },
    async createCoordinator(index) {
      const userId = this.coordinators[index].userId;
      if (userId) {
        const resp = await this.$api.users.createOwnerCoordinator(
          this.user.id,
          userId,
          this.coordinators[index].fullAccess,
        );
        if (resp.success) {
          this.coordinators[index] = resp.data;
        }
      }
      this.$emit("change", this.coordinators);
    },
    async removeCoordinator(index) {
      let success = true;
      const userId = this.coordinators[index].userId;
      if (userId) {
        const resp = await this.$api.users.removeOwnerCoordinator(this.user.id, userId);
        success = resp.success;
      }

      if (success) {
        this.coordinators.splice(index, 1);
        this.$emit("change", this.coordinators);
      }
    },
    async loadCoordinators() {
      const resp = await this.$api.users.findOwnerCoordinators(this.user.id);
      if (resp.success) {
        this.coordinators = resp.data;
      }
    },
    async updateCoordinator(index) {
      const userId = this.coordinators[index].userId;
      if (userId) {
        const resp = await this.$api.users.updateOwnerCoordinator(
          this.user.id,
          userId,
          this.coordinators[index].fullAccess,
        );
        if (resp.success) {
          this.coordinators[index].fullAccess = resp.data.fullAccess;
        }
      }
      this.$emit("change", this.coordinators);
    },
  },
};
</script>

<template>
  <div>
    <v-row v-for="(coordinator, index) in coordinators" :key="index" class="d-flex align-center">
      <v-col cols="6">
        <user-select
          v-model="coordinator.userId"
          :disabled="!coordinator.canDelete"
          :exclude-ids="[user.id]"
          coordinators
          label="Coordinator"
          @change="createCoordinator(index)"
        />
      </v-col>
      <v-col>
        <v-checkbox
          id="full_access"
          v-model="coordinator.fullAccess"
          color="primary"
          density="compact"
          hide-details
          label="Full access"
          @change="updateCoordinator(index)"
        />
      </v-col>
      <v-col class="text-right">
        <v-menu :disabled="coordinator.canDelete" location="top start" offset="4" open-on-hover>
          <template #activator="{ props }">
            <div v-bind="props">
              <v-btn
                v-if="coordinator.userId"
                :id="'coordinator_delete_' + index"
                :color="coordinator.canDelete ? 'error' : 'grey-darken-3'"
                :disabled="!coordinator.canDelete"
                class="text-uppercase"
                size="small"
                variant="text"
                @click="removeCoordinator(index)"
              >
                <v-icon class="mr-2">mdi-delete-outline</v-icon>
                Delete
              </v-btn>
            </div>
          </template>
          <v-card>
            <v-card-text v-if="!coordinator.canDelete">
              Coordinator is a contact person for driver
              <a :href="`/users/` + coordinator.managedUser.id + `/view`" target="_blank">
                {{ coordinator.managedUser.name }}
              </a>
              and can't be changed
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-btn class="text-primary font-weight-600 text-uppercase mt-2" flat="flat" size="small" @click="addCoordinator()">
      <v-icon class="text-primary" size="14">mdi-plus</v-icon>
      Add coordinator
    </v-btn>
  </div>
</template>
